import PropTypes from 'prop-types';

const SVG = ({
  style = {
    fill: '#000000',
  },
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" style={style} viewBox="0 0 60 34.14">
    <path d="M30 22.64H2.72a6.79 6.79 0 0 1-1.81-.11C.56 22.43.3 22 0 21.7c.34-.32.67-.89 1-.91 2-.1 3.91-.13 5.85 0A1.82 1.82 0 0 0 9 19.18c1.26-4.11 2.61-8.19 4-12.27a19.1 19.1 0 0 1 1.41-3.13C16.25.35 18.54-.78 22.25.56A22 22 0 0 0 37.8.57c3.88-1.39 6.36-.26 8 3.61 1.9 4.57 3.44 9.31 4.94 14 .59 1.88 1.35 2.85 3.46 2.54a15.46 15.46 0 0 1 4 0c.64.07 1.21.66 1.81 1-.59.28-1.18.81-1.77.81H33.82Zm19.83-2c-2-5.56-3.71-10.85-5.82-16-1.16-2.81-2.88-3.41-5.76-2.33a22.63 22.63 0 0 1-16.34 0c-3-1.08-4.63-.45-5.86 2.48-1.8 4.33-3.19 8.82-4.74 13.25-.27.79-.45 1.62-.71 2.56ZM30.17 26c4.51 0 9 .05 13.52 0 1.73 0 2.3.49 1.92 2.24a7.21 7.21 0 0 1-6.48 5.95 7.3 7.3 0 0 1-7.62-4.77c-.25-.61-.93-1.05-1.42-1.57-.53.54-1.29 1-1.57 1.63a7.42 7.42 0 0 1-8 4.67 7.23 7.23 0 0 1-6-6c-.25-1.51.09-2.23 1.82-2.19 4.55.04 9.2.04 13.83.04Zm3.08 1.89A5.36 5.36 0 0 0 39 32.33a4.66 4.66 0 0 0 4.42-4.49Zm-6.53.08H16.39c.8 2.86 2.86 4.51 5.37 4.44a5.16 5.16 0 0 0 4.96-4.49Z" />
  </svg>
);

SVG.propTypes = {
  style: PropTypes.object.isRequired,
};

export default SVG;
