import React from 'react';
import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg viewBox="0 0 60 53.2" style={style} {...rest}>
      <path
        d="M34.52 40.9a1 1 0 01-1.41.47c-.36-.17-.72-.34-1.07-.53v5.49a2.68 2.68 0 01-2.66 2.35 2.63 2.63 0 01-1.67-.59 2.66 2.66 0 01-1-2.08.68.68 0 00-.68-.68.67.67 0 00-.67.68 2.67 2.67 0 01-5.33.32V42.8a1 1 0 012 0v3.21a.67.67 0 00.67.67.68.68 0 00.68-.67 2.67 2.67 0 011-2.09 2.63 2.63 0 011.62-.59 2.68 2.68 0 012.68 2.68.67.67 0 00.67.67.68.68 0 00.68-.67v-6.26a.17.17 0 010-.07 6 6 0 00-6.93.35 8.17 8.17 0 01-7.22 1.46 15.12 15.12 0 01-3.4-1.67 6.35 6.35 0 00-4.81-.9 6.67 6.67 0 00-1.21.42 5.28 5.28 0 00-.88.47 13.3 13.3 0 01-3.1 1.62 1.47 1.47 0 01-.48-.26.84.84 0 01-.34-.57.85.85 0 01.43-.7l1.47-1 .31-.22a8.25 8.25 0 018-1.23 11.06 11.06 0 012.07 1.15c3.19 2 5 2 8.16-.1a8.18 8.18 0 016.6-1.26 11.48 11.48 0 012.53 1.06c.92.45 1.76 1 2.66 1.48.51.21.84.55.63 1.12z"
        fillRule="evenodd"
      />
      <path d="M58.23 11.47a14.39 14.39 0 00-11-6.92v2a12 12 0 0110.66 10h-24.5a11.78 11.78 0 018.87-9.64v4.1a.59.59 0 00.59.6h.66a.59.59 0 00.59-.6V.6a.59.59 0 00-.59-.6h-.66a.59.59 0 00-.59.6v4.22a14.31 14.31 0 00-9.17 6.57 12.32 12.32 0 00-1.16 2.53 12.54 12.54 0 00-.63 3.19v5.58a.52.52 0 000 .21H34l.38 3.54 2.73 25.79v.37a.59.59 0 00.59.59h15.86a.57.57 0 00.4-.17.26.26 0 00.09-.09.63.63 0 00.1-.27l2.77-26.2v-.08l.36-3.45h2.22a.6.6 0 00.47-.58V18a12.91 12.91 0 00-1.74-6.53zM52.31 51.2H39l-.83-7.81h14.96zm1-9.81H38l-.76-7.24h16.87zM55 25.52v.08l-.31 2.92v.08l-.38 3.58H37l-.36-3.58-.31-3-.33-2.7h19.3zm3-4.62H33.32v-1.94a1.64 1.64 0 010-.22 1.55 1.55 0 010-.22H58zM34.58 52.19a1 1 0 01-1.07 1H3.67a3.77 3.77 0 01-1.87-.48 3.48 3.48 0 01-1.47-1.52A3.22 3.22 0 010 49.77a3.38 3.38 0 011.81-3 1 1 0 01.33.73v2.22a1.48 1.48 0 001.53 1.42h29.84a1 1 0 011.07 1.05zM31.3 34.47a1 1 0 01-1 1H1a1 1 0 01-.93-.64 5.11 5.11 0 010-.55H0v-.55c0-4.13 2.67-7.71 6.72-9.83a18.12 18.12 0 018.43-2h13.61a1 1 0 010 2H15.15C8 23.9 2.18 28.17 2 33.47h28.3a1 1 0 011 1z" />
      <ellipse cx="7.64" cy="29.73" rx="1.52" ry="1.04" />
      <ellipse cx="13.57" cy="30.77" rx="1.52" ry="1.04" />
      <ellipse cx="16.61" cy="26.84" rx="1.52" ry="1.04" />
      <ellipse cx="20.77" cy="30.51" rx="1.52" ry="1.04" />
      <ellipse cx="25.98" cy="27.14" rx="1.52" ry="1.04" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
