import styled from 'styled-components';
import Icon from '../Icon';
import PropTypes from 'prop-types';

const ErrorWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

  h4 {
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: 0.7px;
  }
  span {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.61px;
  }
`;
const ErrorIconWrapper = styled.div`
  width: 65px;
  height: 65px;
  margin-bottom: 15px;
  cursor: pointer;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 150ms ease-in-out, scale 150ms ease-in-out;

  &:hover {
    transform: rotate(270deg) scale(0.9);
  }
`;

const LoadingErrorReloadComponent = ({ title, subtitle, reloadFn, ...rest }) => {
  return (
    <ErrorWrapper {...rest}>
      <ErrorIconWrapper onClick={reloadFn}>
        <Icon name="refresh" style={{ width: '100%' }} />
      </ErrorIconWrapper>
      <h4>{title || 'Fehler beim Laden'}</h4>
      <span>{subtitle || 'Bitte versuchen Sie es erneut'}</span>
    </ErrorWrapper>
  );
};

LoadingErrorReloadComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  reloadFn: PropTypes.func.isRequired,
};

export default LoadingErrorReloadComponent;
