import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.11 50.14" {...rest}>
      <path d="M22.78 50.13a33 33 0 01-15.61-3.51 24.19 24.19 0 01-6.44-4.88 2.29 2.29 0 01-.34-3 2.52 2.52 0 012.89-.78 48.76 48.76 0 006.56 2c8.1 1.5 15.44-.1 21.6-5.8a19.18 19.18 0 004.89-7.86 38.08 38.08 0 013.14-7.34c1.41-2.34 3.15-4.29 5.87-5.13a5.47 5.47 0 011-.23c3.31-.3 5.19-2.4 6.34-5.23a9.66 9.66 0 00.66-3.7 2.42 2.42 0 00-2.59-2.58c-1.09-.05-2.2.13-3.29.14a1.1 1.1 0 01-.86-.35c-.22-.39.13-.67.5-.78a23.14 23.14 0 013.86-1c3.23-.35 5.42 1.63 5.63 4.89a10.48 10.48 0 01-.65 4.23 31.43 31.43 0 00-1.14 3.74 3.87 3.87 0 00.69 3.08 9.38 9.38 0 011.56 6.56 25.92 25.92 0 01-3.38 9.77 31.9 31.9 0 01-18.86 16 38.65 38.65 0 01-12.03 1.76zm22-34.88a8 8 0 00-3 2.38 23 23 0 00-3.42 6.43 46.94 46.94 0 01-2.81 6.59c-4.2 7.09-10.83 10.21-18.77 10.92a27.87 27.87 0 01-13.7-2.49c-.83-.36-1.35-.23-1.76.38s-.1 1.15.37 1.63a25.15 25.15 0 0013.76 7.27 39.91 39.91 0 0011.8.44 32.49 32.49 0 0013.69-4.36c7-4.24 11.56-10.48 14.18-18.11a13.65 13.65 0 00.88-4.85.92.92 0 00-.27-.67c-1.24-.7-3.22-1.14-4.66 0a1.12 1.12 0 01-1.66-.11 2.82 2.82 0 01-.73-1.2 2.15 2.15 0 00-2.11-1.72l-1.36-.1c-.87 0-1.19-.53-.89-1.35.12-.29.24-.62.42-1.08zm11 4.28l.18-.15a15.78 15.78 0 00-1.08-2.27 6.35 6.35 0 01-.94-5.55c.37-1.26.88-2.48 1.24-3.75a6.11 6.11 0 00-.5-5.19 3.15 3.15 0 00-2.86-1.53c2.18.85 2.85 2.56 2.58 4.69a12 12 0 01-.85 3.24 8.78 8.78 0 01-5.34 5.28c-.63.21-1.32.25-2 .4-.18 0-.43.19-.46.34a14.22 14.22 0 00-.23 1.54c.39 0 .77.08 1.15.14 1.47.23 2.64.82 3 2.45a3.94 3.94 0 00.52.92c1.81-1.42 3.64-1.24 5.52-.56z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
