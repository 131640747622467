import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Currency } from 'react-intl-number-format';
import styled from 'styled-components';
import Icon from '../Icon';
import request from '../../util/request';
import { useState, useContext } from 'react';
import RestaurantContext from '../../Context/RestaurantContext';
import { toast } from 'react-toastify';

const DelIcon = styled.span`
  display: inline-block;
  padding-right: 10px;
  cursor: pointer;
`;

const CartCouponComponent = ({ coupon, couponRemovedFn, ...rest }) => {
  const { restaurantProps: storeProps } = useContext(RestaurantContext);
  const [loading, setLoading] = useState(false);

  const removeCoupon = () => {
    if (loading) return false;

    setLoading(true);
    request('/api/cart/v1/removeCoupon', {
      method: 'POST',
      body: JSON.stringify({
        restaurantId: storeProps.restaurantId,
        couponId: coupon._id,
      }),
    })
      .then(() => {
        couponRemovedFn && couponRemovedFn();
      })
      .catch((error) => {
        // todo: error in logger
        toast.error(`Es ist ein Fehler aufgetreten. Das tut uns leid.`, {
          theme: 'colored',
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <Row>
      <Col xs={8}>
        <DelIcon onClick={removeCoupon}>
          <Icon name="trash" style={{ width: '12px', opacity: loading ? 0.7 : 1 }} />
        </DelIcon>
        <span style={{ verticalAlign: 'middle' }}>{coupon.name}</span>
      </Col>
      <Col xs={4} className="price">
        {coupon.couponType === 'absolute' && (
          <Currency as="span">{-1 * coupon.valueAbsolute}</Currency>
        )}
        {coupon.couponType === 'relative' && (
          <span>
            -
            {new Intl.NumberFormat('de-DE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(coupon.valueRelative)}{' '}
            %
          </span>
        )}
      </Col>
    </Row>
  );
};

CartCouponComponent.propTypes = {
  coupon: PropTypes.object.isRequired,
  couponRemovedFn: PropTypes.func,
};

export default CartCouponComponent;
