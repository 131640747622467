import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import Image from 'next/legacy/image';
import Link from 'next/link';
import moment from 'moment';
import { useContext } from 'react';
import RestaurantContext from '../Context/RestaurantContext';

import FaLogo from '../public/svg/foodAlley-logo-white.svg';

const Wrapper = styled.div`
  background-color: #484f51;
  color: #ffffff;
  flex-grow: 1;

  padding-top: 35px;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    padding-top: 50px;
    padding-bottom: 45px;
  }

  h5 {
    line-height: 24px;
    font-weight: 200;
    letter-spacing: 0.75px;
    margin-bottom: 7px;
    font-size: 17px;
  }

  small {
    font-size: 12px;
    font-weight: 200;
    letter-spacing: 0.53px;
  }

  a:hover {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const ContentRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 768px) {
    margin-bottom: 35px;
  }
`;

const FaLogoWrapper = styled.div`
  position: relative;
  display: inline-block;

  width: 154px;
  height: 40px;
  margin-bottom: 25px;
  @media (min-width: 992px) {
    margin-bottom: 0;
    height: 50px;
    width: 190px;
  }
`;

const PoweredByCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const FooterCol = styled(Col)`
  @media (max-width: 767.98px) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  h3 {
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0.75px;
    color: #ffffff;
    margin-bottom: 15px;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    margin: 0;
    padding: 0;

    font-weight: 300;
    font-size: 15px;
    letter-spacing: 0.67px;
    margin-bottom: 10px;
  }
`;

function Footer(props) {
  const { removeCookieConsent, restaurantProps: storeProps } = useContext(RestaurantContext);

  return (
    <Wrapper>
      <Container>
        <ContentRow>
          <PoweredByCol xs={12} md={3}>
            <h5>
              powered by <br />
              <FaLogoWrapper>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  href="https://www.foodalley.de"
                  target="_blank"
                  rel="noopener"
                  style={{ display: 'inline-block', width: '100%', height: '100%' }}
                >
                  <Image src={FaLogo} alt="FoodAlley.de" />
                </a>
              </FaLogoWrapper>
            </h5>
          </PoweredByCol>
          <FooterCol xs={12} md={3}>
            <h3>FoodAlley</h3>
            <ul>
              <li>
                <a
                  href="https://my.foodalley.de"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  Partner Login
                </a>
              </li>
              <li>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href="https://www.foodalley.de/partner-werden" target="_blank" rel="noopener">
                  Partner werden
                </a>
              </li>
            </ul>
          </FooterCol>
          <FooterCol xs={12} md={3}>
            <h3>Social Media</h3>
            <ul>
              {storeProps.socialMediaLinks?.instagram && (
                <li>
                  <a
                    href={storeProps.socialMediaLinks.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </li>
              )}

              {storeProps.socialMediaLinks?.facebook && (
                <li>
                  <a
                    href={storeProps.socialMediaLinks.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </li>
              )}

              {storeProps.socialMediaLinks?.google && (
                <li>
                  <a
                    href={storeProps.socialMediaLinks.google}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google
                  </a>
                </li>
              )}
            </ul>
          </FooterCol>
          <FooterCol xs={12} md={3}>
            <h3>Datenschutz</h3>
            <ul>
              <li>
                <Link href="/impress">Impressum</Link>
              </li>
              <li>
                <Link href="/privacy">Datenschutzerklärung</Link>
              </li>
              <li>
                <Button variant="link" onClick={removeCookieConsent}>
                  Cookie-Einstellungen
                </Button>
              </li>
            </ul>
          </FooterCol>
        </ContentRow>
        <Row className="text-center">
          <span>
            <small>
              &copy; 2019-{moment().format('YYYY')}{' '}
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://www.foodalley.de" target="_blank" rel="noopener">
                FoodAlley.de
              </a>{' '}
              | Serverzeit:{' '}
              <span suppressHydrationWarning={true}>
                {moment(props.serverTime).locale('de').format('LT')} Uhr
              </span>
            </small>
          </span>
        </Row>
      </Container>
    </Wrapper>
  );
}

export async function getServerSideProps(context) {
  const res = await fetch(`${apiHost}/general/v1/serverTime`);
  const data = await res.json();

  return {
    props: {
      serverTime: data.time,
    },
  };
}

export default Footer;
