import React from 'react';
import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" style={style} {...rest}>
      <path d="M59.14 20.26c-.65-2.18-1.21-4.39-1.78-6.59-.83-3.22-1.87-6.41-1.7-9.84C55.79 1.2 54.49 0 51.84 0H8.12C5.56 0 4.41 1.22 4.31 3.75a35.47 35.47 0 0 1-.61 5.83C3.1 12.41 2.3 15.2 1.48 18A32.34 32.34 0 0 0 0 27.17v1.22a7.92 7.92 0 0 0 3.41 6.5 1.93 1.93 0 0 1 .9 1.86v19.28c0 2.83 1.14 4 4 4h43.45c2.75 0 3.93-1.16 3.93-3.89V36.86a2 2 0 0 1 .94-2 7.36 7.36 0 0 0 3.3-5.86c0-.77.07-1.55.07-2.32a21.61 21.61 0 0 0-.86-6.42ZM10.21 2.15h40.92c2.42 0 2.42 0 2.42 2.49v1.74H6.66c-.07-.12-.15-.19-.16-.26-.08-4.58-.36-3.97 3.71-3.97Zm-4 6.47h47.6c.31 1.17.63 2.34.93 3.52H5.28c.3-1.14.62-2.34.93-3.52Zm-1.54 5.79h50.68c.71 2.65 1.41 5.31 2.13 8H2.54c.72-2.69 1.46-5.35 2.13-8Zm41.43 42v1.39H33.46a84.78 84.78 0 0 1 .1-10.09c.36-3.13 3.5-5.1 6.69-4.83a6.31 6.31 0 0 1 5.82 5.95c.1 2.53.03 5.08.03 7.62Zm6.17 1.41h-4c0-3.06.06-6 0-8.84a8.54 8.54 0 0 0-16.7-2.26 11.37 11.37 0 0 0-.38 2.9c-.05 2.67 0 5.35 0 8.19H7.5c-1.02 0-1-.8-1-1.57V36.36a8.28 8.28 0 0 0 8.66-4.23c3.56 5.46 10.77 6 14.87-.07 4.4 6.39 11.56 5.24 14.88.06a8.24 8.24 0 0 0 8.58 4.24c0 .3.08.51.08.72v19.29c-.02.9-.3 1.49-1.3 1.49Zm5.54-29.12a5.88 5.88 0 0 1-11.74 0c0-.31.1-.69 0-.92-.26-.4-.66-1-1-1s-.81.52-1 .92-.07.79-.15 1.18a6.42 6.42 0 0 1-12.71-.51c0-.26.06-.61-.08-.78-.28-.35-.67-.82-1-.82s-.81.46-1 .83a5 5 0 0 0-.29 1.4 6.43 6.43 0 0 1-12.71-.43c0-.31.06-.71-.09-.92s-.7-.81-1-.79-.77.49-1 .86 0 .7-.07 1.06a5.88 5.88 0 0 1-11.74-.06c-.06-1.28 0-2.58 0-4h55.59c0 1.43.05 2.73-.01 4.02Z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
