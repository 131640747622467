import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" style={style} {...rest}>
      <circle cx="30" cy="30" r="30" style={{ fill: '#ededed' }} />
      <path
        d="M30 .5C46.27.5 59.5 13.73 59.5 30S46.27 59.5 30 59.5.5 46.27.5 30 13.73.5 30 .5m0-.5C13.43 0 0 13.43 0 30s13.43 30 30 30 30-13.43 30-30S46.57 0 30 0Z"
        style={{ fill: '#dedede' }}
      />
      <path
        d="M20.96 24.3c0 5.31 4.35 12.83 9.73 12.83s9.73-7.48 9.73-12.83-4.36-9.73-9.73-9.73-9.73 4.36-9.73 9.73ZM12.1 53.68s2.5 2.44 6.11 3.91c4.12 1.67 9.53 2.4 11.79 2.4 4.1 0 8.73-1.01 12.12-2.55 3.57-1.62 5.78-3.77 5.78-3.77s-2.1-13.69-10.53-15.8c0 0-2.1 3.16-7.37 3.16s-7.37-3.16-7.37-3.16c-8.43 2.1-10.53 15.8-10.53 15.8Z"
        style={{ fill: '#dedede', fillRule: 'evenodd' }}
      />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
