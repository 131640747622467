import PropTypes from 'prop-types';

function SVG({ style, ...rest }) {
  return (
    <svg style={style} {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
      <path d="M29.94 6.92l5.32 17.14.46 1.49h18.11L40.54 35.83l-1.15.89.42 1.39 5 16.48-13.57-9.89-1.24-.89-1.23.88-14.21 10.12 4.73-16.74.38-1.33-1.08-.88L5.93 25.55h17.86l.48-1.45 5.67-17.18M30 0l-7.73 23.44H0L17.26 37.5 10.89 60 30 46.41 48.65 60l-6.82-22.5L60 23.44H37.28z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
