import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} {...rest} viewBox="0 0 60 36.27">
      <path d="m1.85 10.72 23.71 23.7a6.26 6.26 0 0 0 8.88 0l23.71-23.7A6.28 6.28 0 0 0 53.71 0H6.29a6.28 6.28 0 0 0-4.44 10.72Z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
