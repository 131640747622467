import { useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap';
import { Currency } from 'react-intl-number-format';
import RestaurantContext from '../../Context/RestaurantContext';

const Wrapper = styled.div`
  margin-bottom: 20px;

  & p {
    font-weight: 500 !important;
    margin: 0 !important;
  }
  & span {
    font-weight: 300 !important;

    &.no-region-selected {
      opacity: 0.5;
    }
  }
`;

const Btn = styled(Button)`
  box-shadow: none !important;
  font-size: 14px;
  border-radius: 4px !important;

  &:hover {
    background-color: inherit;
    color: ${(props) => props.theme.colors.primary};
  }

  &:disabled,
  &.disabled {
    background: white;
    border: 1px solid rgba(151, 151, 151, 0.65) !important;
    color: rgba(151, 151, 151, 0.65);
    pointer-events: inherit;
  }

  &.btn-sm {
    height: 24px;
    padding: 0;
  }
`;

const PickUpSwitchComponent = (props) => {
  const {
    restaurantProps: storeProps,
    selectedDeliveryRegion,
    setIsPickUp,
    isDeliveryRegionSelected,
  } = useContext(RestaurantContext);

  const switchPickUpSelectionTo = (isPickUp) => {
    if (
      selectedDeliveryRegion.isPickUp !== isPickUp &&
      ((isPickUp && storeProps.doesPickUp) || (!isPickUp && storeProps.doesDelivery))
    ) {
      setIsPickUp(isPickUp);
    }
  };

  return (
    <Wrapper>
      <Row>
        <Col xs={6}>
          <div className="d-grid">
            <Btn
              variant="outline-primary"
              type="button"
              size="md"
              block="true"
              className={selectedDeliveryRegion?.isPickUp ? 'disabled' : ''}
              onClick={() => switchPickUpSelectionTo(false)}
            >
              <p>Liefern</p>
              {!isDeliveryRegionSelected ? (
                <span className="no-region-selected">Liefergebiet angeben</span>
              ) : (
                <>
                  {!storeProps.doesDelivery ? (
                    <span>nicht möglich</span>
                  ) : (
                    <span>
                      ab <Currency as="span">{selectedDeliveryRegion?.minOrderValue}</Currency>
                    </span>
                  )}
                </>
              )}
            </Btn>
          </div>
        </Col>
        <Col xs={6}>
          <div className="d-grid">
            <Btn
              variant="outline-primary"
              type="button"
              size="md"
              block="true"
              className={!selectedDeliveryRegion?.isPickUp ? 'disabled' : ''}
              onClick={() => switchPickUpSelectionTo(true)}
            >
              <p>Abholen</p>
              {!storeProps.doesPickUp ? (
                <span>nicht möglich</span>
              ) : (
                <span>
                  ab <Currency as="span">{selectedDeliveryRegion?.minPickupValue || 0}</Currency>
                </span>
              )}
            </Btn>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default withTheme(PickUpSwitchComponent);
