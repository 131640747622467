import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" style={style} {...rest}>
      <path
        d="M30 57.86A27.86 27.86 0 102.14 30 27.86 27.86 0 0030 57.86zM30 60a30 30 0 1130-30 30 30 0 01-30 30z"
        fillRule="evenodd"
      />
      <path d="M32.13 18.94a2.13 2.13 0 01-4.26 0A2.1 2.1 0 0130 16.79a2 2 0 012.13 2.15zM28.3 43.21V24.26h3.44v19z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
