import { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import Icon from '../Icon';

const IconWrapper = styled.div`
  text-align: center;
  width: 95px;
  flex-grow: 0;
  display: block;
  justify-content: center;
  align-items: center;
`;

const LabelWrapper = styled.div`
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  height: 100%;
`;

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0;
  text-align: left;
  border-radius: 4px !important;
  border: ${(props) =>
    props.selected ? `1px solid ${props.theme.colors.primary}` : '1px solid white'} !important;
  box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.13) !important;
  font-size: 16px;
  color: ${(props) => (props.selected ? props.theme.colors.primary : 'black')} !important;
  height: 50px;

  transition: border-color 100ms ease-out, color 100ms ease-out;

  &.active,
  &:active {
    background-color: #fafafa !important;
    border-color: ${(props) => props.theme.colors.primary} !important;
    outline: none;
  }

  &:hover,
  &.hover {
    background-color: inherit;
    color: inherit;
    outline: none;
    border: ${(props) =>
      props.selected ? `1px solid ${props.theme.colors.primary}` : '1px solid white'} !important;
  }

  ${IconWrapper} {
    fill: ${(props) => (props.selected ? props.theme.colors.primary : '#000000')};
  }
`;

const SelectableButton = (props) => {
  const iconName = (name) => {
    switch (name) {
      case 'cash':
        return 'pt-cash';
      case 'eccash':
        return 'pt-card';
      case 'paypal':
        return 'pt-paypal-small';
      default:
        return name;
    }
  };

  return (
    <StyledButton
      size="lg"
      variant="outline-primary"
      selected={props.selected || false}
      onClick={() => props.onSelect(props.iconName)}
      ref={props.innerRef}
      disabled={props.disabled}
    >
      <IconWrapper>
        <Icon
          style={{
            fill: 'inherit',
            height: '25px',
            transition: 'fill 100ms ease-out',
            verticalAlign: 'middle',
          }}
          name={iconName(props.iconName)}
        />
      </IconWrapper>
      <LabelWrapper>
        <span>{props.label}</span>
      </LabelWrapper>
    </StyledButton>
  );
};

SelectableButton.propTypes = {
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  innerRef: PropTypes.any,
  iconName: PropTypes.oneOf(['cash', 'eccash', 'paypal']).isRequired,
};

export default memo(SelectableButton);
