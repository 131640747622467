import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} viewBox="0 0 60 60">
      <path d="M15.04 23.31c4.2 10.26 11.38 17.39 21.67 21.6.04-.73.08-1.33.1-1.94.1-2.45 1.31-4.01 3.7-4.56 5.51-1.28 10.82-.71 15.87 1.87 2.09 1.06 3.13 2.9 3.44 5.19.51 3.75-.05 7.32-1.65 10.75-1.13 2.43-3.28 3.07-5.64 3.49-3.66.65-7.27.12-10.85-.53-9.16-1.67-17.36-5.46-24.38-11.62C8.97 40.27 3.62 31.15 1.17 20.34.35 16.74-.15 13.09.04 9.39c.07-1.26.37-2.51.61-3.76.31-1.65 1.26-2.84 2.74-3.6C7.18.1 11.16-.45 15.32.36c2.66.52 4.16 2.39 5.09 4.76 1.72 4.38 2.24 8.89 1.31 13.54-.02.08-.02.16-.04.24-.91 3.17-1.67 3.97-4.85 4.25-.56.05-1.12.1-1.79.16zm24.24 21.7h.08v1.54c.01 1.16-.77 1.77-1.86 1.4-5.6-1.93-10.62-4.87-14.95-8.95-4.91-4.63-8.38-10.21-10.58-16.58-.35-1.02.26-1.84 1.35-1.83 1 .01 2 .08 2.99 0 2.28-.18 2.51-.42 2.97-2.71.51-2.56.45-5.14-.1-7.66-.39-1.75-1-3.47-1.68-5.13-.54-1.34-1.69-2.09-3.13-2.27-.93-.11-1.88-.21-2.82-.18-2.42.06-4.79.46-6.91 1.7-.54.32-1.17.88-1.3 1.43-.38 1.6-.74 3.25-.72 4.87.11 7.26 1.9 14.12 5.19 20.58 4.07 7.98 9.92 14.31 17.53 18.99 6.12 3.76 12.75 6.01 19.87 6.84 2.6.3 5.2.39 7.81-.09 1.58-.29 2.57-1.09 3.14-2.55.97-2.48 1.35-5.04 1.23-7.71-.11-2.34-1.2-3.88-3.37-4.76-2.44-.98-4.97-1.43-7.56-1.56-1.97-.1-3.93.04-5.82.73-.68.25-1.09.64-1.14 1.4-.06.84-.15 1.67-.22 2.5z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object.isRequired,
};

export default SVG;
