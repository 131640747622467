import { useContext } from 'react';
import styled from 'styled-components';
import ArticleConfigurationContext from '../../Context/ArticleConfigurationContext';
import RestaurantContext from '../../Context/RestaurantContext';
import ArticleConfiguratorComponent from './ArticleConfiguratorComponent';
import CartComponent from './CartComponent';
import NoDeliveryRegionSelectedComponent from './NoDeliveryRegionSelectedComponent';
import isEmpty from 'lodash/isEmpty';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  background: white;
  box-shadow: 0 2px 9px RGBA(120, 130, 140, 0.13);

  @media (min-width: 992px) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 650px;
  }

  overflow: hidden;
`;

function CartAndConfiguratorComponent(props) {
  const { article } = useContext(ArticleConfigurationContext);
  const { isDeliveryRegionSelected } = useContext(RestaurantContext);

  if (!isDeliveryRegionSelected)
    return (
      <Wrapper>
        <NoDeliveryRegionSelectedComponent />
      </Wrapper>
    );
  return (
    <Wrapper>
      {article && <ArticleConfiguratorComponent />}
      <CartComponent hidden={!isEmpty(article)} />
    </Wrapper>
  );
}

export default CartAndConfiguratorComponent;
