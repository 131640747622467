import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} viewBox="0 0 47.85 60" {...rest}>
      <path
        d="M29.08 30c7.18 0 13-10 13-17.14a13 13 0 0 0-26 0C16.08 20 21.9 30 29.08 30m0-27.89a10.81 10.81 0 0 1 10.86 10.75c0 6.42-5.37 15-10.86 15s-10.87-8.61-10.87-15A10.82 10.82 0 0 1 29.08 2.11M39.85 30.45S37 34.67 30 34.67s-9.85-4.22-9.85-4.22C8.89 33.26 6.08 51.56 6.08 51.56S18.74 60 30 60s23.92-8.44 23.92-8.44-2.81-18.3-14.07-21.11M8.39 50.55a42.87 42.87 0 0 1 2.44-7.75c1.6-3.71 4.36-8.29 8.66-10A14.63 14.63 0 0 0 30 36.75a14.63 14.63 0 0 0 10.51-3.92c4.3 1.68 7.06 6.26 8.66 10a43.11 43.11 0 0 1 2.44 7.75c-3.28 2-13 7.37-21.61 7.37s-18.32-5.42-21.61-7.4"
        transform="translate(-6.08)"
      />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
