import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 60 60"
      style={style}
      {...rest}
    >
      <path d="M30 37.63c-1.51 0-2.74 1.23-2.74 2.74 0 .55.16 1.07.45 1.5.22.33.5.61.83.82v3.89c0 .14.06.29.16.39.1.1.24.16.39.16h1.83c.14 0 .29-.06.39-.16.1-.1.16-.24.16-.39v-3.89c.33-.21.62-.49.83-.82.28-.43.45-.95.45-1.5-.01-1.52-1.24-2.74-2.75-2.74z" />
      <path d="M47.58 23.68v-6.73c0-2.88-.75-5.6-2.07-7.98C42.55 3.63 36.72 0 30 0S17.45 3.63 14.49 8.97c-1.33 2.38-2.07 5.1-2.07 7.98v6.73c-2.72.04-4.92 2.2-4.92 4.82v26.68c0 2.65 2.24 4.82 5 4.82h35c2.76 0 5-2.17 5-4.82V28.5c0-2.62-2.2-4.78-4.92-4.82zm-33.09-6.73C14.49 8.69 21.43 2 30 2s15.51 6.69 15.51 14.95v6.73H14.49v-6.73zm35.94 38.66c0 1.31-1.11 2.39-2.48 2.39h-35.9c-1.37 0-2.48-1.08-2.48-2.39V28.08c0-1.32 1.11-2.4 2.48-2.4h35.89c1.37 0 2.48 1.08 2.48 2.4v27.53z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
