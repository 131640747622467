import styled from 'styled-components';
import Icon from '../Icon';
import { useContext, useState } from 'react';
import RestaurantContext from '../../Context/RestaurantContext';
import ArticleConfigurationContext from '../../Context/ArticleConfigurationContext';
import { Button, InputGroup, FormControl, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import RegionSelectorModal from '../RegionSelectorModal';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  h3 {
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.8px;
    text-align: center;
    color: #000000;
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    letter-spacing: 0.66px;
    font-weight: 400;
    margin-bottom: 15px;
  }
`;

const PositionIconWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 25px;
`;

const CloseButtonWrapper = styled.div`
  @media (min-width: 992px) {
    display: none;
  }

  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background: white;
  box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.13);
  text-align: center;
  user-select: none;
  cursor: pointer;

  transition: box-shadow 0.1s ease-in;
  &:hover {
    box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.3);
  }

  & > svg {
    margin-top: 8px;
    vertical-align: bottom;
  }
`;

const ScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 75px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }

  #button-addon {
    padding: 0 10px;
    font-size: 14px;
    font-weight: 400;
    min-width: 125px;
    letter-spacing: 0.62px;
  }

  #zipLabel {
    white-space: nowrap;
    font-weight: 300;
  }

  #zip {
    height: 40px;
    padding: 10px;
    font-size: 15px;
    padding-top: 1.25rem;
  }
  .form-floating > .form-control:focus ~ #zipLabel,
  .form-floating > .form-control:not(:placeholder-shown) ~ #zipLabel,
  .form-floating > .form-select ~ #zipLabel {
    font-weight: 400;
    transform: scale(0.75) translateY(-0.5rem) translateX(0.1rem); // none !important;
  }
`;

const NoDeliveryRegionSelectedComponent = (props) => {
  const {
    setShowCartModal,
    restaurantProps: storeProps,
    setSelectedDeliveryRegion,
  } = useContext(RestaurantContext);

  const { showConfigModal, resetArticleSelection } = useContext(ArticleConfigurationContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectionModalState, setSelectionModalState] = useState(() => ({
    show: false,
    regionList: [],
  }));

  const onRegionSelected = (region, fromZip, fromModal = false) => {
    // close modal if was shown
    if (fromModal) hideRegionModalOnSelect();

    // save region selection to context
    setSelectedDeliveryRegion(region, fromZip);

    // show toast as success!!
    toast.success(`Liefergebiet geändert, vielen Dank.`, {
      position: 'bottom-right',
      hideProgressBar: true,
    });
  };

  const hideRegionModal = () => {
    if (showConfigModal) resetArticleSelection();
    setShowCartModal(false);
    setSelectionModalState((state) => ({ ...state, show: false }));
  };

  const hideRegionModalOnSelect = () => {
    setSelectionModalState((state) => ({ ...state, show: false }));
  };

  const hasErrors = () => {
    return errors?.zip && true;
  };

  const { ref: zipFormRef, ...zipRest } = register('zip', {
    required: { value: true, message: 'PLZ benötigt' },
    maxLength: { value: 5, message: 'PLZ ungültig' },
    minLength: { value: 5, message: 'PLZ ungültig' },
    pattern: { value: /^[0-9]{1,5}$/, message: 'PLZ ungültig' },
  });
  const getErrorMessage = (field) => {
    return field?.message || 'undefined';
  };

  const onSubmit = ({ zip }) => {
    const matchingRegionsList = [];
    // get all deliveryregions containing this zip
    storeProps.deliveryRegionsRaw?.forEach((deliveryRegion) => {
      if (deliveryRegion.zipCodes?.includes(zip)) {
        matchingRegionsList.push(deliveryRegion);
      }
    });

    if (matchingRegionsList.length > 1) {
      // show selectionModal with fetched data amk
      setSelectionModalState((state) => ({
        ...state,
        show: true,
        regionList: matchingRegionsList,
        forZip: zip,
      }));
    } else if (matchingRegionsList.length === 1) {
      // store selection in context and send to menu page
      onRegionSelected(matchingRegionsList[0], zip);
    } else {
      // show error to user
      toast.error(`Die PLZ ${zip} liegt leider außerhalb unseres Liefergebietes.`, {
        theme: 'colored',
      });
    }
  };

  return (
    <>
      <Wrapper>
        <CloseButtonWrapper onClick={hideRegionModal}>
          <Icon style={{ width: 18 }} name="cross" />
        </CloseButtonWrapper>

        <ScrollWrapper>
          <PositionIconWrapper>
            <Icon name="position" style={{ height: '60px' }} />
          </PositionIconWrapper>
          <h3>Wohin soll&apos;s gehen?</h3>
          <p>
            Bitte teile uns dein <strong>Liefergebiet</strong> mit, damit geprüft werden kann, ob
            wir zu dir liefern können.
          </p>

          <FormWrapper>
            <Form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)} noValidate>
              <InputGroup style={{ flexWrap: 'nowrap' }}>
                <div className="form-floating" style={{ flexGrow: 1 }}>
                  <FormControl
                    ref={zipFormRef}
                    isInvalid={hasErrors()}
                    name="zip"
                    size="lg"
                    maxLength={5}
                    aria-label="PLZ eingeben"
                    id="zip"
                    placeholder="PLZ eingeben"
                    type="tel"
                    {...zipRest}
                  />
                  {hasErrors() ? (
                    <label id="zipLabel" htmlFor="zip" style={{ color: 'var(--bs-warning)' }}>
                      {getErrorMessage(errors.zip)}
                    </label>
                  ) : (
                    <label id="zipLabel" htmlFor="zip">
                      Deine Postleitzahl
                    </label>
                  )}
                </div>
                <Button variant="primary" type="submit" id="button-addon">
                  Weiter geht&apos;s!
                </Button>
              </InputGroup>
            </Form>
          </FormWrapper>
        </ScrollWrapper>
      </Wrapper>
      <RegionSelectorModal
        show={selectionModalState.show}
        onHide={hideRegionModal}
        onSelection={(selectedRegion) =>
          onRegionSelected(selectedRegion, selectionModalState.forZip, true)
        }
        forZip={selectionModalState.forZip}
        regionsList={selectionModalState.regionList}
        currentDeliveryTime={storeProps.currentDeliveryTime}
      />
    </>
  );
};

export default NoDeliveryRegionSelectedComponent;
