import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.04 43.04" style={style}>
      <path d="m58.51 0-19 19L38 20.51 22.52 35.98 3.54 17 0 20.54l20.51 20.5.01-.01 2.02 2.01 19-19 1.5-1.5 19-19L58.51 0z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
