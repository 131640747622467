import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} viewBox="0 0 60 45.58">
      <path d="M46.73 20.49c-1.14.64-2.27 1.28-3.4 1.94l-.22.11-3.27 1.88c-2.12 1.2-4.25 2.41-6.37 3.63a6.47 6.47 0 0 1-6.88.05l-.66-.38-3.1-1.77-14.5-8.27c-2.11-1.2-4.24-2.37-6.33-3.63 0 0-1.33-.71-2-1.09v27.83a4.79 4.79 0 0 0 2.84 4.36l.36.13h.07a4.36 4.36 0 0 0 1.49.25h50.48a4.36 4.36 0 0 0 1.49-.25l.21-.07h.15a4.79 4.79 0 0 0 2.85-3.66 4.4 4.4 0 0 0 .06-.76V12.93c-.67.37-9.61 5.47-13.27 7.56ZM58 40.64a2.94 2.94 0 0 1-2 2.8h-.15a2.46 2.46 0 0 1-.72.1H4.94a2.9 2.9 0 0 1-1.14-.23A3 3 0 0 1 2 40.64V16.4l.26.13h.06c.3.16.58.3.85.46Q12.56 22.38 22 27.73l3.93 2.22a8 8 0 0 0 8.25 0l10.11-5.74 13.19-7.52.55-.29v.19Z" />
      <path d="M59.94 4.05a4.4 4.4 0 0 0-.28-1 4.75 4.75 0 0 0-4.41-3H4.76A4.74 4.74 0 0 0 .5 2.68a2.37 2.37 0 0 0-.17.36A4.75 4.75 0 0 0 0 4.79v6.24l2 1.14s15.86 9.09 23.61 13.5l2 1.14a4.56 4.56 0 0 0 4.8 0l5.74-3.27 2-1.14 10.9-6.22c2.1-1.19 4.19-2.4 6.3-3.58a5.25 5.25 0 0 0 .61-.38c.67-.37 1.33-.75 2-1.14V4.79a4.37 4.37 0 0 0-.02-.74ZM58 8.57a2.76 2.76 0 0 1-1.18 2 4.55 4.55 0 0 1-.43.28l-16.88 9.58-1.36.78-2 1.13-1.26.72c-1.14.64-2.27 1.28-3.4 1.94a3.06 3.06 0 0 1-1.54.45 2.87 2.87 0 0 1-1.45-.42l-.91-.52-.12-.07L3.3 10.65a2.16 2.16 0 0 1-.3-.24 2.49 2.49 0 0 1-1-1.68V4.94a2.94 2.94 0 0 1 1.43-2.52A2.88 2.88 0 0 1 4.94 2h50.12A2.94 2.94 0 0 1 58 4.94Z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
