import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.33 60" {...rest}>
      <path d="M54.16 1.55c0-.5-.1-1-.17-1.5-.51 0-1 .06-1.51.1C38.84 1.25 28.33 8.37 28.33 17v1.24A8.78 8.78 0 0030.5 24a18.66 18.66 0 012 2 126.34 126.34 0 00-5.18 21C22.99 20.29 12.77 1.27.73 0H.61C.33 0 .09.33.03.76a2 2 0 000 .25 2 2 0 000 .25.89.89 0 00.45.74H.6c10.91 1.56 19.88 25.88 22.12 58h2c-1.46-20.18-5.64-37.39-11.35-48.15 6.15 9.47 10.79 25.04 12.79 43.61.16 1.49.31 3 .43 4.52h1.47c.13-1.53.27-3 .43-4.52a139.53 139.53 0 015.73-28 1.55 1.55 0 01.08-.23s.06-.3.09-.28c1.87 1.15 7.29 1 7.52 1C50.3 26.39 55.38 15 54.16 1.55zm-7.28 22.08a10 10 0 01-5.24 2.83l-.71.1a10.65 10.65 0 01-6-1.24 64.54 64.54 0 016.17-13.15.16.16 0 000-.07.45.45 0 00.07-.25c0-.41-.48-.73-1.06-.73a1.22 1.22 0 00-.89.32 1.88 1.88 0 00-.12.18 68.38 68.38 0 00-6 12.56 1.34 1.34 0 01-.07.18l-.21-.23-.35-.42a10.79 10.79 0 01-2.59-6 5.79 5.79 0 010-.72 10.06 10.06 0 011.83-5.67c3.42-5.13 11.38-8.92 21-9.67.83 9.4-1.46 17.75-5.83 21.98z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
