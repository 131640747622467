import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, ModalDialog, Row, Col } from 'react-bootstrap';
import { Currency } from 'react-intl-number-format';
import Icon from './Icon';

const FaModal = styled(ModalDialog)`
  .modal-content {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  @media (min-width: 576px) {
    padding-top: 250px;

    .modal-content {
      padding: 15px;
    }
  }
`;

const HeadingWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 100%;

  h3 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.7px;
    margin-bottom: 0;
    color: #000000;
  }
  span {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.61;
  }
`;

const RegionEntry = styled.div`
  position: relative;
  background-color: #ffffff;
  margin-top: 10px;
  cursor: pointer;
  display: flex;

  box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.13);
  &:hover {
    box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.25);
  }
  transition: box-shadow 0.2s;

  h4 {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
  }

  @media (min-width: 576px) {
    margin-left: -15px;
    margin-right: -15px;
  }
`;

const RegionDescriptionDiv = styled.div`
  position: relative;
  width: calc(100% - 60px);
  border-right: 1px solid rgba(43, 43, 43, 0.07);

  padding: 15px 15px 35px 15px;
`;

const RegionDetailsDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  padding: 0 15px 10px 15px;

  font-size: 10px;
  font-style: italic;
  color: #696969;
`;

const RegionSelector = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 99;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background: white;
  box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.13);
  &:hover {
    box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.25);
  }
  transition: box-shadow 0.2s;
  text-align: center;
  user-select: none;

  & > svg {
    margin-top: 8px;
    vertical-align: bottom;
  }
`;

const RegionSelectorHandle = styled.div`
  display: flex;
  height: 15px;
`;

function RegionSelectorModal({
  regionsList,
  forZip,
  currentDeliveryTime,
  onHide,
  onSelection,
  ...props
}) {
  return (
    <Modal fullscreen={'sm-down'} dialogAs={FaModal} onHide={onHide} {...props}>
      <CloseButtonWrapper className="d-sm-none" onClick={onHide}>
        <Icon style={{ width: 18 }} name="cross" />
      </CloseButtonWrapper>
      <HeadingWrapper>
        <Icon style={{ height: 50, marginBottom: 15 }} name="position" />
        <h3>Wähle Dein Wohngebiet</h3>
        <span>im Bereich der PLZ {forZip || 0}</span>
      </HeadingWrapper>{' '}
      {regionsList.map((region) => (
        <RegionEntry key={region._id} onClick={() => onSelection(region)}>
          <RegionDescriptionDiv>
            <h4>{region.title}</h4>
            <RegionDetailsDiv>
              <Row>
                <Col xs={4} className="nowrap">
                  <Icon style={{ height: 12, marginRight: 5 }} name="cart" />
                  <span style={{ verticalAlign: 'middle' }}>
                    ab <Currency as="span">{region.minOrderValue}</Currency>
                  </span>
                </Col>
                <Col xs={4} className="nowrap text-center">
                  <Icon style={{ height: 12, marginRight: 5 }} name="scooter-min" />
                  <span style={{ verticalAlign: 'middle' }}>
                    {region.deliveryPrice > 0 ? (
                      <Currency as="span">{region.deliveryPrice}</Currency>
                    ) : (
                      <span>Gratis</span>
                    )}
                  </span>
                </Col>
                <Col xs={4} className="nowrap text-end">
                  <Icon style={{ height: 12, marginRight: 5 }} name="watch" />
                  <span style={{ verticalAlign: 'middle' }}>{currentDeliveryTime || '60'} min</span>
                </Col>
              </Row>
            </RegionDetailsDiv>
          </RegionDescriptionDiv>
          <RegionSelector>
            <RegionSelectorHandle>
              <Icon
                style={{
                  width: 9,
                  opacity: 0.5,
                }}
                name="arrow-next"
              />
            </RegionSelectorHandle>
          </RegionSelector>
        </RegionEntry>
      ))}
    </Modal>
  );
}

RegionSelectorModal.propTypes = {
  regionsList: PropTypes.array.isRequired,
  forZip: PropTypes.string,
  currentDeliveryTime: PropTypes.number,
  onHide: PropTypes.func.isRequired,
  onSelection: PropTypes.func.isRequired,
};

export default RegionSelectorModal;
