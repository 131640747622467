import { createRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme, css } from 'styled-components';
import Icon from '../Icon';

const Wrapper = styled.div`
  position: relative;
  padding: 10px 0 0 0;
  background-color: #ffffff;
  box-shadow: 0 3px 9px RGBA(120, 130, 140, 0.13);
  border-radius: 4px;
`;

const Padder = styled.div`
  padding: 12px 10px 22px 10px;
  ${(props) =>
    props.$canClick &&
    css`
      cursor: pointer;
    `}
`;

const TitleAndIconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 100%;
  margin-right: 15px;
  justify-content: center;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-flow: column;
`;
const Title = styled.h2`
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.66px;
`;
const SubTitle = styled.span`
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.66px;
`;

const ExpandableWrapper = styled.div`
  overflow: hidden;
  height: ${(props) => (props.$initiallyOpened ? '100%' : '0')};

  transition: height 0.3s ease-out;
`;

const InnerWrapper = styled.div`
  padding: 5px 10px 10px 10px;
  transition: height 0.3s ease-out;
`;

const TriangleIconWrapper = styled.div`
  padding-right: 5px;
  padding-left: 15px;
`;

const IconTransformWrapper = styled.div`
  transition: all 100ms linear;
  transform: rotate(0);
`;

const ExpandableBoxComponent = (props) => {
  const expandableRef = createRef();
  const innerWrapperRef = createRef();
  const iconRef = createRef();

  const [opened, setOpened] = useState(props.permanentlyOpenend || props.$initiallyOpened || false);

  const openOrCloseBox = useCallback(() => {
    if (opened) {
      // we will expand the content box
      const el = expandableRef.current;
      const sectionHeight = el.scrollHeight;
      el.style.height = `${sectionHeight}px`;
      if (iconRef?.current) iconRef.current.style.transform = 'rotate(180deg)';
    } else {
      const el = expandableRef.current;
      el.style.height = '0px';

      if (iconRef?.current) iconRef.current.style.transform = 'rotate(0deg)';
    }
  }, [expandableRef, iconRef, opened]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (opened && expandableRef?.current && entries && entries[0]?.borderBoxSize[0]?.blockSize) {
        let height = entries[0].borderBoxSize[0].blockSize;
        expandableRef.current.style.height = `${height}px`;
      }
    });

    openOrCloseBox();

    const innerWrapper = innerWrapperRef.current;
    resizeObserver.observe(innerWrapper, { box: 'border-box' });
    return () => resizeObserver.unobserve(innerWrapper);
  }, [opened, openOrCloseBox, expandableRef, innerWrapperRef]);

  useEffect(() => {
    openOrCloseBox();
  }, [props.recalculateOnChangeOfProp, openOrCloseBox]);

  useEffect(() => {
    if (props.$initiallyOpened || props.permanentlyOpenend) {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [props.$initiallyOpened, props.permanentlyOpenend]);

  return (
    <Wrapper>
      <Padder
        onClick={() => !props.permanentlyOpenend && setOpened((state) => !state)}
        $canClick={!props.permanentlyOpenend}
        style={!props.iconName ? { paddingLeft: '15px', paddingRight: '15px' } : {}}
      >
        <TitleAndIconWrapper>
          {props.iconName && (
            <IconWrapper>
              <Icon
                name={props.iconName}
                style={props.iconStyle || { maxWidth: '100%', maxHeight: '100%' }}
              />
            </IconWrapper>
          )}
          <TitleWrapper>
            <Title>{props.title}</Title>
            {props.subTitle && <SubTitle>{props.subTitle}</SubTitle>}
          </TitleWrapper>

          {!props.permanentlyOpenend && (
            <TriangleIconWrapper>
              <IconTransformWrapper ref={iconRef}>
                <Icon style={{ fill: props.theme.colors.primary, height: '6px' }} name="triangle" />
              </IconTransformWrapper>
            </TriangleIconWrapper>
          )}
        </TitleAndIconWrapper>
      </Padder>

      <ExpandableWrapper
        ref={expandableRef}
        $initiallyOpened={props.$initiallyOpened}
        style={{ height: props.$initiallyOpened || props.permanentlyOpenend ? 'auto' : '0px' }}
      >
        <InnerWrapper ref={innerWrapperRef} style={props.contentWrapperStyle || {}}>
          {props.children}
        </InnerWrapper>
      </ExpandableWrapper>
    </Wrapper>
  );
};

ExpandableBoxComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  iconName: PropTypes.string,
  iconStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  permanentlyOpenend: PropTypes.bool,
  $initiallyOpened: PropTypes.bool,
  recalculateOnChangeOfProp: PropTypes.any,
  contentWrapperStyle: PropTypes.object,
};

export default withTheme(ExpandableBoxComponent);
