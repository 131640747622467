import PropTypes from 'prop-types';

const SVG = ({
  style = {
    fill: '#000000',
  },
}) => (
  <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.24 60">
    <path d="M51.23 47.79a28.8 28.8 0 00-.37-4.21C48.46 30.12 39.1 20.71 39.1 20.71a39 39 0 00-5.83-4.83l.26-.49 2.29-4.19 1.33-2.45 2.69-4.93.48-.88a1.08 1.08 0 00-.42-1.44c-.27-.15-.56-.29-.86-.42A12.36 12.36 0 0033.96 0a12.19 12.19 0 00-7.23 2.33 1.09 1.09 0 01-1.21 0 12.42 12.42 0 00-13.55.46 10.57 10.57 0 00-.83.67 1.05 1.05 0 00-.2 1.39l.64 1 1.83 2.8 1.62 2.46 2.8 4.26.26.41a38.72 38.72 0 00-5.95 4.91S2.78 30.12.38 43.58a28.8 28.8 0 00-.37 4.21 12.43 12.43 0 00.27 3.29 13.08 13.08 0 00.69 2 15.23 15.23 0 001.23 2.19 13.45 13.45 0 004.08 3.59 9.62 9.62 0 004 1.09H41.36A9.49 9.49 0 0045 58.89a13.45 13.45 0 004.08-3.59 15.23 15.23 0 001.23-2.19 13.08 13.08 0 00.69-2 12.43 12.43 0 00.23-3.32zM17.78 11.66l-1.71-2.6-2.45-3.65a1.07 1.07 0 01.37-1.51 10.44 10.44 0 0110.68.33 8.66 8.66 0 01.82.6 1 1 0 001.39 0c.25-.24.52-.46.79-.68a10.38 10.38 0 019.15-1.69 1.06 1.06 0 01.62 1.53l-2.82 5.16-1.35 2.55-1.65 3.1a1 1 0 01-.93.55h-9.9a1 1 0 01-.88-.48l-.13-.19zM48.88 51a9.49 9.49 0 01-3.56 5.18A9.9 9.9 0 0141.26 58c-16.55.21-28.63.22-30.53 0a10.29 10.29 0 01-2.11-.45 8.77 8.77 0 01-2.54-1.35 10.49 10.49 0 01-2.12-2.39 12.22 12.22 0 01-1.73-6.57 17.3 17.3 0 011-6.45 64.49 64.49 0 012.64-6.69 41.71 41.71 0 014.86-8.45 36.21 36.21 0 016.47-6.52c.85-.66 1.68-1.24 2.46-1.75h11.96a22.76 22.76 0 013.33 2.1 23.74 23.74 0 015.33 5.61 40.65 40.65 0 013.51 5.16 51.17 51.17 0 013.39 7.49 31.84 31.84 0 011.32 4 32.08 32.08 0 01.7 4.38 13.15 13.15 0 01.12 2.35 11.91 11.91 0 01-.44 2.53z" />
    <path d="M32.38 48.43a8.05 8.05 0 01-4.46 1.26A7.83 7.83 0 0121.85 47a9.18 9.18 0 01-2.15-5.14h-1.86v-1.45h1.71V40c0-.47 0-.92.06-1.34h-1.77v-1.5h2a9.43 9.43 0 012.45-5.06 7.91 7.91 0 015.92-2.48 8.47 8.47 0 014 1l-.6 2a6.73 6.73 0 00-3.35-.84 5.21 5.21 0 00-4 1.64 7.16 7.16 0 00-1.79 3.8h8.35v1.5h-8.61c0 .39-.06.84-.06 1.25v.54h8.68v1.47h-8.47A7.28 7.28 0 0024 45.9a5.73 5.73 0 004.22 1.73 7.82 7.82 0 003.65-1z" />
  </svg>
);

SVG.propTypes = {
  style: PropTypes.object.isRequired,
};

export default SVG;
