import React from 'react';
import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" style={style} {...rest}>
      <path
        fillRule="evenodd"
        d="M34 30l26 26-4 4-26-26L4 60l-4-4 26-26L0 4l4-4 26 26L56 0l4 4-26 26z"
      />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
