import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" style={style} {...rest}>
      <path
        d="M30 57.86A27.86 27.86 0 102.14 30 27.86 27.86 0 0030 57.86zM30 60a30 30 0 1130-30 30 30 0 01-30 30zm1.07-31.07H45v2.14H31.07V45h-2.14V31.07H15v-2.14h13.93V15h2.14z"
        fillRule="evenodd"
      />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
