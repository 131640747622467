import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ExpandableWrapper = styled.div`
  position: relative;
  ${(props) => {
    if (props.$hasOverflow) {
      return `height: ${props.$isExpanded ? props.$scrollHeight : props.$maxHeight}px`;
    }
    return null;
  }};
  overflow: hidden;
  transition: height 200ms ease-out;
`;

const HidingGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  pointer-events: none;
`;

const ShowMoreDiv = styled.div`
  position: relative;
  width: 100%;
  text-align: right;
`;

const Btn = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  background: none;
  border: none;
  outline: none !important;
`;

function ShowMoreListComponent(props) {
  const expandableBoxRef = useRef(null);

  const [hasOverflow, setHasOverflow] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);

  const { maxHeight, showMoreLabel, ...rest } = props;

  useEffect(() => {
    function _hasOverflow() {
      if (expandableBoxRef.current) {
        return expandableBoxRef.current.scrollHeight > maxHeight;
      }
      return false;
    }

    setScrollHeight(expandableBoxRef.current.scrollHeight);
    setHasOverflow(_hasOverflow());
  }, [maxHeight]);

  const buttonClicked = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      <ExpandableWrapper
        ref={expandableBoxRef}
        $scrollHeight={scrollHeight}
        $isExpanded={isExpanded}
        $hasOverflow={hasOverflow}
        $maxHeight={maxHeight}
        $showMoreLabel={showMoreLabel}
        {...rest}
      >
        {rest.children}
        {hasOverflow && !isExpanded ? <HidingGradient /> : null}
      </ExpandableWrapper>
      <ShowMoreDiv>
        {hasOverflow ? (
          <Btn type="button" $show={hasOverflow} onClick={buttonClicked}>
            {isExpanded ? 'Zeige weniger' : props.showMoreLabel}
          </Btn>
        ) : null}
      </ShowMoreDiv>
    </>
  );
}

ShowMoreListComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  maxHeight: PropTypes.number.isRequired,
  showMoreLabel: PropTypes.string,
};

ShowMoreListComponent.defaultProps = {
  showMoreLabel: 'Zeige mehr',
};

export default ShowMoreListComponent;
