import GlobalStyle from '../styles/globalStyles';
import styled from 'styled-components';
import Head from 'next/head';
import Image from 'next/legacy/image';
import FaDeLogo from '../public/svg/foodalley-de-logo.svg';
import { useEffect } from 'react';

const Root = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: white;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding: 0 15px;

  h1 {
    font-weight: 700;

    font-size: 18px;
    letter-spacing: 0.66px;
    @media (min-width: 992px) {
      font-size: 23px;
      letter-spacing: 1.02px;
    }
  }

  h2 {
    font-weight: 300;

    font-size: 18px;
    letter-spacing: 0.66px;
    @media (min-width: 992px) {
      font-size: 21px;
      letter-spacing: 0.93px;
    }
  }
`;

const LottieContainer = styled.div`
  max-width: 370px;
  margin-bottom: 35px;

  dotlottie-player {
    height: 100%;
    width: 100%;
  }
`;

const FaLogoWrapper = styled.div`
  position: relative;
  display: block;

  width: 100%;
  height: 100px;
  margin-top: 35px;
  @media (min-width: 992px) {
    margin-bottom: 0;
    height: 100px;
    width: 100%;
  }
`;

function StoreBlockedPage(props) {
  useEffect(() => {
    import('@dotlottie/player-component');
  }, []);

  return (
    <>
      <Head>
        <title>Restaurant nicht gefunden - FoodAlley.de</title>
        <meta
          name="description"
          content="Das gewünschte Restaurant scheint nicht mehr bei uns zu sein. Bestelle jetzt über FoodAlley.de bei Deinem Lieblingsrestaurant. Schnell, einfach &amp; fair"
        />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1, maximum-scale=1, viewport-fit=cover"
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=2" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=2" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=2" />
        <link rel="icon" href="/favicon.ico?v=2" />
        <link rel="manifest" href="/site.webmanifest?v=2" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg?v=2" color="#5bbad5" />
        <link rel="shortcut icon" href="/favicon.ico?v=2" />
        <meta name="msapplication-TileColor" content="#2f9f2f" />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <GlobalStyle />
      <Root>
        <Container>
          <MessageContainer>
            <LottieContainer>
              <dotlottie-player
                autoplay
                loop
                mode="client"
                src="/LottieFiles/search-blocked.lottie"
              />
            </LottieContainer>
            <h1>Restaurant konnte nicht gefunden werden</h1>
            <h2>Schau mal rein und bestelle bei einem anderen tollen Restaurant auf:</h2>

            <FaLogoWrapper>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a href="https://www.foodalley.de">
                <Image src={FaDeLogo} alt="FoodAlley.de" layout="fill" />
              </a>
            </FaLogoWrapper>
          </MessageContainer>
        </Container>
      </Root>
    </>
  );
}

export default StoreBlockedPage;
