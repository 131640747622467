import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { useContext, useState, useEffect, useRef } from 'react';
import RestaurantContext from '../Context/RestaurantContext';
import CartContext from '../Context/CartContext';

const BadgeWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.warning};
  color: RGB(255, 255, 255);
  font-size: 14px;
  opacity: 0.9;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 6px 7px;
  position: fixed;
  z-index: 4;
  box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.13);
`;

const CounterString = styled.p`
  font-size: 12px;
  font-weight: 400;
  border-left: solid 1px ${(props) => props.theme.colors.warning};
  padding-left: 11px;
  margin-top: 10px;
  margin-bottom: 0px;

  & > span {
    color: ${(props) => props.theme.colors.warning};
    font-weight: 700;
  }
`;

function ClosingTimeCounter(variant) {
  const {
    restaurantProps,
    setIsClosedByLastMoment,
    getOverallStatus,
    minutesUntilLastMomentSinceRefetch,
  } = useContext(RestaurantContext);

  const { refetchDeliveryTimes } = useContext(CartContext);

  const [lastMinutes, setLastMinutes] = useState();
  const interval = useRef();

  useEffect(() => {
    function calcLastMinutes() {
      const closingTime = new Date(restaurantProps.statusData.payload.lastMoment).setSeconds(0, 0);
      return Math.ceil((closingTime - new Date().getTime()) / 60000);
    }
    // refetch cart (DeliveryTimes);
    refetchDeliveryTimes();

    const _lastMinutes = calcLastMinutes();
    setLastMinutes(_lastMinutes);

    if (lastMinutes > 0 && lastMinutes < 31) {
      if (interval.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(() => {
        const minutesTogo = calcLastMinutes();

        if (minutesTogo <= 0) {
          setLastMinutes(minutesTogo);
          setIsClosedByLastMoment(true);
        }

        if (minutesTogo > 0) {
          setLastMinutes(minutesTogo);
        }
      }, 6000);
    }

    return function cleanup() {
      clearInterval(interval.current);
    };
  }, [
    minutesUntilLastMomentSinceRefetch,
    restaurantProps.statusData?.payload?.lastMoment,
    setIsClosedByLastMoment,
    lastMinutes,
    refetchDeliveryTimes,
  ]);

  useEffect(() => {
    if (lastMinutes <= 0) {
      clearInterval(interval.current);
    }
  }, [lastMinutes]);

  return (
    <>
      {lastMinutes > 0 && lastMinutes < 31 && getOverallStatus(restaurantProps) === 'OPENED' ? (
        <>
          {variant.variant === 'menu' ? (
            <BadgeWrapper>
              noch <span style={{ fontWeight: 700 }}>{lastMinutes}</span> min
            </BadgeWrapper>
          ) : (
            <CounterString>
              Das Restaurant hat nur noch{' '}
              <span>
                {lastMinutes} Minute{lastMinutes > 1 && 'n'}
              </span>{' '}
              geöffnet{' '}
            </CounterString>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

ClosingTimeCounter.propTypes = {
  variant: PropTypes.string,
};

export default withTheme(ClosingTimeCounter);
