import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" style={style} {...rest}>
      <g data-name="Ebene 2">
        <path d="M64 0a64 64 0 1064 64A64 64 0 0064 0zm0 123a59 59 0 1159-59 59 59 0 01-59 59zM31.17 59.83z" />
        <path d="M93.98 41.48l-19 19-1.5 1.5L58 77.46 39.02 58.48l-3.54 3.54 20.5 20.5.02-.01 2.02 2.01 19-19 1.5-1.5 19-19-3.54-3.54z" />
      </g>
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
