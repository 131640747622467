import React from 'react';
import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.4 60" style={style} {...rest}>
      <path d="M32.56 31.27a3.88 3.88 0 00-2.62-3.67V14.93a1.16 1.16 0 00-2.32 0v12.62a3.87 3.87 0 00.53 7.54l.89 6a1.16 1.16 0 002.29-.34l-.89-6a3.86 3.86 0 002.12-3.48z" />
      <path d="M47.85 9.87l1.43-.52a1.57 1.57 0 00.38-.14A1.4 1.4 0 0049 6.56a1.38 1.38 0 00-1.07.51l-1-.8a1.4 1.4 0 10-2.65-.65v1.55a28.48 28.48 0 00-13.14-4.53v-.2a2.44 2.44 0 10-4.87 0 1.36 1.36 0 000 .2 28.71 28.71 0 1021.58 7.23zM28.7 57.68a26.42 26.42 0 01-.21-52.83 1.81 1.81 0 00.23 0h.23a26.41 26.41 0 01-.25 52.82z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
