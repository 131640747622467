import '../styles/bootstrap.scss';
import '../styles/fonts.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'moment/locale/de';
import 'core-js/actual/structured-clone';
import * as smoothscroll from 'smoothscroll-polyfill';
import { useEffect } from 'react';
import Head from 'next/head';
import Error from 'next/error';
import styled, { ThemeProvider, css } from 'styled-components';
import GlobalStyle from '../styles/globalStyles';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { ToastContainer } from 'react-toastify';
import { COOKIE_CONSENT_NAME, RestaurantContextProvider } from '../Context/RestaurantContext';
import { CartContextProvider } from '../Context/CartContext';
import { ArticleConfigurationContextProvider } from '../Context/ArticleConfigurationContext';

import { IntlProvider } from 'react-intl-number-format';
import CookieConsent from '../components/CookieConsent';
import CartAndConfiguratorMobileModal from '../components/Cart/CartAndConfiguratorMobileModal';
import GoogleTagManagerComponent from '../components/ConsentRequiredComponents/GoogleTagManagerComponent';
import StoreBlockedPage from '../components/StoreBlockedPage';
import LoginModal from '../components/MemberArea/LoginModal';
import { UserContextProvider } from '../Context/UserContext';
import rewriteAssetPath from '../util/rewriteAssetPath';
import { generateStoreLd } from '../util/structuredDataHelpers';
import FacebookSdkConsentAware from '../components/ConsentRequiredComponents/FacebookSdkConsentAware';

const intlConfig = {
  locale: 'de-DE',
  options: {
    currency: 'EUR',
    maximumFractionDigits: 2,
  },
};

const theme = {
  colors: {
    primary: '#2f9f2f',
    warning: '#ec971f',
  },
};

const Root = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-flow: column;
  background: white;
`;

export const HeaderSpacer = styled.div`
  height: 50px;
  @media (min-width: 768px) {
    height: 64px;
  }
`;

export const ContentPadder = styled.div`
  padding-top: 30px;
  @media (min-width: 768px) {
    padding-top: 60px;
  }

  ${(props) =>
    !props.$noBottomPadding &&
    css`
      padding-bottom: 30px;
      @media (min-width: 768px) {
        padding-bottom: 60px;
      }
    `}
`;

const StyledToastContainer = styled(ToastContainer)`
  --toastify-color-success: #2f9f2f;
  --toastify-color-warning: #ec971f;
  --toastify-color-error: #e74c3c;
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    padding: 12px;
  }
  .Toastify__toast-body {
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    letter-spacing: 0.75px;
    padding: 0px;

    & p {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
    & p ~ span {
      font-size: 14px;
      font-weight: 300;
      display: inline-block;
    }

    &&& > div:last-child {
      display: flex;
    }
  }
  .Toastify__progress-bar {
  }

  .Toastify__close-button {
    align-self: center;
  }

  .Toastify__toast-body > div:last-child {
    display: flex;
    flex-flow: column;
  }
`;

export const COOKIE_RWG_TOKEN = 'rwg_token';
function App({ Component, pageProps, restaurantData, onlyPickUp, env, errorCode, url }) {
  useEffect(() => smoothscroll.polyfill(), []);

  if (errorCode) {
    switch (errorCode) {
      case 209:
        return <StoreBlockedPage />;
      default:
        return <Error statusCode={errorCode} />;
    }
  }

  return (
    <>
      <Head>
        <title>{restaurantData.seoTitle}</title>
        <meta name="description" content={restaurantData.seoDescription} />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={restaurantData.seoTitle} />
        <meta property="og:description" content={restaurantData.seoDescription} />
        <meta
          property="og:image"
          content={rewriteAssetPath(restaurantData.titleImagePath, env.APP_ENV)}
        />
        <meta property="og:image:alt" content="website image" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={url} />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={restaurantData.seoTitle} />
        <meta property="twitter:description" content={restaurantData.seoDescription} />
        <meta
          property="twitter:image"
          content={rewriteAssetPath(restaurantData.titleImagePath, env.APP_ENV)}
        />
        <meta property="twitter:image:alt" content="website image" />

        {/* structured data */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: generateStoreLd(restaurantData, env, url) }}
        ></script>

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=2" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=2" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=2" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico?v=2" />
        <link rel="manifest" href="/site.webmanifest?v=2" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg?v=2" color="#5bbad5" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico?v=2" />
        <meta name="msapplication-TileColor" content="#2f9f2f" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <RestaurantContextProvider
        value={{ ...restaurantData, onlyPickUp }}
        env={env}
        cookieConsent={pageProps.cookieConsent}
        rwgToken={pageProps.rwg_token}
      >
        <CartContextProvider>
          <ArticleConfigurationContextProvider>
            <GlobalStyle />
            <GoogleTagManagerComponent />
            <FacebookSdkConsentAware />
            <ThemeProvider theme={theme}>
              <IntlProvider config={intlConfig}>
                <CookieConsent />
                <UserContextProvider>
                  <LoginModal />
                  <Root>
                    <>
                      {Component.getLayout ? (
                        Component.getLayout(<Component {...pageProps} env={env} />)
                      ) : (
                        <>
                          <Header initiallyShown />
                          <HeaderSpacer />
                          <ContentPadder $noBottomPadding={Component.noBottomPadding || false}>
                            <Component {...pageProps} env={env} />
                          </ContentPadder>
                          <Footer />
                        </>
                      )}
                      <CartAndConfiguratorMobileModal />
                      <StyledToastContainer
                        hideProgressBar
                        limit={3}
                        autoClose={3000}
                        position="bottom-right"
                      />
                    </>
                  </Root>
                </UserContextProvider>
              </IntlProvider>
            </ThemeProvider>
          </ArticleConfigurationContextProvider>
        </CartContextProvider>
      </RestaurantContextProvider>
    </>
  );
}

import CookiesServerSide from 'cookies';
App.getInitialProps = async ({ router: { query }, ctx: { req, res, asPath }, ...rest }) => {
  if (process.browser) {
    return __NEXT_DATA__.props; // .pageProps;
  }

  let url;
  if (req) {
    // Server side rendering
    let protocol = 'https';
    let host = req.headers.host;
    if (host.indexOf('localhost') > -1) {
      protocol = 'http';
    }
    url = protocol + '://' + host;
  } else {
    // Client side rendering
    url =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '');
  }

  let apiHost = process.env.API_HOST_STAGING;
  let defaultRestaurantId = process.env.DEFAULT_RESTAURANT_ID;
  if (process.env.APP_ENV === 'production') {
    apiHost = process.env.API_HOST_PROD;
    defaultRestaurantId = process.env.DEFAULT_RESTAURANT_ID_PROD;
  }

  // if there is no restaurantId given, we use the default ones
  const restaurantId = query.restaurantId || defaultRestaurantId;
  let rwg_token = query.rwg_token;

  const cookies = new CookiesServerSide(req, res);
  if (rwg_token) {
    cookies.set(COOKIE_RWG_TOKEN, rwg_token, { maxAge: 2592000000, httpOnly: false });
  } else {
    try {
      const rwg_token_from_cookie = cookies.get(COOKIE_RWG_TOKEN);
      rwg_token = rwg_token_from_cookie;
    } catch (e) {
      rwg_token = undefined;
    }
  }

  // pre-read the cookie consent to already pass a cookie value to prevent unnecessary frontend cookie display
  let cookieConsent;
  try {
    const valueRaw = decodeURIComponent(cookies.get(COOKIE_CONSENT_NAME));
    if (valueRaw) {
      cookieConsent = JSON.parse(valueRaw);
    }
  } catch (e) {
    cookieConsent = undefined;
  }

  // now we want to fetch the restaurants basic data
  try {
    const res = await fetch(`${apiHost}/restaurants/v1/storePageInfo?restaurantId=${restaurantId}`);
    // check if blocked
    if (res.status === 209) {
      return {
        errorCode: 209,
      };
    }

    const data = await res.json();

    return {
      restaurantData: { restaurantId, ...data },
      onlyPickUp: !data.doesDelivery && data.doesPickUp,
      url,
      env: {
        APP_ENV: process.env.APP_ENV,
      },
      pageProps: {
        cookieConsent,
        rwg_token,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      errorCode: 500,
    };
  }
};

export default App;
