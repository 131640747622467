import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg style={style} {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.42 60">
      <path d="M47.11 32.29l-19.4 19.4-19.4-19.4H0L27.71 60l27.71-27.71h-8.31zM47.11 27.71l-19.4-19.4-19.4 19.4H0L27.71 0l27.71 27.71h-8.31z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
