import React from 'react';
import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 491.508 491.508"
      style={style}
      {...rest}
    >
      <path
        fill="#31978c"
        d="m491.4986054 185.535003-364.529474 213.4377094-108.6410159-185.547589L382.8575895-.012586z"
      />
      <path
        fill="#44c4a1"
        d="M354.827 40.557 67.473 208.811c6.25 10.674 2.413 24.539-8.567 30.968l64.954 110.933c10.981-6.429 24.95-2.99 31.199 7.684l287.355-168.254c-6.25-10.674-2.415-24.538 8.568-30.968L386.026 48.241c-10.982 6.431-24.949 2.99-31.199-7.684z"
      />
      <g fill="#31978c">
        <ellipse
          cx="254.939"
          cy="199.481"
          rx="73.339"
          ry="57.938"
          transform="matrix(-.5053 -.863 .863 -.5053 211.6118 520.2759)"
        />
        <circle cx="396.545" cy="116.56" r="13.107" />
      </g>
      <circle cx="113.32" cy="282.426" r="13.107" fill="#3ea69b" />
      <path
        fill="#44c4a1"
        d="m282.084 252.029-5.256-8.977c-11.909 5.434-24.613 6.873-38.098 4.314l2.713-15.954c10.389 1.953 19.56 1.367 27.539-1.76l-13.969-23.857c-10.562 3.214-19.052 4.275-25.467 3.187-6.415-1.088-11.699-5.177-15.85-12.267s-5.075-14.337-2.754-21.756c2.324-7.415 7.34-13.651 15.056-18.719l-3.601-6.149 7.866-4.606 3.671 6.27c9.403-4.619 19.728-6.539 30.981-5.75L263.543 162c-7.67-.683-14.916.368-21.761 3.16l13.535 23.117.37-.217c10.725-3.303 19.402-4.366 26.031-3.188 6.642 1.177 12.059 5.356 16.257 12.526 4.201 7.175 5.081 14.508 2.634 21.988-2.441 7.49-7.717 13.878-15.843 19.187l5.183 8.851-7.865 4.605zm-55.151-74.293c-1.013 3.066-.709 5.972.899 8.719 1.608 2.747 3.701 4.438 6.276 5.073 2.569.646 6.59.354 12.048-.859l-12.457-21.275c-3.499 2.492-5.755 5.271-6.766 8.342zm57.46 38.761c1.19-3.223.949-6.274-.732-9.146-1.679-2.867-3.912-4.616-6.698-5.243-2.796-.621-7.081-.228-12.859 1.173l12.958 22.131c3.696-2.708 6.134-5.681 7.331-8.915z"
      />
      <path
        fill="#f6c358"
        d="m344.6358839 271.5252568-70.784986 41.4457166-108.6410159-185.547589 70.784986-41.4457166z"
      />
      <g fill="#fff">
        <path d="m284.51156 306.7556139-5.412736 3.1692416-108.6410159-185.547589 5.412736-3.1692416zM339.3948748 274.5807977l-5.412736 3.1692416-108.6410159-185.547589 5.412736-3.1692416z" />
      </g>
      <path
        fill="#3ea69b"
        d="m484.0865748 338.4768008-414.794836 79.7487424-40.5925664-211.132946 414.794836-79.7487424z"
      />
      <path
        fill="#44c4a1"
        d="m78.578 385.148-28.985-150.8c8.331-1.601 13.786-9.652 12.185-17.983l354.49-68.135c1.601 8.331 9.653 13.786 17.983 12.185l28.985 150.8c-8.331 1.601-13.786 9.652-12.185 17.983l-354.49 68.135c-1.601-8.331-9.652-13.787-17.983-12.185z"
      />
      <g fill="#3ea69b">
        <ellipse
          cx="256.413"
          cy="272.781"
          rx="73.339"
          ry="57.938"
          transform="rotate(-100.883 256.40079124 272.78560145)"
        />
        <circle cx="417.541" cy="241.809" r="13.107" />
        <circle cx="95.266" cy="303.757" r="13.107" />
      </g>
      <path
        fill="#44c4a1"
        d="m264.488 331.374-1.963-10.215c-13.039 1.154-25.496-1.724-37.357-8.632l7.875-14.137c9.144 5.304 17.986 7.809 26.551 7.52l-5.218-27.149c-11.029-.49-19.387-2.32-25.073-5.484s-9.304-8.78-10.855-16.849c-1.551-8.068-.006-15.208 4.655-21.43 4.662-6.216 11.47-10.424 20.434-12.631l-1.345-6.998 8.951-1.72 1.371 7.135c10.405-1.221 20.78.411 31.126 4.905l-6.625 14.623c-7.004-3.2-14.186-4.624-21.569-4.274l5.056 26.306.421-.081c11.212.461 19.747 2.351 25.605 5.671 5.87 3.324 9.584 9.069 11.152 17.229 1.569 8.165-.045 15.372-4.845 21.608-4.798 6.248-11.902 10.512-21.333 12.809l1.936 10.073-8.95 1.721zm-27.234-88.426c-1.977 2.553-2.659 5.394-2.059 8.52.601 3.126 2.01 5.418 4.226 6.875 2.206 1.465 6.096 2.53 11.646 3.206l-4.654-24.211c-4.129 1.183-7.183 3.051-9.159 5.61zm41.254 55.696c2.196-2.642 2.986-5.599 2.358-8.867-.627-3.263-2.15-5.656-4.568-7.176-2.429-1.517-6.6-2.575-12.514-3.18l4.841 25.185c4.387-1.322 7.677-3.312 9.883-5.962z"
      />
      <path
        fill="#f6c358"
        d="M171.553 455.444c0 19.917-38.402 36.064-85.774 36.064S.005 475.361.005 455.444v-29.501h171.548v29.501z"
      />
      <ellipse cx="85.781" cy="425.92" fill="#fcd462" rx="85.775" ry="36.045" />
      <g fill="#dc8744">
        <path d="M85.779 396.924c-41.343 0-74.859 12.992-74.859 29.019 0 16.027 33.516 29.019 74.859 29.019s74.859-12.992 74.859-29.019c0-16.027-33.516-29.019-74.859-29.019zm0 54.813c-36.749 0-66.541-11.549-66.541-25.795 0-14.246 29.792-25.795 66.541-25.795s66.541 11.549 66.541 25.795c0 14.247-29.792 25.795-66.541 25.795z" />
        <path d="M83.038 445.989v-3.531c-11.402-.451-21.637-2.208-30.693-5.27l9.107-4.207c6.985 2.354 14.174 3.755 21.586 4.207v-9.383c-9.402-.87-16.286-2.015-20.652-3.434s-6.549-3.523-6.549-6.311c0-2.789 2.508-5.069 7.544-6.845 5.036-1.773 11.585-2.739 19.657-2.901v-2.419h7.98v2.466c9.148.26 17.799 1.468 25.952 3.629l-8.112 4.45c-5.493-1.515-11.433-2.45-17.839-2.806v9.092h.376c9.564.872 16.59 2.049 21.078 3.53 4.498 1.484 6.742 3.637 6.742 6.457 0 2.822-2.579 5.121-7.737 6.892-5.158 1.775-11.971 2.741-20.459 2.903v3.481h-7.981zm-8.803-31.217c-2.122.724-3.178 1.628-3.178 2.708 0 1.08.833 1.935 2.498 2.562 1.655.63 4.823 1.234 9.483 1.814v-8.368c-3.747.13-6.681.558-8.803 1.284zm26.266 21.206c2.325-.74 3.493-1.675 3.493-2.804 0-1.128-.914-2.023-2.741-2.684-1.838-.661-5.249-1.281-10.234-1.862v8.704c3.989-.159 7.147-.612 9.482-1.354z" />
      </g>
      <path
        fill="#f6c358"
        d="M171.553 419.615c0 19.917-38.402 36.064-85.774 36.064S.005 439.533.005 419.615v-29.501h171.548v29.501z"
      />
      <ellipse cx="85.781" cy="390.132" fill="#fcd462" rx="85.775" ry="36.045" />
      <g fill="#dc8744">
        <path d="M85.779 361.095c-41.343 0-74.859 12.992-74.859 29.019 0 16.027 33.516 29.019 74.859 29.019s74.859-12.992 74.859-29.019c0-16.027-33.516-29.019-74.859-29.019zm0 54.813c-36.749 0-66.541-11.549-66.541-25.795 0-14.246 29.792-25.795 66.541-25.795s66.541 11.549 66.541 25.795c0 14.247-29.792 25.795-66.541 25.795z" />
        <path d="M83.038 410.16v-3.531c-11.402-.451-21.637-2.208-30.693-5.27l9.107-4.207c6.985 2.354 14.174 3.755 21.586 4.207v-9.383c-9.402-.87-16.286-2.015-20.652-3.434s-6.549-3.523-6.549-6.311c0-2.789 2.508-5.069 7.544-6.845 5.036-1.773 11.585-2.739 19.657-2.901v-2.419h7.98v2.466c9.148.26 17.799 1.468 25.952 3.629l-8.112 4.45c-5.493-1.515-11.433-2.45-17.839-2.806v9.092h.376c9.564.872 16.59 2.049 21.078 3.531 4.498 1.484 6.742 3.637 6.742 6.457 0 2.822-2.579 5.121-7.737 6.892-5.158 1.775-11.971 2.741-20.459 2.903v3.481h-7.981zm-8.803-31.217c-2.122.724-3.178 1.628-3.178 2.708 0 1.08.833 1.935 2.498 2.562 1.655.63 4.823 1.234 9.483 1.814v-8.368c-3.747.131-6.681.558-8.803 1.284zm26.266 21.206c2.325-.74 3.493-1.675 3.493-2.804 0-1.128-.914-2.023-2.741-2.684-1.838-.661-5.249-1.281-10.234-1.862v8.704c3.989-.159 7.147-.612 9.482-1.354z" />
      </g>
      <path
        fill="#f6c358"
        d="M171.553 383.786c0 19.917-38.402 36.064-85.774 36.064S.005 403.704.005 383.786v-29.501h171.548v29.501z"
      />
      <ellipse cx="85.781" cy="354.292" fill="#fcd462" rx="85.775" ry="36.045" />
      <g fill="#dc8744">
        <path d="M85.779 325.266c-41.343 0-74.859 12.992-74.859 29.019 0 16.027 33.516 29.019 74.859 29.019s74.859-12.992 74.859-29.019c0-16.027-33.516-29.019-74.859-29.019zm0 54.814c-36.749 0-66.541-11.549-66.541-25.795 0-14.246 29.792-25.795 66.541-25.795s66.541 11.549 66.541 25.795c0 14.246-29.792 25.795-66.541 25.795z" />
        <path d="M83.038 374.332v-3.531c-11.402-.451-21.637-2.208-30.693-5.27l9.107-4.207c6.985 2.354 14.174 3.755 21.586 4.207v-9.383c-9.402-.87-16.286-2.015-20.652-3.434s-6.549-3.523-6.549-6.311 2.508-5.069 7.544-6.845c5.036-1.773 11.585-2.739 19.657-2.901v-2.419h7.98v2.466c9.148.26 17.799 1.468 25.952 3.629l-8.112 4.45c-5.493-1.515-11.433-2.45-17.839-2.806v9.092h.376c9.564.872 16.59 2.049 21.078 3.531 4.498 1.484 6.742 3.637 6.742 6.457 0 2.822-2.579 5.121-7.737 6.892-5.158 1.775-11.971 2.741-20.459 2.903v3.481h-7.981zm-8.803-31.218c-2.122.724-3.178 1.628-3.178 2.708 0 1.08.833 1.934 2.498 2.562 1.655.63 4.823 1.234 9.483 1.814v-8.368c-3.747.131-6.681.558-8.803 1.284zm26.266 21.207c2.325-.74 3.493-1.675 3.493-2.804 0-1.128-.914-2.023-2.741-2.684-1.838-.661-5.249-1.281-10.234-1.862v8.704c3.989-.16 7.147-.613 9.482-1.354z" />
      </g>
      <path
        fill="#f6c358"
        d="M171.553 347.957c0 19.917-38.402 36.064-85.774 36.064S.005 367.875.005 347.957v-29.501h171.548v29.501z"
      />
      <ellipse cx="85.781" cy="318.452" fill="#fcd462" rx="85.775" ry="36.045" />
      <g fill="#dc8744">
        <path d="M85.779 289.437c-41.343 0-74.859 12.992-74.859 29.019 0 16.027 33.516 29.019 74.859 29.019s74.859-12.992 74.859-29.019c0-16.027-33.516-29.019-74.859-29.019zm0 54.814c-36.749 0-66.541-11.549-66.541-25.795 0-14.246 29.792-25.795 66.541-25.795s66.541 11.549 66.541 25.795c0 14.246-29.792 25.795-66.541 25.795z" />
        <path d="M83.038 338.503v-3.531c-11.402-.451-21.637-2.208-30.693-5.27l9.107-4.208c6.985 2.354 14.174 3.755 21.586 4.208v-9.383c-9.402-.87-16.286-2.015-20.652-3.434s-6.549-3.523-6.549-6.311 2.508-5.069 7.544-6.845c5.036-1.773 11.585-2.739 19.657-2.901v-2.419h7.98v2.466c9.148.26 17.799 1.468 25.952 3.629l-8.112 4.45c-5.493-1.515-11.433-2.45-17.839-2.806v9.092h.376c9.564.872 16.59 2.049 21.078 3.531 4.498 1.484 6.742 3.637 6.742 6.457 0 2.822-2.579 5.121-7.737 6.892-5.158 1.775-11.971 2.741-20.459 2.903v3.481h-7.981zm-8.803-31.218c-2.122.724-3.178 1.628-3.178 2.708 0 1.08.833 1.934 2.498 2.562 1.655.63 4.823 1.234 9.483 1.814v-8.368c-3.747.131-6.681.558-8.803 1.284zm26.266 21.207c2.325-.74 3.493-1.675 3.493-2.804 0-1.128-.914-2.023-2.741-2.684-1.838-.661-5.249-1.281-10.234-1.862v8.704c3.989-.16 7.147-.612 9.482-1.354z" />
      </g>
      <path
        fill="#f6c358"
        d="M352.436 455.444c0 19.917-38.402 36.064-85.774 36.064s-85.774-16.146-85.774-36.064v-29.501h171.548v29.501z"
      />
      <ellipse cx="266.655" cy="425.92" fill="#fcd462" rx="85.775" ry="36.045" />
      <g fill="#dc8744">
        <path d="M266.662 396.924c-41.343 0-74.859 12.992-74.859 29.019 0 16.027 33.516 29.019 74.859 29.019s74.859-12.992 74.859-29.019c0-16.027-33.515-29.019-74.859-29.019zm0 54.813c-36.749 0-66.541-11.549-66.541-25.795 0-14.246 29.792-25.795 66.541-25.795s66.541 11.549 66.541 25.795c0 14.247-29.792 25.795-66.541 25.795z" />
        <path d="M263.921 445.989v-3.531c-11.402-.451-21.637-2.208-30.693-5.27l9.107-4.207c6.985 2.354 14.174 3.755 21.586 4.207v-9.383c-9.402-.87-16.286-2.015-20.652-3.434s-6.549-3.523-6.549-6.311c0-2.789 2.508-5.069 7.544-6.845 5.036-1.773 11.585-2.739 19.657-2.901v-2.419h7.98v2.466c9.148.26 17.799 1.468 25.952 3.629l-8.112 4.45c-5.493-1.515-11.433-2.45-17.839-2.806v9.092h.376c9.564.872 16.59 2.049 21.078 3.53 4.498 1.484 6.742 3.637 6.742 6.457 0 2.822-2.579 5.121-7.737 6.892-5.158 1.775-11.971 2.741-20.459 2.903v3.481h-7.981zm-8.803-31.217c-2.122.724-3.178 1.628-3.178 2.708 0 1.08.833 1.935 2.498 2.562 1.655.63 4.823 1.234 9.483 1.814v-8.368c-3.747.13-6.681.558-8.803 1.284zm26.267 21.206c2.325-.74 3.493-1.675 3.493-2.804 0-1.128-.914-2.023-2.741-2.684-1.838-.661-5.249-1.281-10.234-1.862v8.704c3.989-.159 7.146-.612 9.482-1.354z" />
      </g>
      <path
        fill="#f6c358"
        d="M352.436 419.615c0 19.917-38.402 36.064-85.774 36.064s-85.774-16.146-85.774-36.064v-29.501h171.548v29.501z"
      />
      <ellipse cx="266.655" cy="390.132" fill="#fcd462" rx="85.775" ry="36.045" />
      <g fill="#dc8744">
        <path d="M266.662 361.095c-41.343 0-74.859 12.992-74.859 29.019 0 16.027 33.516 29.019 74.859 29.019s74.859-12.992 74.859-29.019c0-16.027-33.515-29.019-74.859-29.019zm0 54.813c-36.749 0-66.541-11.549-66.541-25.795 0-14.246 29.792-25.795 66.541-25.795s66.541 11.549 66.541 25.795c0 14.247-29.792 25.795-66.541 25.795z" />
        <path d="M263.921 410.16v-3.531c-11.402-.451-21.637-2.208-30.693-5.27l9.107-4.207c6.985 2.354 14.174 3.755 21.586 4.207v-9.383c-9.402-.87-16.286-2.015-20.652-3.434s-6.549-3.523-6.549-6.311c0-2.789 2.508-5.069 7.544-6.845 5.036-1.773 11.585-2.739 19.657-2.901v-2.419h7.98v2.466c9.148.26 17.799 1.468 25.952 3.629l-8.112 4.45c-5.493-1.515-11.433-2.45-17.839-2.806v9.092h.376c9.564.872 16.59 2.049 21.078 3.531 4.498 1.484 6.742 3.637 6.742 6.457 0 2.822-2.579 5.121-7.737 6.892-5.158 1.775-11.971 2.741-20.459 2.903v3.481h-7.981zm-8.803-31.217c-2.122.724-3.178 1.628-3.178 2.708 0 1.08.833 1.935 2.498 2.562 1.655.63 4.823 1.234 9.483 1.814v-8.368c-3.747.131-6.681.558-8.803 1.284zm26.267 21.206c2.325-.74 3.493-1.675 3.493-2.804 0-1.128-.914-2.023-2.741-2.684-1.838-.661-5.249-1.281-10.234-1.862v8.704c3.989-.159 7.146-.612 9.482-1.354z" />
      </g>
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
