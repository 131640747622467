import styled, { withTheme, css } from 'styled-components';
import PropTypes from 'prop-types';
import { useContext, useState, useEffect, useRef, memo } from 'react';
import useSWR from 'swr';
import isEmpty from 'lodash/isEmpty';
import UserContext from '../../Context/UserContext';
import RestaurantContext from '../../Context/RestaurantContext';
import Icon from '../Icon';
import PagePointIndicator from '../UtilComponents/PagePointIndicator';
import RegionSelectorModal from '../RegionSelectorModal';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  position: relative;

  margin-bottom: 10px;
  margin-left: -15px;
  margin-right: -15px;
`;

const ScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-x: scroll;

  padding: 8px 15px;

  display: inline-flex;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scroll-padding-left: 15px;

  // hide scrollbars
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
`;

const CheckIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 300ms ease-in;
`;
const IconWrapper = styled.div``;
const ArrowWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  padding: 10px;

  bottom: 0;
  top: 0;

  &.left {
    left: 0;
    margin-left: 15px;
    padding-left: 10px;
    ${IconWrapper} {
      transform: rotate(90deg);
    }
  }
  &.right {
    right: 0;
    margin-right: 15px;
    padding-right: 10px;
    ${IconWrapper} {
      transform: rotate(-90deg);
    }
  }

  &:hover {
    ${IconWrapper} {
      filter: drop-shadow(RGBA(120, 130, 140, 0.13) 0px 2px 4px);
    }
  }
`;

const AddressBox = styled.div`
  position: relative;
  padding: 10px;
  width: 100%;
  min-width: 100%;
  border-radius: 4px;
  border: 1px solid RGBA(47, 159, 47, 0.1);
  box-shadow: 0 2px 9px 0 RGBA(120, 130, 140, 0.13);
  background: #ffffff;
  text-align: center;
  line-height: initial;
  word-break: break-word;

  display: flex;
  flex-flow: column;
  justify-content: center;
  flex-basis: 100%;
  flex-shrink: 0;

  cursor: pointer;
  user-select: none;
  scroll-snap-align: start;

  margin-right: 15px;

  && p {
    margin-bottom: 10px;
  }

  & p.details {
    font-size: 12px;
    margin: 0;
  }

  ${(props) =>
    props.selected
      ? css`
          opacity: 1;
          & ${CheckIconWrapper} {
            background: ${(props) => props.theme.colors.primary};
          }
        `
      : css`
          opacity: 0.5;
        `}
  transition: opacity 100ms ease-in;
`;

const CurrentIndexIndicator = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

const EnterAddressSelector = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.61px;

  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;

  & > .icon {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    ${(props) => {
      if (props.$active) {
        return css`
          fill: ${(props) => props.theme.colors.primary}!important;
          transform: rotate(45deg);
        `;
      } else {
        return css`
          fill: RGBA(133, 133, 133, 0.15) !important;
        `;
      }
    }}

    transition: transform 100ms ease-in-out;
  }
`;

const fetcher = (...args) => fetch(...args).then((res) => res.json());

const UserAddressSelectorComponent = ({ onAddressSelected, selectedAddressId, ...props }) => {
  const { userData } = useContext(UserContext);
  const {
    setShowCartModal,
    restaurantProps: storeProps,
    setSelectedDeliveryRegion,
    selectedDeliveryRegion,
  } = useContext(RestaurantContext);

  const scrollerRef = useRef();
  const addressRefs = useRef([]);

  const [currentAddressIndex, setCurrentAddressIndex] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState();
  const [addressToSelect, setAddressToSelect] = useState();
  const [initialized, setInitialized] = useState(false);
  const [ignoreObserver, setIgnoreObserver] = useState(true);
  const [isPickUp, setIsPickUp] = useState(selectedDeliveryRegion?.isPickUp);

  const onAddressBoxClick = (address) => {
    if (!initialized) {
      setIgnoreObserver(false);
    }

    setSelectedAddress(address);
    setAddressToSelect(address);
    setInitialized(true);
    onAddressSelected(address);
  };

  const { data, error } = useSWR(
    `/api/profile/v1/getAddressesOfUser?userId=${userData.id}&restaurantId=${storeProps.restaurantId}`,
    fetcher,
  );

  const [addressList, setAddressList] = useState([]);

  useEffect(() => {
    if (isPickUp !== selectedDeliveryRegion?.isPickUp) {
      setIsPickUp(selectedDeliveryRegion?.isPickUp);
    }
  }, [isPickUp, selectedDeliveryRegion?.isPickUp]);

  useEffect(() => {
    const zipIncludedInDeliveryRegions = (zipToTest) => {
      let isIncluded = false;
      storeProps.deliveryRegions.forEach((region) => {
        if (region.zipList.includes(zipToTest)) {
          isIncluded = true;
          return;
        }
      });
      return isIncluded;
    };

    if (data && data.length > 0) {
      if (!selectedDeliveryRegion?.isPickUp) {
        const possibleDeliveryAddresses = data.filter((address) => {
          return zipIncludedInDeliveryRegions(address.zip);
        });
        setAddressList(possibleDeliveryAddresses);
      } else {
        setAddressList(data);
      }
    }
  }, [data, selectedDeliveryRegion?.isPickUp, storeProps.deliveryRegions]);

  useEffect(() => {
    const index = addressList.findIndex((el) => el._id === selectedAddressId);
    if (index > -1 && scrollerRef && addressList?.length > 0) {
      if (currentAddressIndex !== index) {
        setCurrentAddressIndex(index);

        scrollerRef.current.scrollTo({
          top: 0,
          left: addressRefs.current[index].offsetLeft,
          behavior: 'instant',
        });
        onAddressBoxClick(addressList[index]);
      }

      setInitialized(true);
    } else {
      if (scrollerRef && addressList?.length > 0) {
        onAddressSelected(null);
        setSelectedAddress(null);
        setIgnoreObserver(true);
        if (currentAddressIndex >= addressList.length) {
          setCurrentAddressIndex(0);
          scrollerRef.current.scrollTo({
            top: 0,
            left: addressRefs.current[0].offsetLeft,
            behavior: 'instant',
          });
        }
      }
    }
  }, [selectedAddressId, addressList, scrollerRef]);

  useEffect(() => {
    const options = {
      root: scrollerRef.current,
      rootMargin: '0px',
      threshold: 1,
    };
    const observerCallback = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        const nowShownIndex = entry.target.dataset.index;
        setCurrentAddressIndex(parseInt(nowShownIndex, 10));
        if (!ignoreObserver) {
          onAddressBoxClick(addressList[nowShownIndex]);
        } else if (initialized) {
          setIgnoreObserver(false);
        }
      }
    };

    if (!isEmpty(addressList)) {
      const observer = new IntersectionObserver(observerCallback, options);
      addressRefs.current.forEach((_, index) => {
        if (addressRefs.current[index]) {
          observer.observe(addressRefs.current[index]);
        }
      });

      return () => {
        observer.disconnect();
      };
    } else if (selectedAddress) {
      setAddressToSelect(null);
    }
  }, [addressList, error, initialized, ignoreObserver]);

  /** fns for DeliverRegion selection on zip change */
  const [selectionModalState, setSelectionModalState] = useState(() => ({
    show: false,
    regionList: [],
  }));
  const hideRegionModal = () => {
    setShowCartModal(false);
    setAddressToSelect(null);
    setSelectionModalState((state) => ({ ...state, show: false }));
  };
  const hideRegionModalOnSelect = () => {
    setSelectionModalState((state) => ({ ...state, show: false }));
  };
  const onRegionSelected = (region, fromZip, fromModal = false) => {
    // close modal if was shown
    if (fromModal) hideRegionModalOnSelect();

    // save region selection to context
    setSelectedDeliveryRegion(region, fromZip, selectedDeliveryRegion?.isPickUp);

    // success callback -> we can select the address
    setSelectedAddress(addressToSelect);

    // show toast as success!!
    toast.success(`Liefergebiet geändert - Bitte prüfen Sie Ihren Warenkorb.`, {
      position: 'bottom-right',
      hideProgressBar: true,
    });
  };
  const onZipRegionChange = (zip) => {
    // we do not need to validate the zip when we only do pickUp
    if (storeProps.onlyPickUp || selectedDeliveryRegion?.isPickUp) {
      setSelectedAddress(addressToSelect);
      return;
    }

    const matchingRegionsList = [];
    // get all deliveryregions containing this zip
    storeProps.deliveryRegionsRaw?.forEach((deliveryRegion) => {
      if (deliveryRegion.zipCodes?.includes(zip)) {
        matchingRegionsList.push(deliveryRegion);
      }
    });

    if (matchingRegionsList.length > 1) {
      // show selectionModal with fetched data amk
      setSelectionModalState((state) => ({
        ...state,
        show: true,
        regionList: matchingRegionsList,
        forZip: zip,
      }));
    } else if (matchingRegionsList.length === 1) {
      // store selection in context and send to menu page
      onRegionSelected(matchingRegionsList[0], zip);
    } else {
      // show error to user
      toast.error(`Die PLZ ${zip} liegt leider außerhalb unseres Liefergebietes.`, {
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    // try to select different zip as initially given... check if we need to change the region
    if (addressToSelect && selectedDeliveryRegion?.fromZip !== addressToSelect?.zip) {
      onZipRegionChange(addressToSelect?.zip);
    } else if (selectedAddress !== null) {
      setSelectedAddress(addressToSelect);
    } else if (addressToSelect === null) {
      setSelectedAddress(null);
    } else {
      setSelectedAddress(addressToSelect);
    }
  }, [addressToSelect]);

  const changeCurrentAddressBy = (positionsToMove) => {
    if (
      (positionsToMove < 0 && currentAddressIndex > 0) ||
      (positionsToMove > 0 && currentAddressIndex < addressList.length - 1)
    ) {
      addressIndexChangeByControl(currentAddressIndex + positionsToMove);
    } else if (positionsToMove < 0 && currentAddressIndex === 0) {
      addressIndexChangeByControl(addressList.length - 1);
    } else if (positionsToMove > 0 && currentAddressIndex === addressList.length - 1) {
      addressIndexChangeByControl(0);
    }
  };

  const addressIndexChangeByControl = (newIndex) => {
    if (initialized) {
      setCurrentAddressIndex(newIndex);
      onAddressBoxClick(addressList[newIndex]);
    } else {
      setCurrentAddressIndex(newIndex);
    }

    scrollerRef.current.scrollTo({
      top: 0,
      left: addressRefs.current[newIndex].offsetLeft,
      behavior: 'smooth',
    });
  };

  const enterAddressClicked = () => {
    if (selectedAddress) {
      setAddressToSelect(null);
      onAddressSelected(null);
      setInitialized(false);
      setIgnoreObserver(true);
    } else {
      setAddressToSelect(addressList[currentAddressIndex]);
      onAddressSelected(addressList[currentAddressIndex]);
      setInitialized(true);
      setIgnoreObserver(false);
    }
  };

  if (isEmpty(addressList)) {
    return null;
  }

  return (
    <div style={{ position: 'relative' }}>
      <Wrapper>
        <ArrowWrapper className="left" onClick={() => changeCurrentAddressBy(-1)}>
          <IconWrapper>
            <Icon style={{ fill: props.theme.colors.primary, width: '14px' }} name="triangle" />
          </IconWrapper>
        </ArrowWrapper>
        <ArrowWrapper className="right" onClick={() => changeCurrentAddressBy(1)}>
          <IconWrapper>
            <Icon style={{ fill: props.theme.colors.primary, width: '14px' }} name="triangle" />
          </IconWrapper>
        </ArrowWrapper>
        <ScrollWrapper ref={scrollerRef}>
          {addressList.map((address, index) => (
            <AddressBox
              key={`${address._id}-${index}`}
              ref={(r) => {
                addressRefs.current[index] = r;
              }}
              data-index={index}
              selected={address._id === selectedAddress?._id}
              onClick={() => onAddressBoxClick(address)}
            >
              <CheckIconWrapper>
                <Icon style={{ fill: '#ffffff', width: 12, height: 12 }} name="check-single" />
              </CheckIconWrapper>
              <p>
                <strong>{address.name}</strong>
                <br />
                {address.street} {address.housenumber}
                <br />
                {address.zip} {address.city}
              </p>
              <p className="details">
                <strong>Tel.: {address.telephone}</strong>
                <br />
                {userData.email}
              </p>
            </AddressBox>
          ))}
        </ScrollWrapper>
      </Wrapper>
      <CurrentIndexIndicator>
        <PagePointIndicator numberOfItems={addressList.length} activeIndex={currentAddressIndex} />
      </CurrentIndexIndicator>
      <EnterAddressSelector onClick={enterAddressClicked} $active={isEmpty(selectedAddress)}>
        <span>Adresse eingeben</span>
        <Icon name="add-round" className="icon" />
      </EnterAddressSelector>

      <RegionSelectorModal
        show={selectionModalState.show}
        onHide={hideRegionModal}
        onSelection={(selectedRegion) =>
          onRegionSelected(selectedRegion, selectionModalState.forZip, true)
        }
        forZip={selectionModalState.forZip}
        regionsList={selectionModalState.regionList}
        currentDeliveryTime={storeProps.currentDeliveryTime}
      />
    </div>
  );
};

UserAddressSelectorComponent.propTypes = {
  onAddressSelected: PropTypes.func,
  selectedAddressId: PropTypes.string,
};

export default withTheme(memo(UserAddressSelectorComponent));
