import React from 'react';
import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 60" style={style} {...rest}>
      <path d="M34.83 32.65 6.82 58.9A4 4 0 0 1 4 60a3.88 3.88 0 0 1-4-3.75 3.65 3.65 0 0 1 1.17-2.65l22.36-21a3.59 3.59 0 0 0 0-5.3L1.17 6.4A3.65 3.65 0 0 1 0 3.75 3.88 3.88 0 0 1 4 0a4 4 0 0 1 2.82 1.1l28 26.25a3.59 3.59 0 0 1 .01 5.3Z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
