import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 60 60"
      style={style}
      {...rest}
    >
      <path d="M29.84 56.76c-.75-.31-.91-.54-.91-1.39V27.74c-.13.12-.21.2-.3.28-2.66 2.66-5.33 5.32-7.99 7.98-.27.27-.56.45-.94.41-.43-.04-.75-.27-.9-.67-.15-.41-.08-.79.22-1.11.33-.35.67-.68 1.01-1.02 2.8-2.8 5.6-5.59 8.4-8.39.95-.94 2.17-.95 3.11-.01 3.11 3.11 6.22 6.21 9.33 9.32.34.34.48.73.32 1.19-.15.41-.46.64-.89.69-.37.04-.67-.12-.93-.38-.96-.97-1.93-1.93-2.9-2.9-1.71-1.71-3.42-3.42-5.13-5.12-.08-.08-.16-.15-.29-.27v27.63c0 .84-.16 1.08-.91 1.39h-.3z" />
      <path d="M21.9 11.15c.7-.9 1.31-1.82 2.05-2.62 2.7-2.95 6.05-4.71 10.02-5.17 8.28-.96 15.74 4.27 17.7 12.36.42 1.73.52 3.48.33 5.25-.03.25.04.35.26.45 3.87 1.74 6.35 4.67 7.37 8.78 1.71 6.92-2.75 14.05-9.72 15.55-.86.19-1.75.28-2.64.3-1.65.05-3.31.02-4.96.01-.56 0-.97-.37-1.06-.91-.07-.47.22-.97.7-1.13.18-.06.38-.07.57-.07 1.49-.01 2.98 0 4.47 0 1.81 0 3.53-.38 5.11-1.25 3.24-1.79 5.23-4.51 5.65-8.2.51-4.5-1.24-8.01-5.04-10.48-.5-.33-1.07-.55-1.61-.8-.9-.4-1.36-1.12-1.24-2.09.35-2.88-.1-5.62-1.46-8.17-2.18-4.07-5.54-6.65-10.11-7.38-5.59-.9-10.16 1.03-13.67 5.47-.36.46-.65.98-.95 1.49-.6 1.01-1.53 1.27-2.52.64-4.08-2.62-9.69-1.02-11.75 3.59-.67 1.49-.84 3.05-.56 4.66.2 1.14-.13 1.8-1.16 2.37-2.1 1.16-3.65 2.83-4.62 5.01-2.86 6.42 1.13 13.83 8.06 14.98.67.11 1.36.15 2.04.16 1.5.02 3 0 4.5.01.77 0 1.28.7 1.03 1.4-.15.41-.45.69-.89.69-2.21-.02-4.44.06-6.64-.14-2.9-.27-5.36-1.61-7.42-3.67C1.77 40.26.58 37.88.15 35.13c-.81-5.23 1.71-10.44 6.31-13.08.22-.13.3-.24.26-.52-.9-5.36 2.93-10.69 8.3-11.58 2.4-.4 4.67-.06 6.79 1.15.05.03.09.05.09.05z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
