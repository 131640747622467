import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416.88 416.84" style={style} {...rest}>
      <path d="M.04 200.76c.15-1.89.32-3.79.44-5.68a207.83 207.83 0 0 1 57.21-130.5 208.3 208.3 0 0 1 55.16-41.38 206 206 0 0 1 56.36-19.54 196.8 196.8 0 0 1 30.83-3.5c3.59-.11 7.18-.19 10.78-.15 5.92.06 9.94 3.94 10.31 9.91a39.23 39.23 0 0 0 3.82 14.74 39.2 39.2 0 0 0 18.78 18.78c5.42 2.61 7.74 8.06 5.53 13.31a14 14 0 0 1-1.37 2.37 39.88 39.88 0 0 0 26.84 61.94 45.54 45.54 0 0 0 9.55.35c6.44-.42 11.6 4.41 11.13 10.84-.88 12.15 2.76 22.72 11.15 31.54a38.45 38.45 0 0 0 22.92 11.73 39.71 39.71 0 0 0 28.67-6.83c5.5-3.72 12.16-1.79 15.13 4.15 6.92 13.92 18.15 21.59 33.66 22.92 5.88.48 9.86 4.45 9.93 10.22a208.8 208.8 0 0 1-161.66 205.56 199.49 199.49 0 0 1-38.31 5.11 3.61 3.61 0 0 0-.77.19h-15.35a6 6 0 0 0-1-.19 203.55 203.55 0 0 1-30.45-3.49A208.56 208.56 0 0 1 .44 221.76c-.12-1.89-.29-3.79-.44-5.68Zm31.29-56.08c-28 74.48-5.19 175.22 82.83 226.62 83.62 48.84 184.13 24.2 238.34-41.71a42.83 42.83 0 0 1-10.29-10.6 41.91 41.91 0 0 1-7-28.36 42 42 0 0 1 22.43-32.47c10.66-5.49 21.74-6.09 33.2-2.42a187 187 0 0 0 5.93-41c-.13-.11-.16-.15-.2-.16s-.38-.12-.57-.16a59.32 59.32 0 0 1-35-23.12c-.5-.67-.88-.67-1.57-.37a57.83 57.83 0 0 1-33.74 4.29c-20.37-3.55-35.19-14.74-44.48-33.19a54.82 54.82 0 0 1-5.56-19.34c-.1-1-.48-1.35-1.46-1.42a34.23 34.23 0 0 1-4-.55 60 60 0 0 1-46.4-77.23c.74-2.35 1.68-4.64 2.55-7a59.71 59.71 0 0 1-15.39-15.9 59.94 59.94 0 0 1-8.6-20.51C153.2 22.26 111.2 40.53 75.92 74.53a42.09 42.09 0 0 1-44.63 70.11Zm353.35 130.28a21.83 21.83 0 0 0-20 38.52 187.61 187.61 0 0 0 20-38.52ZM62.39 89.59a188.43 188.43 0 0 0-23.3 36.39c8.27 4.17 20.64 2.1 27.25-7.89a21.75 21.75 0 0 0-3.95-28.5Z" />
      <path d="M110.54 322.12a41.93 41.93 0 1 1 41.92-41.93 41.89 41.89 0 0 1-41.92 41.93Zm21.88-41.81a21.92 21.92 0 1 0-22 21.85 22 22 0 0 0 22-21.85Z" />
      <path d="M250.45 353h-6.95c-2.11-.31-4.24-.56-6.35-.94a54 54 0 0 1-43.25-43.15c-.36-2.06-.63-4.14-.94-6.2v-7.19a7.44 7.44 0 0 0 .2-.78c.42-2.68.65-5.4 1.27-8a53.82 53.82 0 0 1 69.83-38.71c26.23 8.89 41.1 35.56 35.31 62.7a54 54 0 0 1-42.9 41.35c-2.07.35-4.15.61-6.22.92Zm-37.41-54a33.89 33.89 0 0 0 33.6 33.89c18.74 0 34.11-15.09 34.21-33.45a33.91 33.91 0 1 0-67.81-.44Z" />
      <path d="M143.28 176.96v-2.64a4.65 4.65 0 0 0 .19-.9 40.35 40.35 0 0 1 12.29-27.5c10.32-10.1 22.78-14 37.05-11.57 23.3 3.93 38.82 27.5 33.38 50.58-5.1 21.64-25.36 35.3-47.53 32a42 42 0 0 1-34.69-34.59c-.27-1.78-.46-3.58-.69-5.38Zm42.11-23.26c-12 0-22 9.74-22 21.56a21.9 21.9 0 1 0 22-21.56Z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
