import { useContext, useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'next/link';
import CartContext from '../../Context/CartContext';
import RestaurantContext from '../../Context/RestaurantContext';
import LoadIndicatorComponent from '../Loader/LoadIndicatorComponent';
import styled, { withTheme } from 'styled-components';
import PickUpSwitchComponent from './PickUpSwitchComponent';
import LoadingErrorReloadComponent from '../Loader/LoadingErrorReloadComponent';
import HorizontalDivider from '../HorizontalDivider';
import Icon from '../Icon';
import CartArticleList from './CartArticleList';
import { Row, Col, Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import { Currency } from 'react-intl-number-format';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import DesiredDeliveryTimeSelectorComponent from './DesiredDeliveryTimeSelectorComponent';
import ExpandableBoxComponent from '../UtilComponents/ExpandableBoxComponent';
import AddressFormComponent from './AddressFormComponent';
import SelectableButton from '../UtilComponents/SelectableButton';
import request, { resolveErrorBody } from '../../util/request';
import LoaderTyping from '../Loader/LoaderTyping';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import CartCouponComponent from './CartCouponComponent';
import UserContext from '../../Context/UserContext';
import UserAddressSelectorComponent from './UserAddressSelectorComponent';
import ClosingTimeCounter from '../ClosingTimeCounter';
import FreeDeliveryIndicator from './FreeDeliveryIndicator';
import calculateCartTotalValue from '../../util/calculateCartTotalValue';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 60px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  & > h2 {
    font-size: 19px;
    text-align: center;
    letter-spacing: 0.79px;
    margin-bottom: 25px;
  }

  & p {
    font-weight: 300;
    margin-bottom: 5px;
  }
  padding-bottom: calc(env(safe-area-inset-bottom) / 2);
`;

const OrderWithStoreAddress = styled.div`
  & p {
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
    line-height: 15px;
  }

  span {
    font-weight: 300;
    font-size: 13px;
    letter-spacing: 0.58px;
  }
`;

const EmptyCartWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 60px;
`;
const CartIconWrapper = styled.div`
  position: relative;
  display: flex;
  height: 60px;
  justify-content: center;
`;
const NumberWrapper = styled.div`
  position: absolute;
  font-size: 22px;
  font-weight: 700;
`;

const PriceWrapper = styled.div`
  position: relative;

  letter-spacing: 0.66px;

  .price {
    text-align: right;
  }

  font-weight: 300;
  .strong {
    font-weight: 500;
  }

  .flex-push-bottom {
    display: flex;
    align-items: flex-end;
  }
`;

const TotalPriceWrapper = styled.div`
  display: inline-block;

  width: 100%;
  border-top: 1px solid #000000;
  margin-top: 4px;
  padding-top: 4px;
`;

const BelowMinimumOrderValue = styled.div`
  color: #ec971f;
  font-weight: 500;
  margin-top: 5px;

  font-weight: 500;

  font-size: 14px;
`;

const MinOrderValueElement = styled.div`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.53px;

  margin-top: 5px;
  border-left: 2px solid ${(props) => props.theme.colors.warning};
  padding-left: 10px;
`;

const CloseButtonWrapper = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background: white;
  box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.13);
  text-align: center;
  user-select: none;
  cursor: pointer;

  transition: box-shadow 0.1s ease-in;
  &:hover {
    box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.3);
  }

  & > svg {
    margin-top: 8px;
    vertical-align: bottom;
  }
`;

const OrderNotice = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0 0 0;

  span {
    font-size: 15px;
    letter-spacing: 0.97px;
    font-weight: 300;
  }

  strong {
    font-weight: 700;
  }
`;

const ContinueOrderProcessWrapper = styled.div`
  margin-top: 25px;
`;

const NoteInputWrapper = styled.div`
  textarea {
    font-size: 14px;
    resize: vertical;
    border: 1px solid rgba(133, 133, 133, 0.15);
  }
`;

const CouponCodeInput = styled(FormControl)`
  line-height: 100%;
  border: 1px solid rgba(133, 133, 133, 0.15);
  box-shadow: none;
  font-family: 'Courier New', 'Courier', Serif;
  text-transform: uppercase;

  &::placeholder {
    font-size: 14px;
    line-height: 16px;

    text-transform: none;
  }
`;

const AddonButton = styled(Button)`
  font-size: 14px;
  border: 1px solid rgba(133, 133, 133, 0.15) !important;
  background: ${(props) => props.theme.colors.primary} !important;
  color: white !important;
  width: 100px;
  outline: none !important;
  box-shadow: none !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;

  &.disabled,
  &:disabled {
    border: 1px solid rgba(133, 133, 133, 0.15) !important;
    background: rgba(235, 235, 235, 0.25) !important;
    color: #cecece !important;
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

const FormHeroElement = styled.div`
  h3 {
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 17px;
    letter-spacing: 0.75px;
    color: #000000;
  }

  span {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0.75px;
  }
`;

const AddressElementWrapper = styled.div`
  margin-top: 10px;
`;

const PaymentButtonWrapper = styled.div`
  display: ${(props) => (props.$isVisible ? 'block' : 'none')};
  position: ${(props) => (props.$isOnMobile ? 'fixed' : 'absolute')};
  bottom: 0;
  height: calc(60px + (env(safe-area-inset-bottom) / 2));
  left: 0;
  right: 0;
  background-color: white;
  z-index: 100;

  padding-top: 8px;
  padding-bottom: 8px;
  padding-bottom: calc(8px + (env(safe-area-inset-bottom) / 2));
  padding-left: 15px;
  padding-right: 15px;

  box-shadow: 0 -2px 9px RGBA(120, 130, 140, 0.07);
`;

const PaymentButton = styled(Button)`
  height: 100%;
  width: 100%;

  font-size: 20px;
  letter-spacing: 0.95px;
  font-weight: 700;

  pointer-events: all !important;
`;

const PaymentTypesWrapper = styled.div`
  & button:not(:first-child) {
    margin-top: 10px;
  }
`;

const ShadowBox = styled.div`
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 3px 9px RGBA(120, 130, 140, 0.13);
  border-radius: 4px;
`;

const StoreAddressQuestion = styled.div`
  margin-top: 15px;
  font-size: 12px;
  letter-spacing: 0.51px;

  & .checkBox {
    margin: 0;
    min-height: auto;
    padding: 0;

    & input[type='checkbox'] {
      width: 20px;
      height: 20px;
      margin: 0px 10px 0 0;
    }

    & label {
      line-height: 20px;
      vertical-align: middle;
    }
  }
`;

const LoginOrRegisterButton = styled.button`
  position: relative;
  padding: 10px 0 0 0;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
  border: 0;
  width: 100%;
  padding: 22px 10px;
  cursor: pointer;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
`;

const TitleAndIconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  text-align: left;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 100%;
  margin-right: 15px;
  justify-content: center;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-flow: column;
`;
const Title = styled.h2`
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.66px;
`;
const SubTitle = styled.span`
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.66px;
`;

function CartComponent(props) {
  const { loadingCart, cart, fetchCart, setRedeemedCoupons, error, deliveryTimes } =
    useContext(CartContext);
  const {
    restaurantProps: storeProps,
    selectedDeliveryRegion,
    setShowCartModal,
    showCartModalForMobile,
    isClosedByLastMoment,
    getOverallStatus,
  } = useContext(RestaurantContext);

  const { userData, showLogin } = useContext(UserContext);
  const [hasUserData, setHasUserData] = useState(userData);

  useEffect(() => setHasUserData(!isEmpty(userData)), [userData]);

  const isCartAlwaysVisibleInScreen = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [minPriceReached, setMinPriceReached] = useState(false);
  const [deliveryNote, setDeliveryNote] = useState('');
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState(null);
  const [submittingOrder, setSubmittingOrder] = useState(false);

  const [paymentButtonLabel, setPaymentButtonLabel] = useState('');

  const [hasMinOrderValueExclusiveItems, setHasMinOrderValueExclusiveItems] = useState(false);
  const [priceWithoutNonMinOrderValueArticles, setPriceWithoutNonMinOrderValueArticles] =
    useState(0);

  /* Address handling */
  const [addressData, setAddressData] = useState({});
  const [addressIsValid, setAddressIsValid] = useState(false);
  const [userAddressData, setUserAddressData] = useState();
  const [userAddressIsValid, setUserAddressIsValid] = useState(false);

  const addressScrollRef = useRef();
  const paymentTypeRef = useRef();

  const router = useRouter();

  const isDeliveryForFree = useCallback(() => {
    const cartValue = calculateCartTotalValue(cart);
    const parsedFreeDeliveryFrom = parseFloat(storeProps.freeDeliveryFrom);

    if (Number.isNaN(parsedFreeDeliveryFrom) || cartValue < parsedFreeDeliveryFrom) {
      return false;
    }
    return true;
  }, [cart, storeProps?.freeDeliveryFrom]);

  useEffect(() => {
    function calcTotalCartPrice() {
      const cartPrice = cart?.meta?.totalPrice || 0;
      const deliveryPrice =
        !isDeliveryForFree() &&
        !selectedDeliveryRegion?.isPickUp &&
        selectedDeliveryRegion?.deliveryPrice
          ? selectedDeliveryRegion?.deliveryPrice
          : 0;

      const absoluteCoupons = cart.redeemedCoupons
        ?.filter((x) => x.couponType === 'absolute')
        .reduce((acc, obj) => acc + obj.valueAbsolute, 0);

      const relativeCoupons = cart.redeemedCoupons
        ?.filter((x) => x.couponType === 'relative')
        .reduce((acc, obj) => acc + obj.valueRelative, 0);

      return (cartPrice + deliveryPrice - absoluteCoupons) * (1 - relativeCoupons / 100);
    }

    function sumOfNonMinOrderRelevantArticles(cartItemList) {
      return cartItemList.reduce((acc, curr) => {
        let sum = acc;
        if (curr.fullArticle?.nonMinOrderValue) {
          sum += curr.totalPrice;
        }
        return sum;
      }, 0);
    }

    const newCartPrice = calcTotalCartPrice();
    if (newCartPrice !== totalCartPrice) {
      setTotalCartPrice(newCartPrice);
    }

    if (cart?.items?.length > 0) {
      const foundExcludedItem = cart.items.find((x) => x.fullArticle.nonMinOrderValue);
      if (foundExcludedItem) {
        setHasMinOrderValueExclusiveItems(true);
      } else {
        setHasMinOrderValueExclusiveItems(false);
      }

      const effectiveMinPrice = totalCartPrice - sumOfNonMinOrderRelevantArticles(cart.items);
      setPriceWithoutNonMinOrderValueArticles(effectiveMinPrice > 0 ? effectiveMinPrice : 0);
    } else setHasMinOrderValueExclusiveItems(false);
  }, [
    selectedDeliveryRegion?.isPickUp,
    cart?.meta?.totalPrice,
    cart?.redeemedCoupons,
    selectedDeliveryRegion?.deliveryPrice,
    totalCartPrice,
    cart?.items,
    storeProps?.freeDeliveryFrom,
    isDeliveryForFree,
  ]);

  /**
   * checks if the store is currently closed, but we can pre-order
   */
  const [isPreOrderable, setIsPreOrderable] = useState(false);
  useEffect(() => {
    const canPreorder =
      storeProps.statusData.status === 'CLOSED' &&
      storeProps.statusData.groupStatus === 'WILL_OPEN';
    if (canPreorder !== isPreOrderable) {
      setIsPreOrderable(canPreorder);
    }
  }, [storeProps.statusData]);

  const preOrderFrom = () =>
    storeProps.statusData.payload.firstMoment
      ? moment(storeProps.statusData.payload.firstMoment).format('LT [Uhr]')
      : '';

  /**
   * show order form when we are able to place an order / preorder:
   * * items > 0 in cart
   * * minOrderValueReached or PickUp
   * * shop is opened or will_open
   */
  const canShowOrderForm = () => {
    return (
      cart.meta.items > 0 &&
      (getOverallStatus(storeProps) === 'OPENED' || isPreOrderable) &&
      minPriceReached
    );
  };

  const [showOrderForm, setShowOrderForm] = useState(canShowOrderForm());
  useEffect(() => {
    setShowOrderForm(canShowOrderForm());
  }, [cart.meta.items, isPreOrderable, minPriceReached, storeProps.statusData]);

  const guestAddressValid = (data) => {
    if (!isEqual(data, addressData)) {
      setAddressData(data);
      setAddressIsValid(true);
    }
  };
  const guestAddressInvalid = () => {
    setAddressIsValid(false);
    setAddressData(null);
  };

  /* Payment Type Handling */
  const [selectedPaymentType, setSelectedPaymentType] = useState();

  useEffect(() => {
    let reached = false;
    if (selectedDeliveryRegion?.isPickUp) {
      reached = totalCartPrice > 0;
    } else {
      reached = priceWithoutNonMinOrderValueArticles >= selectedDeliveryRegion?.minOrderValue;
    }
    if (reached !== minPriceReached) {
      setMinPriceReached(reached);
    }
  }, [
    minPriceReached,
    selectedDeliveryRegion?.minOrderValue,
    totalCartPrice,
    selectedDeliveryRegion?.isPickUp,
    priceWithoutNonMinOrderValueArticles,
  ]);

  /**
   * check if prerequisities are fulfilled and we can proceed to order
   */
  const [canCheckout, setCanCheckout] = useState(false);

  const restaurantIsClosed = useCallback(() => {
    let restaurantStatus = getOverallStatus(storeProps);
    return (
      restaurantStatus === 'CLOSED' ||
      restaurantStatus === 'INACTIVE' ||
      restaurantStatus === 'OFFLINE' ||
      restaurantStatus === 'HOLIDAYS'
    );
  }, [getOverallStatus, storeProps]);

  useEffect(() => {
    if (minPriceReached && (addressIsValid || userAddressIsValid) && selectedPaymentType) {
      setCanCheckout(true);
    } else {
      setCanCheckout(false);
    }

    let _paymentButtonLabel = 'Mindestbestellwert';
    if (restaurantIsClosed()) {
      _paymentButtonLabel = 'Restaurant geschlossen';
    } else if (minPriceReached) {
      if (!addressIsValid && !userAddressIsValid) {
        _paymentButtonLabel = 'Adresse eingeben';
      } else if (!selectedPaymentType) {
        _paymentButtonLabel = 'Zahlart wählen';
      } else if (isPreOrderable) {
        _paymentButtonLabel = 'Vorbestellung absenden';
      } else _paymentButtonLabel = 'Bestellung absenden';
    }
    setPaymentButtonLabel(_paymentButtonLabel);
  }, [
    minPriceReached,
    addressIsValid,
    userAddressIsValid,
    selectedPaymentType,
    isClosedByLastMoment,
    storeProps,
    isPreOrderable,
    restaurantIsClosed,
  ]);

  const resetFormState = () => {
    setAddressData();
    setAddressIsValid(false);
    setUserAddressData();
    setUserAddressIsValid();
    setSelectedPaymentType();
  };

  useEffect(() => {
    function resetOnOrderSubmit(url) {
      if (submittingOrder && url.includes('ordersuccess')) {
        setSubmittingOrder(false);
        setShowCartModal(false);
        resetFormState();
      }
    }
    router.events.on('routeChangeComplete', resetOnOrderSubmit);

    return () => {
      router.events.off('routeChangeComplete', resetOnOrderSubmit);
    };
  }, [router.events, setShowCartModal, submittingOrder]);

  const checkoutClicked = () => {
    // check prerequisities and scrollIntoView where necessary
    if (showOrderForm) {
      if (!addressIsValid && !userAddressIsValid) {
        return addressScrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (!selectedPaymentType) {
        return paymentTypeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      return false;
    }

    // tests passed, we forward to checkout hö
    const checkoutData = {
      addressData: { deliveryNote, ...addressData },
      saveAddress: getValues('saveAddress'),
      isPickUp: selectedDeliveryRegion?.isPickUp,
      paymentType: selectedPaymentType,
      restaurantId: storeProps.restaurantId,
      selectedDeliveryTime: selectedDeliveryTime,
      regionId: selectedDeliveryRegion._id,
      orderSource: 'restaurant',
      frontendBasePath: window.location.protocol + '//' + window.location.host,
    };

    setSubmittingOrder(true);
    request('/api/cart/v1/submitOrder', {
      method: 'POST',
      body: JSON.stringify(checkoutData),
    })
      .then((result) => {
        if (result.status === 'redirect') {
          window.location.href = result.redirectUrl;
        } else if (result.status === 'ok') {
          router.push(`/ordersuccess?orderId=${result.order._id}`);
        } else {
          toast.error(`Fehlerhafter Status-Code - sorry`, {
            theme: 'colored',
          });
          setSubmittingOrder(false);
        }
      })
      .catch(async (error) => {
        if (error.response?.status) {
          const body = await resolveErrorBody(error);
          if (body.payPalError) {
            toast.error(
              `Aktuell besteht ein Problem bei PayPal Zahlungen - bitte wähle eine andere Zahlart.`,
              {
                theme: 'colored',
              },
            );
          } else if (body.error) {
            toast.error(
              <>
                <p>Fehler</p>
                <span>{body.error}</span>
              </>,
              {
                theme: 'colored',
              },
            );
          } else {
            toast.error(`Es ist ein Fehler aufgetreten. Das tut uns leid.`, {
              theme: 'colored',
            });
          }

          fetchCart();
        } else {
          // TODO: log error in logsystem
          toast.error(`Es ist ein Fehler aufgetreten. Das tut uns leid.`, {
            theme: 'colored',
          });
        }

        setSubmittingOrder(false);
      });
  };

  const {
    handleSubmit,
    register,
    formState,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: { code: '' },
  });
  const [couponValidating, setCouponValidating] = useState(false);
  const { ref: codeRef, ...codeRegister } = register('code', {
    required: true,
    minLength: 1,
  });
  const codeCustomRef = useRef();
  const redeemCoupon = (data) => {
    if (couponValidating) return;

    setCouponValidating(true);
    request('/api/cart/v1/redeemCoupon', {
      method: 'POST',
      body: JSON.stringify({
        restaurantId: storeProps.restaurantId,
        code: data.code,
        orderSource: 'restaurant',
      }),
    })
      .then((result) => {
        setRedeemedCoupons(result.redeemedCoupons);
        setValue('code', undefined);
        codeCustomRef.current.blur();

        toast.success(`Coupon wurde erfolgreich eingelöst!`);
      })
      .catch(async (error) => {
        // todo: hier error in logger
        const err = await resolveErrorBody(error);
        toast.error(`${err.error}`, {
          theme: 'colored',
        });
      })
      .finally(() => setCouponValidating(false));
  };

  const userAddressSelected = (address) => {
    if (address) {
      const addressDto = { ...address, email: userData?.email, houseNumber: address.housenumber };
      setUserAddressData(addressDto);
    } else {
      setUserAddressData(null);
    }
  };

  useEffect(() => {
    if (userAddressData) {
      setAddressData(userAddressData);
      setUserAddressIsValid(true);
    } else {
      setAddressData(null);
      setUserAddressIsValid(false);
    }
  }, [userAddressData]);

  return (
    <Wrapper style={{ display: props.hidden ? 'none' : 'block' }}>
      <CloseButtonWrapper onClick={() => setShowCartModal(false)}>
        <Icon style={{ width: 18 }} name="cross" />
      </CloseButtonWrapper>
      <ScrollWrapper>
        <ContentWrapper>
          <h2>Warenkorb</h2>
          <PickUpSwitchComponent />

          {loadingCart ? (
            <LoadIndicatorComponent
              style={{ marginTop: '100px' }}
              display={true}
              subTitle="Warenkorb wird geladen"
            />
          ) : (
            <>
              {error ? (
                <LoadingErrorReloadComponent reloadFn={fetchCart} />
              ) : (
                <div>
                  <p>Du bestellst bei:</p>
                  <OrderWithStoreAddress>
                    <p>{storeProps.restaurantName}</p>
                    <span>
                      {storeProps.address.street} {storeProps.address.houseNumber},{' '}
                      {storeProps.address.zip} {storeProps.address.city}
                    </span>
                  </OrderWithStoreAddress>
                  <ClosingTimeCounter variant="cart" />
                  <HorizontalDivider style={{ marginTop: '15px' }} />

                  {!cart?.items || cart?.items.length === 0 ? (
                    <>
                      <EmptyCartWrapper>
                        <NumberWrapper>
                          <span>0</span>
                        </NumberWrapper>
                        <CartIconWrapper>
                          <Icon
                            name="cart"
                            id="cart"
                            style={{
                              height: '100%',
                              fill: '#000000',
                            }}
                          />
                        </CartIconWrapper>
                      </EmptyCartWrapper>
                    </>
                  ) : (
                    <>
                      <CartArticleList list={cart.items} minPriceReached={minPriceReached} />
                    </>
                  )}
                </div>
              )}
              <PriceWrapper>
                <HorizontalDivider style={{ marginBottom: '15px' }} />

                <Row className="strong">
                  <Col xs={8}>
                    <span>Zwischensumme</span>
                  </Col>
                  <Col xs={4} className="price">
                    <Currency as="span">{cart?.meta?.totalPrice}</Currency>
                  </Col>
                </Row>

                {!selectedDeliveryRegion?.isPickUp && (
                  <Row>
                    <Col xs={8}>
                      <span>Lieferkosten</span>
                    </Col>
                    <Col xs={4} className="price">
                      <Currency as="span">
                        {isDeliveryForFree() ? 0 : selectedDeliveryRegion?.deliveryPrice}
                      </Currency>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xs={8}>
                    <span>Servicegebühr</span>
                  </Col>
                  <Col xs={4} className="price">
                    <Currency as="span">{0}</Currency>
                  </Col>
                </Row>

                {cart.redeemedCoupons
                  ?.filter((x) => x.couponType === 'absolute')
                  .map((coupon, index) => (
                    <CartCouponComponent
                      key={`${coupon._id}-${index}`}
                      coupon={coupon}
                      couponRemovedFn={fetchCart}
                    />
                  ))}
                {cart.redeemedCoupons
                  ?.filter((x) => x.couponType === 'relative')
                  .map((coupon, index) => (
                    <CartCouponComponent
                      key={`${coupon._id}-${index}`}
                      coupon={coupon}
                      couponRemovedFn={fetchCart}
                    />
                  ))}

                <Row className="strong" style={{ fontWeight: 700 }}>
                  <Col xs={8} className="flex-push-bottom">
                    <span>Gesamtsumme</span>
                  </Col>
                  <Col xs={4} className="price flex-push-bottom">
                    <TotalPriceWrapper>
                      <Currency as="span">{totalCartPrice}</Currency>
                    </TotalPriceWrapper>
                  </Col>
                </Row>

                {!minPriceReached && !selectedDeliveryRegion?.isPickUp && (
                  <>
                    <BelowMinimumOrderValue>
                      <Row>
                        <Col xs={6}>
                          <span>Mindestbestellwert{hasMinOrderValueExclusiveItems ? '*' : ''}</span>
                        </Col>
                        <Col xs={6} className="price">
                          {hasMinOrderValueExclusiveItems && (
                            <>
                              <Currency as="span">{priceWithoutNonMinOrderValueArticles}</Currency>{' '}
                              /{' '}
                            </>
                          )}
                          <Currency as="span">{selectedDeliveryRegion.minOrderValue}</Currency>
                        </Col>
                      </Row>
                    </BelowMinimumOrderValue>
                    {hasMinOrderValueExclusiveItems && (
                      <Row>
                        <Col xs={12}>
                          <MinOrderValueElement>
                            * Artikel <strong>nicht</strong> für den Mindestbestellwert
                            berücksichtigt
                          </MinOrderValueElement>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </PriceWrapper>
            </>
          )}

          {!selectedDeliveryRegion?.isPickUp &&
          selectedDeliveryRegion?.deliveryPrice &&
          storeProps?.freeDeliveryFrom > 0 ? (
            <FreeDeliveryIndicator />
          ) : (
            <></>
          )}

          {isPreOrderable || restaurantIsClosed() ? (
            <OrderNotice>
              <Icon
                style={{
                  width: '25px',
                  fill: props.theme.colors.primary,
                  marginRight: '10px',
                }}
                name="info"
              />
              {isPreOrderable && (
                <span>
                  Das Restaurant <strong>öffnet um {preOrderFrom()}</strong>
                </span>
              )}
              {restaurantIsClosed() && (
                <span>
                  Das Restaurant ist aktuell <strong>geschlossen</strong>.<br />
                  Eine Bestellung ist leider nicht möglich.
                </span>
              )}
            </OrderNotice>
          ) : null}

          {showOrderForm ? (
            <ContinueOrderProcessWrapper>
              <DesiredDeliveryTimeSelectorComponent
                isPickUp={selectedDeliveryRegion?.isPickUp}
                deliveryTimes={deliveryTimes}
                onSelectionChanged={setSelectedDeliveryTime}
                preselectedDeliveryTime={selectedDeliveryTime}
              />

              <Row style={{ marginTop: 10, fontSize: '14px' }}>
                <Col xs={12}>
                  <NoteInputWrapper>
                    <Form.Control
                      defaultValue={deliveryNote}
                      as="textarea"
                      // type="textarea"
                      maxLength={250}
                      placeholder={`Notizen zur ${
                        selectedDeliveryRegion?.isPickUp ? 'Abholung' : 'Lieferung'
                      } bitte hier eintragen`}
                      rows={3}
                      onChange={(e) => setDeliveryNote(e.target.value)}
                    />
                  </NoteInputWrapper>
                </Col>
              </Row>

              <Row style={{ marginTop: 10, fontSize: '14px' }}>
                <Col xs={12}>
                  <Form onSubmit={handleSubmit(redeemCoupon)}>
                    <InputGroup style={{ height: '40px' }}>
                      <CouponCodeInput
                        placeholder="Gutschein oder Rabattcode"
                        aria-label="Gutschein oder Rabattcode"
                        aria-describedby="button-coupon"
                        autoComplete="nope"
                        {...codeRegister}
                        ref={(e) => {
                          codeRef(e), (codeCustomRef.current = e);
                        }}
                      />
                      <AddonButton
                        variant="outline-secondary"
                        type="submit"
                        disabled={errors?.code || !formState.dirtyFields.code}
                        id="button-coupon"
                      >
                        {couponValidating ? <LoaderTyping /> : 'Einlösen'}
                      </AddonButton>
                    </InputGroup>
                  </Form>
                </Col>
              </Row>

              <FormHeroElement>
                <Row style={{ marginTop: 25, fontSize: '14px' }} ref={addressScrollRef}>
                  {selectedDeliveryRegion?.isPickUp ? (
                    <Col xs="12" className="text-center">
                      <Icon style={{ height: 50 }} name="position" />
                      <h3>Du bestellst zur Abholung</h3>
                      <span>Nenn uns bitte noch Deine Kontaktdaten</span>
                    </Col>
                  ) : (
                    <Col xs="12" className="text-center">
                      <Icon style={{ height: 44 }} name="scooter-min" />
                      <h3>Wohin soll Deine Lieferung gehen?</h3>
                      <span>Schon fast geschafft und das Essen ist da</span>
                    </Col>
                  )}
                </Row>
              </FormHeroElement>

              <AddressElementWrapper>
                {!hasUserData ? (
                  <>
                    <ShadowBox>
                      <LoginOrRegisterButton onClick={() => showLogin()}>
                        <TitleAndIconWrapper>
                          <IconWrapper>
                            <Icon name="avatar" style={{ width: '28px', maxHeight: '100%' }} />
                          </IconWrapper>
                          <TitleWrapper>
                            <Title>Einloggen oder Registrieren</Title>
                            <SubTitle>Für Vorteile &amp; Angebote</SubTitle>
                          </TitleWrapper>
                        </TitleAndIconWrapper>
                      </LoginOrRegisterButton>
                    </ShadowBox>
                    <ExpandableBoxComponent
                      title="Als Gast bestellen"
                      subTitle="und fortfahren"
                      iconName="anonymous"
                      iconStyle={{ width: '40px' }}
                      permanentlyOpenend={true}
                      recalculateOnChangeOfProp={isCartAlwaysVisibleInScreen}
                    >
                      <AddressFormComponent
                        initialData={addressData}
                        prefilledZip={selectedDeliveryRegion?.fromZip}
                        onValidAddress={guestAddressValid}
                        onInvalidAddress={guestAddressInvalid}
                      />
                    </ExpandableBoxComponent>
                  </>
                ) : (
                  <>
                    <UserAddressSelectorComponent
                      selectedAddressId={userAddressData?._id}
                      onAddressSelected={userAddressSelected}
                    />
                    {!userAddressData && (
                      <ShadowBox style={{ padding: '15px 10px' }}>
                        <AddressFormComponent
                          prefilledZip={selectedDeliveryRegion?.fromZip}
                          initialData={addressData}
                          onValidAddress={guestAddressValid}
                          onInvalidAddress={guestAddressInvalid}
                        />
                        <StoreAddressQuestion>
                          <Form.Check type="checkbox" id="saveAddress" className="checkBox">
                            <Form.Check.Input type="checkbox" {...register('saveAddress')} />
                            <Form.Check.Label>
                              Ich möchte die eingegebene Adresse speichern
                            </Form.Check.Label>
                          </Form.Check>
                        </StoreAddressQuestion>
                      </ShadowBox>
                    )}
                  </>
                )}
              </AddressElementWrapper>

              <FormHeroElement>
                <Row style={{ marginTop: 25, fontSize: '14px' }}>
                  <Col xs="12" className="text-center">
                    <Icon style={{ height: 60 }} name="money-bag" />
                    <h3>Wähle eine Zahlungsmethode</h3>
                    <span>und voilà, dein Essen wird zubereitet</span>
                  </Col>
                </Row>
              </FormHeroElement>

              <PaymentTypesWrapper>
                <Row style={{ marginTop: 10, fontSize: '14px' }} ref={paymentTypeRef}>
                  <Col xs={12}>
                    {storeProps?.paymentTypes?.includes('cash') && (
                      <SelectableButton
                        iconName="cash"
                        label="Barzahlung"
                        selected={selectedPaymentType === 'cash'}
                        onSelect={() => setSelectedPaymentType('cash')}
                      />
                    )}

                    {storeProps?.paymentTypes?.includes('paypal') && (
                      <SelectableButton
                        iconName="paypal"
                        label="PayPal"
                        selected={selectedPaymentType === 'paypal'}
                        onSelect={() => setSelectedPaymentType('paypal')}
                      />
                    )}

                    {storeProps?.paymentTypes?.includes('eccash') && (
                      <SelectableButton
                        iconName="eccash"
                        label="EC-Cash beim Fahrer"
                        selected={selectedPaymentType === 'eccash'}
                        onSelect={() => setSelectedPaymentType('eccash')}
                      />
                    )}

                    {storeProps?.paymentTypes?.includes('cash') === false && (
                      <SelectableButton iconName="cash" label="Keine Barzahlung möglich" disabled />
                    )}
                  </Col>
                </Row>
              </PaymentTypesWrapper>

              <Row style={{ marginTop: 10 }}>
                <Col xs={12} style={{ fontSize: 10, fontWeight: 300, fontStyle: 'italic' }}>
                  <span>
                    Durch das Absenden der Bestellung bestätigst Du den Warenkorb und die
                    Richtigkeit deiner eingegebenen Daten. Du stimmst außerdem unseren{' '}
                    <Link
                      href="/privacy"
                      target="_blank"
                      style={{ color: 'inherit', fontWeight: 500 }}
                    >
                      <strong>Datenschutzbestimmungen</strong>
                    </Link>{' '}
                    zu.
                    <br />
                    Alle Preise inkl. gesetzl. USt.
                  </span>
                </Col>
              </Row>

              {storeProps.currentDeliveryTime > 60 ? (
                <Row
                  style={{
                    marginTop: 15,
                    marginLeft: -15,
                    marginRight: -15,
                    padding: 15,
                    backgroundColor: '#FCF0DE',
                  }}
                >
                  <Col
                    xs={12}
                    className="text-center"
                    style={{
                      fontSize: 14,
                      fontWeight: 300,
                      color: props.theme.colors.warning,
                    }}
                  >
                    {selectedDeliveryRegion.isPickUp ? 'Abholbereit in: ' : 'Aktuelle Lieferzeit: '}
                    <span style={{ fontWeight: 400 }}>
                      ca. {storeProps.currentDeliveryTime} Minuten
                    </span>
                  </Col>
                </Row>
              ) : (
                <Row style={{ padding: 15 }}>
                  <Col
                    xs={12}
                    className="text-center"
                    style={{ fontSize: 14, fontWeight: 300, color: 'grey' }}
                  >
                    {selectedDeliveryRegion.isPickUp ? 'Abholbereit in: ' : 'Aktuelle Lieferzeit: '}
                    <span style={{ fontWeight: 400 }}>
                      ca. {storeProps.currentDeliveryTime} Minuten
                    </span>
                  </Col>
                </Row>
              )}
            </ContinueOrderProcessWrapper>
          ) : (
            <Row style={{ height: 15 }}>&nbsp;</Row>
          )}
        </ContentWrapper>
      </ScrollWrapper>
      <PaymentButtonWrapper
        $isOnMobile={!isCartAlwaysVisibleInScreen}
        $isVisible={isCartAlwaysVisibleInScreen || showCartModalForMobile}
      >
        <PaymentButton
          className={!canCheckout && 'disabled'}
          disabled={submittingOrder || !minPriceReached || restaurantIsClosed()}
          onClick={checkoutClicked}
        >
          {submittingOrder ? <LoaderTyping /> : paymentButtonLabel}
        </PaymentButton>
      </PaymentButtonWrapper>
    </Wrapper>
  );
}

CartComponent.propTypes = {
  hidden: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(CartComponent);
