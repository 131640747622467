import { Row, Col, FormControl, Form, FloatingLabel } from 'react-bootstrap';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useEffect, useState, useRef, useContext } from 'react';
import RestaurantContext from '../../Context/RestaurantContext';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import RegionSelectorModal from '../RegionSelectorModal';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { getFieldLabelString } from '../../util/formHelpers';

const Wrapper = styled.div`
  .row:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const AddressFormComponent = ({
  prefilledZip,
  initialData,
  onValidAddress,
  onInvalidAddress,
  ...rest
}) => {
  const formRef = useRef();

  const {
    setShowCartModal,
    restaurantProps: storeProps,
    setSelectedDeliveryRegion,
    selectedDeliveryRegion,
  } = useContext(RestaurantContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    formState,
    setValue,
    getValues,
    watch,
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (initialData && !formState.isDirty) {
      reset(initialData);
      trigger();
    }
  }, [formState.isDirty, initialData, reset, setValue, trigger]);

  const resetZipToZipBefore = useCallback(() => {
    setValue('zip', prefilledZip, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  }, [setValue, prefilledZip]);

  useEffect(() => {
    resetZipToZipBefore();
  }, [selectedDeliveryRegion?.isPickUp, resetZipToZipBefore]);

  useEffect(() => {
    if (prefilledZip)
      setValue('zip', prefilledZip, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  }, [prefilledZip]);

  // listen for value changes to send to HoC
  const watchAll = watch();
  useEffect(() => {
    if (isValid) {
      onValidAddress(getValues());
    } else {
      onInvalidAddress();
    }
  }, [watchAll, isValid, onValidAddress, onInvalidAddress, getValues]);

  const formSubmit = (_, event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  /** fns for DeliverRegion selection on zip change */
  const [selectionModalState, setSelectionModalState] = useState(() => ({
    show: false,
    regionList: [],
  }));
  const hideRegionModal = () => {
    setShowCartModal(false);
    resetZipToZipBefore();
    setSelectionModalState((state) => ({ ...state, show: false }));
  };
  const hideRegionModalOnSelect = () => {
    setSelectionModalState((state) => ({ ...state, show: false }));
  };
  const onRegionSelected = (region, fromZip, fromModal = false) => {
    // close modal if was shown
    if (fromModal) hideRegionModalOnSelect();

    // save region selection to context
    setSelectedDeliveryRegion(region, fromZip, selectedDeliveryRegion?.isPickUp);

    // show toast as success!!
    toast.success(`Liefergebiet geändert - Bitte prüfen Sie Ihren Warenkorb.`, {
      position: 'bottom-right',
      hideProgressBar: true,
    });
  };

  const onZipChange = (e) => {
    const watchZip = e.target.value;

    const zipSchema = yup
      .string()
      .required('PLZ')
      .min(5)
      .max(5)
      .matches(/^[0-9]{1,5}$/);

    async function revalidateAndReactToZipChange(zip) {
      const isValidZip = await zipSchema.isValid(watchZip);
      if (isValidZip) {
        onZipRegionChange(zip);
      }
    }

    if (prefilledZip !== watchZip) {
      revalidateAndReactToZipChange(watchZip);
    }
  };

  const onZipRegionChange = (zip) => {
    if (storeProps.onlyPickUp || selectedDeliveryRegion?.isPickUp) return; // we do not need to validate the zip when we only do pickUp

    const matchingRegionsList = [];
    // get all deliveryregions containing this zip
    storeProps.deliveryRegionsRaw?.forEach((deliveryRegion) => {
      if (deliveryRegion.zipCodes?.includes(zip)) {
        matchingRegionsList.push(deliveryRegion);
      }
    });

    if (matchingRegionsList.length > 1) {
      // show selectionModal with fetched data amk
      setSelectionModalState((state) => ({
        ...state,
        show: true,
        regionList: matchingRegionsList,
        forZip: zip,
      }));
    } else if (matchingRegionsList.length === 1) {
      // store selection in context and send to menu page
      onRegionSelected(matchingRegionsList[0], zip);
    } else {
      // show error to user
      toast.error(`Die PLZ ${zip} liegt leider außerhalb unseres Liefergebietes.`, {
        theme: 'colored',
      });
      resetZipToZipBefore();
    }
  };

  /*-- START: Autofill fix */
  // name
  const myNameRef = useRef();
  const { ref: nameRef, ...nameRest } = register('name', {
    required: true,
    maxLength: 50,
    minLength: 5,
    pattern: /^\d*\s*[a-zA-ZäöüÄÖÜß][a-zA-ZäöüÄÖÜß0-9ß\-\ ]*\ *[^0-9]$/,
  });
  useEffect(() => {
    addAutofillFix('name', myNameRef);
  }, [myNameRef]);

  // street
  const myStreetRef = useRef();
  const { ref: streetRef, ...streetRest } = register('street', {
    required: true,
    maxLength: 60,
    pattern: /^(?=\s*(?:[\w.a-zA-ZäöüÄÖÜß-]\s*){4,}).*[^0-9]+$|^([A-Ua-u]{1}[0-9]{1})$/,
  });
  useEffect(() => {
    addAutofillFix('street', myStreetRef);
  }, [myStreetRef]);

  // houseNumber
  const myHouseNumberRef = useRef();
  const { ref: houseNumberRef, ...houseNumberRest } = register('houseNumber', {
    required: { value: true, message: 'Nr.' },
    maxLength: 8,
    minLength: 1,
    pattern: /^(?:[0-9]+[/-]*[0-9]*[\s]*[a-zA-Z]?)+$/,
  });
  useEffect(() => {
    addAutofillFix('houseNumber', myHouseNumberRef);
  }, [myHouseNumberRef]);

  // city
  const myCityRef = useRef();
  const { ref: cityRef, ...cityRest } = register('city', {
    required: true,
    maxLength: 50,
    minLength: 2,
    pattern: /^(?!.*\.\.)(\d*\s*[a-zA-ZäöüÄÖÜß\- \*.]*\ *[^0-9])$/,
  });
  useEffect(() => {
    addAutofillFix('city', myCityRef);
  }, [myCityRef]);

  // zip
  const myZipRef = useRef();
  const { ref: zipRef, ...zipRest } = register('zip', {
    required: { value: true, message: 'PLZ' },
    maxLength: 5,
    minLength: 5,
    pattern: /^[0-9]{1,5}$/,
    onChange: (e) => {
      onZipChange(e);
    },
  });
  useEffect(() => {
    addAutofillFix('zip', myZipRef);
  }, [myZipRef]);

  // telephone
  const myTelRef = useRef();
  const { ref: telRef, ...telRest } = register('telephone', {
    required: true,
    maxLength: 50,
    minLength: 5,
    pattern: /^(?:\(\+?\d+\)|\+?\d+)(?:\s*[-/]*\s*\d+)+$/i,
  });
  useEffect(() => {
    addAutofillFix('telephone', myTelRef);
  }, [myTelRef]);

  // email
  const myMailRef = useRef();
  const { ref: mailRef, ...mailRest } = register('email', {
    required: true,
    maxLength: 50,
    minLength: 2,
    pattern: /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,10}$/i,
  });
  useEffect(() => {
    addAutofillFix('email', myMailRef);
  }, [myMailRef]);

  function addAutofillFix(fieldName, ref) {
    if (ref) {
      ref.current.addEventListener(
        'input',
        (e) => {
          if (!e.data) {
            setValue(fieldName, e.target.value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
          }
        },
        { passive: true },
      );
    }
  }
  /*-- END: Autofill Fix */

  return (
    <Wrapper style={rest.style}>
      <Form onSubmit={handleSubmit(formSubmit)} ref={formRef} noValidate>
        <Row>
          <Col xs={12}>
            <FloatingLabel
              controlId="name"
              label={getFieldLabelString(errors, 'name', 'Name', 'Dein Name')}
              className="size-md"
            >
              <FormControl
                type="text"
                placeholder="Dein Name"
                className="size-md"
                isInvalid={errors.name && formState.dirtyFields.name}
                isValid={!errors.name && formState.dirtyFields.name}
                {...nameRest}
                ref={(e) => {
                  nameRef(e), (myNameRef.current = e);
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col xs={7} style={{ paddingRight: 0 }}>
            <FloatingLabel
              controlId="street"
              className="size-md"
              label={getFieldLabelString(errors, 'street', 'Str.', 'Straße')}
            >
              <FormControl
                type="text"
                placeholder="Straße"
                className="size-md"
                isInvalid={errors.street && formState.dirtyFields.street}
                isValid={!errors.street && formState.dirtyFields.street}
                {...streetRest}
                ref={(e) => {
                  streetRef(e), (myStreetRef.current = e);
                }}
              />
            </FloatingLabel>
          </Col>
          <Col xs={5}>
            <FloatingLabel
              controlId="houseNumber"
              label={getFieldLabelString(errors, 'houseNumber', 'Nr.')}
              className="size-md"
            >
              <FormControl
                type="text"
                placeholder="Hausnr."
                className="size-md"
                autoComplete="address-line2"
                isInvalid={errors.houseNumber && formState.dirtyFields.houseNumber}
                isValid={!errors.houseNumber && formState.dirtyFields.houseNumber}
                {...houseNumberRest}
                ref={(e) => {
                  houseNumberRef(e), (myHouseNumberRef.current = e);
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row>
          <Col xs={7} style={{ paddingRight: 0 }}>
            <FloatingLabel
              controlId="city"
              label={getFieldLabelString(errors, 'city', 'Ort')}
              className="size-md"
            >
              <FormControl
                type="text"
                placeholder="Ort"
                className="size-md"
                isInvalid={errors.city && formState.dirtyFields.city}
                isValid={!errors.city && formState.dirtyFields.city}
                {...cityRest}
                ref={(e) => {
                  cityRef(e), (myCityRef.current = e);
                }}
              />
            </FloatingLabel>
          </Col>
          <Col xs={5}>
            <FloatingLabel
              controlId="zip"
              label={getFieldLabelString(errors, 'zip', 'PLZ')}
              className="size-md"
            >
              <FormControl
                type="tel"
                placeholder="PLZ"
                className="size-md"
                isInvalid={errors.zip && formState.dirtyFields.zip}
                isValid={!errors.zip && formState.dirtyFields.zip}
                maxLength={5}
                autoComplete="nope"
                {...zipRest}
                ref={(e) => {
                  zipRef(e), (myZipRef.current = e);
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FloatingLabel
              controlId="telephone"
              label={getFieldLabelString(errors, 'telephone', 'Telefonnummer')}
              className="size-md"
            >
              <FormControl
                type="tel"
                placeholder="Telefonnummer"
                className="size-md"
                isInvalid={errors.telephone && formState.dirtyFields.telephone}
                isValid={!errors.telephone && formState.dirtyFields.telephone}
                {...telRest}
                ref={(e) => {
                  telRef(e), (myTelRef.current = e);
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FloatingLabel
              controlId="email"
              label={getFieldLabelString(errors, 'email', 'E-Mail', 'E-Mail Adresse')}
              className="size-md"
            >
              <FormControl
                type="email"
                placeholder="E-Mail Adresse"
                className="size-md"
                isInvalid={errors.email && formState.dirtyFields.email}
                isValid={!errors.email && formState.dirtyFields.email}
                {...mailRest}
                ref={(e) => {
                  mailRef(e), (myMailRef.current = e);
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>
      </Form>

      <RegionSelectorModal
        show={selectionModalState.show}
        onHide={hideRegionModal}
        onSelection={(selectedRegion) =>
          onRegionSelected(selectedRegion, selectionModalState.forZip, true)
        }
        forZip={selectionModalState.forZip}
        regionsList={selectionModalState.regionList}
        currentDeliveryTime={storeProps.currentDeliveryTime}
      />
    </Wrapper>
  );
};

AddressFormComponent.propTypes = {
  onValidAddress: PropTypes.func,
  onInvalidAddress: PropTypes.func,
  initialData: PropTypes.object,
  prefilledZip: PropTypes.string,
};

export default AddressFormComponent;
