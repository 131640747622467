import React from 'react';
import PropTypes from 'prop-types';

import Position from './Icons/position';
import FoodMenu from './Icons/foodmenu';
import ScooterMin from './Icons/scooter-min';
import Email from './Icons/email';
import Phone from './Icons/phone';
import Avatar from './Icons/avatar';
import AvatarDummy from './Icons/avatar-dummy';
import Cart from './Icons/cart';
import Cookie from './Icons/cookie';
import Menu from './Icons/menu';
import Store from './Icons/store';
import ArrowBack from './Icons/arrow-back';
import ArrowNext from './Icons/arrow-next';
import ArrowThick from './Icons/arrow-thick';
import Watch from './Icons/watch';
import Cross from './Icons/cross';
import AddCircle from './Icons/add-circle';
import Info from './Icons/info';
import Veggie from './Icons/vegetarian';
import Vegan from './Icons/vegan';
import Hot from './Icons/hot';
import Dropdown from './Icons/dropdown';
import DropdownRound from './Icons/dropdown-round';
import Check from './Icons/check';
import CheckSingle from './Icons/check-single';
import Refresh from './Icons/refresh';
import Search from './Icons/search';
import Triangle from './Icons/triangle';
import Anonymous from './Icons/anonymous';
import MoneyBag from './Icons/money-bag';
import Trash from './Icons/trash';
import StarEmpty from './Icons/star-empty';
import StarFull from './Icons/star-full';
import Lock from './Icons/lock';
import Upload from './Icons/upload';
import AddRound from './Icons/addRound';

import PaymentTypeAmex from './Icons/paymenttype-amex';
import PaymentTypeCard from './Icons/paymenttype-card';
import PaymentTypeCash from './Icons/paymenttype-cash';
import PaymentTypeMastercard from './Icons/paymenttype-mastercard';
import PaymentTypePayPal from './Icons/paymenttype-paypal';
import PaymentTypePayPalSmall from './Icons/paypal';
import PaymentTypeVisa from './Icons/paymenttype-visa';

import dynamic from 'next/dynamic';
const IllustrationHandyBurger = dynamic(() => import('./Icons/illustration-handy-burger'), {
  ssr: false,
});

function Icon(props) {
  switch (props.name) {
    case 'position':
      return <Position {...props} />;
    case 'foodmenu':
      return <FoodMenu {...props} />;
    case 'scooter-min':
      return <ScooterMin {...props} />;
    case 'e-mail':
      return <Email {...props} />;
    case 'phone':
      return <Phone {...props} />;
    case 'avatar':
      return <Avatar {...props} />;
    case 'avatar-dummy':
      return <AvatarDummy {...props} />;
    case 'cart':
      return <Cart {...props} />;
    case 'pt-amex':
      return <PaymentTypeAmex {...props} />;
    case 'pt-card':
      return <PaymentTypeCard {...props} />;
    case 'pt-cash':
      return <PaymentTypeCash {...props} />;
    case 'pt-master':
      return <PaymentTypeMastercard {...props} />;
    case 'pt-paypal':
      return <PaymentTypePayPal {...props} />;
    case 'pt-paypal-small':
      return <PaymentTypePayPalSmall {...props} />;
    case 'pt-visa':
      return <PaymentTypeVisa {...props} />;
    case 'cookie':
      return <Cookie {...props} />;
    case 'store':
      return <Store {...props} />;
    case 'menu':
      return <Menu {...props} />;
    case 'arrow-back':
      return <ArrowBack {...props} />;
    case 'arrow-next':
      return <ArrowNext {...props} />;
    case 'arrow-thick':
      return <ArrowThick {...props} />;
    case 'watch':
      return <Watch {...props} />;
    case 'cross':
      return <Cross {...props} />;
    case 'add-circle':
      return <AddCircle {...props} />;
    case 'info':
      return <Info {...props} />;
    case 'vegetarian':
      return <Veggie {...props} />;
    case 'vegan':
      return <Vegan {...props} />;
    case 'hot':
      return <Hot {...props} />;
    case 'dropdown':
      return <Dropdown {...props} />;
    case 'dropdown-round':
      return <DropdownRound {...props} />;
    case 'check':
      return <Check {...props} />;
    case 'check-single':
      return <CheckSingle {...props} />;
    case 'refresh':
      return <Refresh {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'triangle':
      return <Triangle {...props} />;
    case 'anonymous':
      return <Anonymous {...props} />;
    case 'money-bag':
      return <MoneyBag {...props} />;
    case 'trash':
      return <Trash {...props} />;
    case 'star-empty':
      return <StarEmpty {...props} />;
    case 'star-full':
      return <StarFull {...props} />;
    case 'illu-burger':
      return <IllustrationHandyBurger {...props} />;
    case 'lock':
      return <Lock {...props} />;
    case 'upload':
      return <Upload {...props} />;
    case 'add-round':
      return <AddRound {...props} />;
    default:
      return <div></div>;
  }
}

Icon.propTypes = {
  style: PropTypes.object,
  name: PropTypes.oneOf([
    'position',
    'foodmenu',
    'scooter-min',
    'e-mail',
    'phone',
    'avatar',
    'avatar-dummy',
    'cart',
    'pt-amex',
    'pt-card',
    'pt-cash',
    'pt-master',
    'pt-paypal',
    'pt-paypal-small',
    'pt-visa',
    'cookie',
    'store',
    'menu',
    'arrow-back',
    'arrow-next',
    'arrow-thick',
    'watch',
    'cross',
    'add-circle',
    'info',
    'vegetarian',
    'vegan',
    'hot',
    'dropdown',
    'dropdown-round',
    'check',
    'check-single',
    'refresh',
    'search',
    'triangle',
    'anonymous',
    'money-bag',
    'trash',
    'star-empty',
    'star-full',
    'illu-burger',
    'lock',
    'upload',
    'add-round',
  ]),
  viewBox: PropTypes.string,
};

export default Icon;
