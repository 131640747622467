import PropTypes from 'prop-types';

function SVG({ style, ...rest }) {
  return (
    <svg style={style} {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60.04">
      <path d="M29.94 6.92l5.32 17.12.46 1.49h18.11l-13.29 10.3-1.15.89.42 1.39 5 16.48-13.57-9.89-1.24-.89-1.23.88L14.56 54.8l4.73-16.76.38-1.33-1.08-.88L5.93 25.55h17.86l.48-1.45 5.67-17.18M30 .04l-7.73 23.4H0L17.26 37.5l-6.37 22.54L30 46.41l18.65 13.63-6.82-22.5L60 23.44H37.28z" />
      <path d="M30 .04l-7.73 23.44H0l17.26 14.06-6.37 22.5L30 46.45l18.65 13.59-6.82-22.5L60 23.48H37.28L30 .04z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
