import { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { Currency } from 'react-intl-number-format';
import Icon from '../Icon';
import AttributeSelector from './AttributeSelector';

const MenuOptions = styled.div`
  position: relative;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const HidingDiv = styled.div`
  position: relative;
  display: ${(props) => (props.$show ? 'block' : 'none')};
`;

const MenuOptionsRow = styled(Row)`
  align-items: center;
  font-weight: 300;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;

  & > .price {
    font-weight: ${(props) => (props.selected ? '500' : '300')};
  }
`;

function MenuOptionSelector({ value, selected, preSelection, ...props }) {
  const [optionsSelected, setOptionsSelected] = useState([]);

  const optionChanged = (attr, optionSelected) => {
    const index = optionsSelected.findIndex((el) => el.attribute.title === attr.title);

    if (index > -1) {
      optionsSelected[index].selectedValue = optionSelected;
    } else {
      optionsSelected.push({
        attribute: { title: attr.title },
        selectedValue: optionSelected,
      });
    }

    setOptionsSelected(optionsSelected);
    if (selected)
      props.onAttributeChange(
        { title: value.title, _id: value._id, price: value.price },
        optionsSelected,
      );
  };

  const rowClicked = (option) => {
    props.onSelect(
      { title: option.title, _id: option._id, price: option.price },
      optionsSelected,
      selected,
    );
  };

  const _getSelectionId = (title) => {
    if (!preSelection || !preSelection.selectedAttributes) return null;

    return preSelection.selectedAttributes.find((el) => el.attribute.title === title).selectedValue
      ._id;
  };

  return (
    <MenuOptions key={value._id}>
      <MenuOptionsRow selected={selected} onClick={() => rowClicked(value)}>
        <Col xs={6}>{value.title}</Col>
        <Col className="flex-grow-1 text-end price">
          <Currency as="span">{value.price}</Currency>
        </Col>
        <Col xs={2} className="text-end">
          <Icon
            style={{
              width: 23,
              fill: selected ? props.theme.colors.primary : '#cecece',
            }}
            name="check"
          />
        </Col>
      </MenuOptionsRow>

      <HidingDiv $show={selected}>
        {value.opt1 && value.opt1.length > 0 && (
          <AttributeSelector
            attribute={{ title: 'Beilage', values: value.opt1 }}
            selectionId={_getSelectionId('Beilage')}
            onChange={optionChanged}
          />
        )}
        {value.opt2 && value.opt2.length > 0 && (
          <AttributeSelector
            attribute={{ title: 'Getränk', values: value.opt2 }}
            selectionId={_getSelectionId('Getränk')}
            onChange={optionChanged}
          />
        )}
      </HidingDiv>
    </MenuOptions>
  );
}

MenuOptionSelector.propTypes = {
  value: PropTypes.object.isRequired,
  theme: PropTypes.object,
  selected: PropTypes.bool,
  preSelection: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onAttributeChange: PropTypes.func.isRequired,
};

export default withTheme(MenuOptionSelector);
