import React from 'react';
import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 1000.046 323.653"
      style={style}
      {...rest}
    >
      <path
        fill="#00579f"
        d="M433.35228125 318.98380797h-81.01073521L403.01146398 5.67909768h81.00630524zM727.02339507 13.33850388C711.04451817 6.99922668 685.70069927-.000115 654.36314031-.000115c-80.00070362 0-136.33654437 42.66054465-136.68208149 103.65227661-.66449446 44.99956517 40.33481403 69.99341698 70.9990186 84.997702 31.34198894 15.3321023 41.9960502 25.33938894 41.9960502 39.0058251-.31895735 20.98916517-25.32609905 30.66420458-48.64985477 30.66420458-32.3431606 0-49.67317624-4.98813845-76.00930687-16.66552118l-10.66735115-5.0058583-11.33627557 70.33009417c19.00011174 8.6561479 54.00568015 16.33770391 90.34909743 16.6743811 85.00213196 0 140.341231-42.00048014 140.99686554-106.99689875.3233873-35.66563292-21.32584236-62.99407528-67.99993358-85.32551927-28.33404399-14.33536059-45.68620945-24.00154007-45.68620945-38.6691479.33224723-13.33418893 14.67646775-26.99176517 46.66080133-26.99176517 26.33613063-.66892442 45.68620945 5.66149285 60.34495735 11.99634008l7.32715897 3.32690229 11.01731822-67.65439647zM834.69364822 207.9910825c6.67152443-17.99894008 32.3431606-87.66010982 32.3431606-87.66010982-.3366772.66892442 6.65823453-18.33118731 10.65849121-29.99528015l5.66149284 26.99619513s15.34096222 74.99484532 18.67229447 90.65919484h-67.33543912zM934.69120528 5.67909768h-62.66182805c-19.32349905 0-34.00439676 5.66149285-42.34158731 25.99502348L709.35670223 318.979378h85.00213196s13.99425344-38.6691479 17.00219838-46.99747853H915.3721362c2.32573062 10.99959838 9.66617948 46.99747853 9.66617948 46.99747853h75.00813521L934.69120528 5.67909768zM284.67828965 5.67909768l-79.33620916 213.64383043-8.66943778-43.32946908c-14.66760783-49.99656355-60.66834466-104.31677108-112.00275706-131.3262561l72.6691147 273.9843578h85.66662642L370.34048612 5.67909769h-85.66219647z"
      />
      <path
        fill="#faa61a"
        d="M131.67179414 5.67909768H1.33341981L9.1e-7 12.00951495c101.67208312 25.99945344 169.00752224 88.67014141 196.6726418 163.99723397l-28.334044-143.9915206c-4.66475114-20.01014333-19.00011173-25.67606613-36.66680457-26.33613064z"
      />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
