import { useContext, useEffect, useState, useCallback } from 'react';
import styled, { withTheme } from 'styled-components';
import { Form } from 'react-bootstrap';
import ArticleConfigurationContext from '../../Context/ArticleConfigurationContext';
import RestaurantContext from '../../Context/RestaurantContext';
import HorizontalDivider from '../HorizontalDivider';
import Icon from '../Icon';
import { Button } from 'react-bootstrap';
import { Currency } from 'react-intl-number-format';
import ShowMoreListComponent from '../UtilComponents/ShowMoreListComponent';
import ExtrasManipulator from './ExtrasManipulator';
import AttributeSelector from './AttributeSelector';
import MenuOptionSelector from './MenuOptionSelector';
import LoaderTyping from '../Loader/LoaderTyping';
import cartHelpers from './cartHelpers';
import CartContext from '../../Context/CartContext';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: white;

  .notes-area {
    font-size: 14px;

    &::placeholder {
      font-size: inherit;
      font-style: italic;
      font-weight: 400;
      letter-spacing: 0.53px;
      color: #979797;
    }
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background: white;
  box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.13);
  text-align: center;
  user-select: none;
  cursor: pointer;

  transition: box-shadow 0.1s ease-in;
  &:hover {
    box-shadow: 0 2px 9px 0 rgba(120, 130, 140, 0.3);
  }

  & > svg {
    margin-top: 8px;
    vertical-align: bottom;
  }
`;

const TitleAndDescription = styled.div`
  h3 {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.8px;
    margin: 0;
    line-height: 20px;
    color: #000000;
  }
  p {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.67px;
    line-height: 20px;
    margin: 0;
  }
`;

const AddToCartWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: calc(60px + (env(safe-area-inset-bottom) / 2));

  padding: 8px 15px;
  padding-bottom: calc(8px + (env(safe-area-inset-bottom) / 2));

  background-color: white;
  box-shadow: 0 -2px 9px RGBA(120, 130, 140, 0.07);
`;
const AmountCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
`;
const AmountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  flex-grow: 0;
  flex-shrink: 1;

  white-space: nowrap;
  font-size: 18px;
  flex-flow: row nowrap;

  & > span {
    text-align: center;
    flex-grow: 1;
  }
`;
const ButtonWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 50%;

  span {
    font-size: 20px;
    letter-spacing: 0.95px;
    font-weight: 500;
  }
`;

const AmountButton = styled.button`
  padding: 0;
  min-width: 25px;
  opacity: 0.5;
  font-size: 20px;
  line-height: 18px;
  background: none;
  border: none;
  outline: none !important;
  user-select: none;

  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`;

const ScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 75px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Attributes = styled.div`
  position: relative;
`;

const MenuOptions = styled.div`
  position: relative;
`;

const NoteWrapper = styled.div`
  padding-bottom: calc(env(safe-area-inset-bottom) / 2);
`;

const TitleDiv = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-bottom: 15px;

  h2 {
    margin: 0;
    padding-left: 10px;
    font-size: 15px;
  }
`;

const Extras = styled.div`
  position: relative;

  h2 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 20px;
    letter-spacing: 0.67px;
  }
`;

function ArticleConfiguratorComponent(props) {
  const { article, configuration, uuid, setArticleWithConfiguration, resetArticleSelection } =
    useContext(ArticleConfigurationContext);
  const { restaurantProps } = useContext(RestaurantContext);
  const { fetchCart } = useContext(CartContext);

  const [totalPrice, setTotalPrice] = useState(0);
  const [extraCharge, setExtraCharge] = useState(0);
  const [specialRequestCharge, setSpecialRequestCharge] = useState(0);
  const [loading, setLoading] = useState(false);

  const updateTotalPrice = useCallback(() => {
    let sum = 0.0;

    sum += article.price; // base price
    // add attributes price
    configuration.attributes.forEach((attr) => {
      sum += attr.selectedValue.price;
    });
    // add extras (+ extra charge) * amount
    configuration.extras.forEach((extra) => {
      const extraPrice = extra.price + (!extra.protected ? extraCharge || 0 : 0);
      sum += extraPrice * extra.amount;
    });
    // add special requests (+ extra charge) * amount
    configuration.specialRequests.forEach((special) => {
      const specialPrice = special.price + (!special.protected ? specialRequestCharge || 0 : 0);
      sum += specialPrice * special.amount;
    });

    if (configuration.menuOption?.option) {
      sum += configuration.menuOption.option.price;
    }

    // return sum * configuration.amount;
    setTotalPrice(sum * configuration.amount);
  }, [
    article.price,
    configuration.amount,
    configuration.extras,
    configuration.specialRequests,
    configuration.attributes,
    configuration.menuOption,
    extraCharge,
    specialRequestCharge,
  ]);

  useEffect(() => {
    updateTotalPrice();
  }, [updateTotalPrice]);

  useEffect(() => {
    if (isEmpty(article.attributes)) {
      setExtraCharge(0);
      setSpecialRequestCharge(0);
    }
  }, [article]);

  const increaseAmount = () => {
    const newConfig = { ...configuration };
    newConfig.amount += 1;
    setArticleWithConfiguration(article, newConfig, uuid, 'increaseAmount');
    updateTotalPrice();
  };

  const decreaseAmount = () => {
    if (configuration.amount > 1) {
      const newConfig = { ...configuration };
      newConfig.amount -= 1;
      setArticleWithConfiguration(article, newConfig, uuid, 'decreaseAmount');
      updateTotalPrice();
    }
  };

  const noteChanged = (value) => {
    const newConfig = { ...configuration };
    newConfig.note = value;
    setArticleWithConfiguration(article, newConfig, uuid, 'noteChanged');
  };

  const addArticleToCart = () => {
    setLoading(true);
    cartHelpers
      .addArticleToCart(uuid, article, configuration, restaurantProps.restaurantId)
      .then(() => {
        setLoading(false);

        resetArticleSelection();
        fetchCart();
      })
      .catch(() => {
        toast.error(`Es ist ein Fehler aufgetreten. Das tut uns leid.`, {
          theme: 'colored',
        });
        // todo: hier error in logger

        setLoading(false);
      });
  };

  const getConfiguredExtraAmount = (extraId) => {
    if (configuration.extras?.length > 0) {
      const extra = configuration.extras.find((el) => el._id === extraId);
      return extra ? extra.amount : 0;
    } else return 0;
  };

  const getConfiguredSpecialRequestsAmount = (srId) => {
    if (configuration.specialRequests?.length > 0) {
      const sr = configuration.specialRequests.find((el) => el._id === srId);
      return sr ? sr.amount : 0;
    } else return 0;
  };

  const setExtraAmount = (extra, amount) => {
    if (amount > 0) {
      if (configuration.extras?.length > 0) {
        const existingExtra = configuration.extras.find((el) => el._id === extra._id);
        if (existingExtra) {
          existingExtra.amount = amount;
        } else {
          configuration.extras.push({ ...extra, amount });
        }
      } else {
        configuration.extras = [{ ...extra, amount }];
      }
    } else {
      // remove from configuration, rather than set to 0
      if (configuration.extras?.length > 0) {
        const index = configuration.extras.findIndex((el) => el._id === extra._id);
        if (index > -1) {
          configuration.extras.splice(index, 1);
        }
      }
    }

    setArticleWithConfiguration(article, configuration, uuid, 'setExtraAmount');
    updateTotalPrice();
  };

  const setSpecialRequestsAmount = (sr, amount) => {
    if (amount > 0) {
      if (configuration.specialRequests?.length > 0) {
        const existingSr = configuration.specialRequests.find((el) => el._id === sr._id);
        if (existingSr) {
          existingSr.amount = amount;
        } else {
          configuration.specialRequests.push({ ...sr, amount });
        }
      } else {
        configuration.specialRequests = [{ ...sr, amount }];
      }
    } else {
      // remove from configuration, rather than set to 0
      if (configuration.specialRequests?.length > 0) {
        const index = configuration.specialRequests.findIndex((el) => el._id === sr._id);
        if (index > -1) {
          configuration.specialRequests.splice(index, 1);
        }
      }
    }

    setArticleWithConfiguration(article, configuration, uuid, 'setSpecialRequestsAmount');
    updateTotalPrice();
  };

  let cachedConfig = undefined;
  const attributeSelectionChanged = (attr, value) => {
    if (!cachedConfig) cachedConfig = cloneDeep(configuration);
    const newConfiguration = cachedConfig;

    // check if attribute is already in config
    const attributeIndex = newConfiguration.attributes.findIndex(
      (el) => el.attribute._id === attr._id,
    );
    if (attributeIndex > -1) {
      // now we change the value
      newConfiguration.attributes[attributeIndex].selectedValue = value;
      newConfiguration.attributes[attributeIndex].selectedValueId = value._id;
    } else {
      newConfiguration.attributes.push({
        attribute: attr,
        selectedValue: value,
        selectedValueId: value._id,
      });
    }
    cachedConfig = newConfiguration;

    setArticleWithConfiguration(article, newConfiguration, uuid, 'attributeSelectionChanged');
    setExtraCharge(_getExtraCharge(cachedConfig));
    setSpecialRequestCharge(_getSpecialRequestCharge(cachedConfig));
    updateTotalPrice();
  };

  const _getPreselectedAttributeValueId = (attribute) => {
    if (!configuration) return null;

    const foundAttr = configuration.attributes.find((el) => el._id === attribute._id);
    if (!foundAttr) return null;

    return foundAttr.selectedValueId;
  };

  const _getExtraCharge = (configuration) => {
    let sum = 0;
    configuration.attributes.forEach((attr) => {
      sum += attr.selectedValue.extraCharge || 0;
    });
    return sum;
  };

  const _getSpecialRequestCharge = (configuration) => {
    let sum = 0;
    configuration.attributes.forEach((attr) => {
      sum += attr.selectedValue.specialCharge || 0;
    });
    return sum;
  };

  const menuOptionClicked = (menuOption, selectedAttributes, wasSelected) => {
    if (!cachedConfig) cachedConfig = cloneDeep(configuration);
    const config = cachedConfig;

    if (!wasSelected) {
      config.menuOption = { ...config.menuOption, option: menuOption, selectedAttributes };
    } else {
      config.menuOption = {};
    }
    setArticleWithConfiguration(article, config, uuid, 'menuOptionClicked');
    updateTotalPrice();
  };

  const updateMenuOption = (menuOption, selectedAttributes) => {
    if (!cachedConfig) cachedConfig = cloneDeep(configuration);
    const config = cachedConfig;
    config.menuOption.option = menuOption;
    config.menuOption.selectedAttributes = selectedAttributes;

    setArticleWithConfiguration(article, config, uuid, 'menuOptionAttributeChangedd');
    updateTotalPrice();
  };

  const _menuOptionPreselection = (menuOption) => {
    if (!configuration?.menuOption || configuration.menuOption?._id !== menuOption._id) return null;

    return configuration.menuOption;
  };

  return (
    <Wrapper>
      <CloseButtonWrapper onClick={resetArticleSelection}>
        <Icon style={{ width: 18 }} name="cross" />
      </CloseButtonWrapper>

      <ScrollWrapper>
        <TitleAndDescription>
          <h3>{article.title}</h3>
          <p>{article.description}</p>
        </TitleAndDescription>

        <HorizontalDivider />

        {article.attributes?.length > 0 && (
          <>
            <Attributes>
              {article.attributes.map((attribute) => (
                <AttributeSelector
                  key={`${attribute._id}-${article._id}`}
                  attribute={attribute}
                  selectionId={_getPreselectedAttributeValueId(attribute)}
                  onChange={(attr, value) => attributeSelectionChanged(attr, value)}
                />
              ))}
            </Attributes>

            <HorizontalDivider />
          </>
        )}

        {article.menuoptions && article.menuoptions.length > 0 ? (
          <>
            <MenuOptions>
              <TitleDiv>
                <Icon style={{ width: 38 }} name="foodmenu" />
                <h2>Im Menü genießen</h2>
              </TitleDiv>
              {article.menuoptions.map((option) => (
                <MenuOptionSelector
                  key={option._id}
                  value={option}
                  onSelect={menuOptionClicked}
                  onAttributeChange={updateMenuOption}
                  preSelection={_menuOptionPreselection(option)}
                  selected={
                    configuration.menuOption &&
                    configuration.menuOption.option &&
                    configuration.menuOption.option._id === option._id
                  }
                />
              ))}
            </MenuOptions>
            <HorizontalDivider />
          </>
        ) : null}

        {article.extras?.length > 0 && (
          <>
            <Extras>
              <h2>Extras wählen</h2>
              <ShowMoreListComponent
                key={`list-extra-${article._id}`}
                maxHeight={100}
                showMoreLabel={`Zeige alle ${article.extras.length} Extras`}
              >
                {article.extras.map((extra) => (
                  <ExtrasManipulator
                    key={`${extra._id}-${article._id}`}
                    extra={extra}
                    amountSelected={getConfiguredExtraAmount(extra._id)}
                    onDecrease={setExtraAmount}
                    onIncrease={setExtraAmount}
                    extraCharge={extraCharge}
                  />
                ))}
              </ShowMoreListComponent>
            </Extras>

            <HorizontalDivider />
          </>
        )}

        {article.specialRequests?.length > 0 && (
          <>
            <Extras>
              <h2>Sonderwünsche wählen</h2>
              <ShowMoreListComponent
                key={`list-special-${article._id}`}
                maxHeight={100}
                showMoreLabel={`Zeige alle ${article.specialRequests.length} Sonderwünsche`}
              >
                {article.specialRequests.map((sr) => (
                  <ExtrasManipulator
                    key={`${sr._id}-${article._id}`}
                    extra={sr}
                    amountSelected={getConfiguredSpecialRequestsAmount(sr._id)}
                    onDecrease={setSpecialRequestsAmount}
                    onIncrease={setSpecialRequestsAmount}
                    extraCharge={specialRequestCharge}
                  />
                ))}
              </ShowMoreListComponent>
            </Extras>

            <HorizontalDivider />
          </>
        )}

        <NoteWrapper>
          <Form.Control
            key={`${article._id}-note`}
            className="notes-area"
            as="textarea"
            rows={4}
            value={configuration.note}
            maxLength={100}
            placeholder="Notizen zur Speise bitte hier angeben (Sonderwünsche, Unverträglichkeiten, Allergien, etc)"
            onChange={(e) => noteChanged(e.target.value)}
          />
        </NoteWrapper>
      </ScrollWrapper>

      <AddToCartWrapper>
        <AmountCol>
          <AmountWrapper>
            <AmountButton
              style={{
                color: configuration.amount > 1 ? props.theme.colors.primary : '#212529',
                opacity: 1,
              }}
              onClick={decreaseAmount}
            >
              -
            </AmountButton>
            <span>{configuration?.amount}x</span>
            <AmountButton
              style={{ color: props.theme.colors.primary, opacity: 1 }}
              onClick={increaseAmount}
            >
              +
            </AmountButton>
          </AmountWrapper>
        </AmountCol>
        <ButtonWrapper>
          <Button
            style={{ height: '100%', width: '100%' }}
            type="button"
            onClick={addArticleToCart}
            disabled={loading}
          >
            {loading ? <LoaderTyping /> : <Currency as="span">{totalPrice}</Currency>}
          </Button>
        </ButtonWrapper>
      </AddToCartWrapper>
    </Wrapper>
  );
}

export default withTheme(ArticleConfiguratorComponent);
