import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg style={style} {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66">
      {/* <path
        d="M66 0v66H0V0h34.67a1.81 1.81 0 0 0-.55.09L34 .16a.61.61 0 0 0-.21.11 1.57 1.57 0 0 0-.24.21 1.14 1.14 0 0 0-.15.16.21.21 0 0 0-.06.1 2.07 2.07 0 0 0-.15.25.24.24 0 0 0 0 .08.83.83 0 0 0-.19.23s0 0 0 .06a1.66 1.66 0 0 0 0 .32V7a26.51 26.51 0 1 0 18 6.43l-.47-.43a1.15 1.15 0 0 0-.43 0 1.05 1.05 0 0 0-.89 1.61c.16.13.32.26.48.41A24.5 24.5 0 1 1 33 9v5.32a1.39 1.39 0 0 0 0 .3 1.35 1.35 0 0 0 .2.56l.08.11a.92.92 0 0 0 .18.22l.17.14.13.09a1.16 1.16 0 0 0 .22.13 2.07 2.07 0 0 0 .65.12 1.62 1.62 0 0 0 .93-.28L43.43 11l1.41-.85a1.6 1.6 0 0 0 .65-.4 1.37 1.37 0 0 0 .19-.22 1.67 1.67 0 0 0-.33-2.31L35.61.29a1.67 1.67 0 0 0-.9-.26Z"
        style="fill:#fff"
      /> */}
      <path d="M60.17 33.49A26.5 26.5 0 1 1 33 7V1.7a1.66 1.66 0 0 1 0-.32v-.06a.83.83 0 0 1 .08-.22.24.24 0 0 1 0-.08 2.07 2.07 0 0 1 .15-.25.21.21 0 0 1 .06-.1 1.14 1.14 0 0 1 .21-.18 1.57 1.57 0 0 1 .24-.21.61.61 0 0 1 .26-.11l.16-.07a1.81 1.81 0 0 1 .51-.1 1.67 1.67 0 0 1 .9.26l9.74 7a1.67 1.67 0 0 1 .33 2.31 1.37 1.37 0 0 1-.19.22 1.6 1.6 0 0 1-.65.4l-1.37.81-7.83 4.7a1.62 1.62 0 0 1-.93.28 2.07 2.07 0 0 1-.65-.12 1.16 1.16 0 0 1-.22-.13l-.13-.09-.17-.14a.92.92 0 0 1-.18-.22l-.08-.11a1.35 1.35 0 0 1-.2-.56 1.39 1.39 0 0 1 0-.3V9a24.51 24.51 0 1 0 16.69 6c-.16-.15-.32-.28-.48-.41A1.05 1.05 0 0 1 50.1 13a1.15 1.15 0 0 1 .41.08l.45.39a26.4 26.4 0 0 1 9.21 20.02Z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
