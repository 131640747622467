import { useContext, useEffect, useRef, useState } from 'react';
import RestaurantContext from '../../Context/RestaurantContext';
import ArticleConfigurationContext from '../../Context/ArticleConfigurationContext';
import styled, { css } from 'styled-components';
import CartAndConfiguratorComponent from './CartAndConfiguratorComponent';
import { useMediaQuery } from 'react-responsive';

const ContentWrapper = styled.div`
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  transition: top 200ms;

  background: white;
  box-shadow: 0 2px 9px RGBA(120, 130, 140, 0.13);
`;

const HidingWrapper = styled.div`
  @media (min-width: 992px) {
    display: none !important;
  }

  ${(props) => {
    return props.$show
      ? css`
          ${ContentWrapper} {
            top: 0%;
          }
          z-index: 100;
        `
      : css`
          pointer-events: none;
          z-index: -99;
          transition: z-index 0ms 200ms;
        `;
  }}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

function CartAndConfiguratorMobileModal(props) {
  const { showCartModalForMobile } = useContext(RestaurantContext);
  const { showConfigModal } = useContext(ArticleConfigurationContext);

  const isCartAlwaysVisibleInScreen = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const wrapperRef = useRef();

  const [isOnClient, setIsOnClient] = useState(false);
  useEffect(() => {
    setIsOnClient(true);
  }, []);

  useEffect(() => {
    if (isCartAlwaysVisibleInScreen || !isOnClient) return;

    if (showCartModalForMobile || showConfigModal) {
      setTimeout(() => {
        wrapperRef.current.style.background = 'white';
      }, 200);
    } else {
      wrapperRef.current.style.background = 'none';
    }
  }, [showCartModalForMobile, showConfigModal, isCartAlwaysVisibleInScreen, isOnClient]);

  return (
    <>
      {isCartAlwaysVisibleInScreen || !isOnClient ? null : (
        <HidingWrapper ref={wrapperRef} $show={showCartModalForMobile || showConfigModal}>
          <ContentWrapper>
            {isOnClient && !isCartAlwaysVisibleInScreen && (
              <CartAndConfiguratorComponent key="mobile-cart" />
            )}
          </ContentWrapper>
        </HidingWrapper>
      )}
    </>
  );
}

export default CartAndConfiguratorMobileModal;
