import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={style}
      viewBox="0 0 42.5 60"
      {...rest}
    >
      <path
        fillRule="evenodd"
        d="M36.4 9.3h-6V3.4c0-.8-.7-1.6-2-2.1-2.2-.9-4.6-1.3-7-1.3-2.4-.1-4.8.4-7 1.3-1.2.6-2 1.3-2 2.1v5.9H6.2C2.8 9.3 0 12.1 0 15.5v38.3C0 57.2 2.8 60 6.2 60h30.2c3.4 0 6.2-2.8 6.2-6.2V15.5c-.1-3.4-2.8-6.2-6.2-6.2zM14.3 3.8c2.2-1.1 4.6-1.6 7-1.5 2.4-.1 4.8.4 7 1.5v5.5h-14V3.8zm26.3 49.7c0 2.5-2 4.5-4.5 4.5H6.6C4.1 58 2 56 2 53.5v-4.1h38.5v4.1zM2 47.4V15.9c0-2.5 2-4.5 4.5-4.5h5.7V13h2v-1.7h14V13h2v-1.7H36c2.5 0 4.5 2 4.5 4.5v31.5H2z"
        clipRule="evenodd"
      />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
