const calculateCartTotalValue = (cart) => {
  const redeemedCoupons = cart?.redeemedCoupons || [];
  const totalPrice = cart?.meta.totalPrice;

  const calculateAbsoluteCouponsSum =
    redeemedCoupons
      ?.filter((coupon) => coupon.couponType === 'absolute')
      ?.reduce((acc, curr) => acc + curr.valueAbsolute, 0) || 0;

  const calculateRelativeCouponsSum =
    redeemedCoupons
      ?.filter((coupon) => coupon.couponType === 'relative')
      ?.reduce((acc, curr) => acc + curr.valueRelative, 0) || 0;
  const relativeCouponsSum =
    ((totalPrice - calculateAbsoluteCouponsSum) * calculateRelativeCouponsSum) / 100;

  return Math.round((totalPrice - calculateAbsoluteCouponsSum - relativeCouponsSum) * 100) / 100;
};

export default calculateCartTotalValue;
