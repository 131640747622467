import React from 'react';
import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  viewBox = '0 0 36.7 60',
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} style={style} {...rest}>
      <path d="M34.2 8.86a16 16 0 00-4.18-5A17.93 17.93 0 0022.94.53 21 21 0 0018.35 0a20.22 20.22 0 00-3.89.39A19 19 0 009.9 1.86a16 16 0 00-6.8 6c-1.87 3-3.1 7-3.1 12.44 0 3.19 1.15 6.78 2.8 10.44A96.3 96.3 0 0012 46.09c3.07 4.27 5.51 7.19 5.52 7.21l.81 1 .8-1a135.92 135.92 0 008.73-11.88 82.07 82.07 0 006-10.71c1.65-3.66 2.79-7.25 2.8-10.44A23.88 23.88 0 0034.2 8.86zm-2 21A95 95 0 0123 45.1c-1.53 2.14-2.91 3.94-3.91 5.2l-.78 1a138.35 138.35 0 01-8.3-11.46 79.27 79.27 0 01-5.7-10.27 24.55 24.55 0 01-2.5-9.36A22 22 0 014.13 9.64a13.91 13.91 0 013.69-4.42 15.89 15.89 0 016.35-2.95 19.12 19.12 0 014.18-.49 19 19 0 013.54.35A17.63 17.63 0 0126 3.46a14.09 14.09 0 016 5.26c1.66 2.62 2.82 6.28 2.82 11.47.04 2.71-1 6.14-2.62 9.69z" />
      <path fill="none" d="M0 0h36.7v54.26H0z" />
      <path d="M32.27 60H4.42l.53-2 2.12-7.95.53-2h2.73v2H9.14L7.03 58h22.64l-2.12-7.95h-1.44v-2h2.98l.54 2L31.74 58l.53 2zM18.61 10.16a8 8 0 108 8 8 8 0 00-8-8zm0 14a6 6 0 116-6 6 6 0 01-6.03 6z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
  viewBox: PropTypes.string,
};

export default SVG;
