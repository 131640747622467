import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={style} {...rest} viewBox="0 0 45.98 60">
      <path d="M44.59 14.65a4.72 4.72 0 0 0-3.34-8.07h-9.12C32 2.93 28 0 23 0s-9 2.93-9.14 6.58H4.73a4.73 4.73 0 1 0 0 9.45h.34A7 7 0 0 0 3 21v32a7 7 0 0 0 7 7h26a7 7 0 0 0 7-7V21a7 7 0 0 0-2.1-5h.35a4.74 4.74 0 0 0 3.34-1.35Zm-3.65 6.08v32.53a4.71 4.71 0 0 1-4.7 4.74H9.67A4.7 4.7 0 0 1 5 53.26V20.73A4.7 4.7 0 0 1 9.67 16h26.57a4.71 4.71 0 0 1 4.7 4.73ZM23 1.83a7.91 7.91 0 0 1 7.57 4.75H15.42A7.91 7.91 0 0 1 23 1.83ZM4.72 14A2.7 2.7 0 0 1 2 11.29a2.74 2.74 0 0 1 .78-1.92 2.64 2.64 0 0 1 1.89-.8h36.61A2.71 2.71 0 0 1 44 11.28a2.78 2.78 0 0 1-.78 1.93 2.68 2.68 0 0 1-1.9.8Z" />
      <path d="M13.96 24.51h2v25h-2zm8.48 0h2v25h-2zm8.46 0h2v25h-2z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
