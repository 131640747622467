import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.31 60" {...rest}>
      <path d="M35.52 58.49l.07.07.07-.06z" />
      <path d="M45.87 6.06A39 39 0 0041 .43c-.14-.14-.34-.28-.44-.43L39.3.84l-.39.27a36.36 36.36 0 00-14.43 22A39.82 39.82 0 008.11 19.4a39.3 39.3 0 00-7.45.6l-.61.1c0 .5-.06 1-.07 1.52v.47a36.38 36.38 0 008.68 24.07l1.27-.86a35 35 0 01-8.4-23.19v-.77a39 39 0 016.52-.46 38.21 38.21 0 0116.08 3.77c.49.22 1 .47 1.42.73A36.77 36.77 0 0137.94 36v.05c.26.35.5.69.74 1a34.94 34.94 0 015.64 14q.16 1 .27 2.1a32.33 32.33 0 01.19 4.08V58a37 37 0 01-5.7.44h-.82c-.58 0-1.15 0-1.72-.06l-.09.06-.08-.07c-.75 0-1.49-.11-2.23-.2a37.94 37.94 0 01-18-7.12l-1.3.95a39.4 39.4 0 0020.93 7.88c.43 0 .87.06 1.31.07h1.18a38.49 38.49 0 007.4-.54l.61-.1c0-.51.06-1 .07-1.53v-.46a34.71 34.71 0 00-.39-5.79C55.56 38.69 56 20.25 45.87 6.06zm-.27 43.33a36.41 36.41 0 00-6-13.66 39.79 39.79 0 001.86-16.4 41.57 41.57 0 00-.76-4.81A1.91 1.91 0 0038.83 13a37.59 37.59 0 011.15 6.43 38.3 38.3 0 01-1.47 14.8 38.45 38.45 0 00-12.63-10.4A35 35 0 0139.8 2.38c.2-.16.42-.3.64-.45a39.3 39.3 0 014.22 5c9.42 13.18 9.31 30.21.94 42.46z" />
      <path d="M36.44 51.71a1.22 1.22 0 00-.13-1.26c-.1-.16-.2-.31-.31-.46A61.18 61.18 0 0019.78 33.9c-2-1.44-4.18-2.8-6.38-4.08a4.94 4.94 0 00-4-.44 85 85 0 018.7 5.38 60.22 60.22 0 0114.86 14.41c.45.63.87 1.27 1.26 1.91z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
