import { memo, useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RowStyled = styled(Row)`
  margin-bottom: 10px;
  font-size: 15px;

  span {
    font-size: 15px;
    letter-spacing: 0.67px;
    font-weight: 400;
  }
`;

const InputStyled = styled(Form.Select)`
  appearance: none;
  border: 1px solid rgba(151, 151, 151, 0.15);
  font-size: 15px;
`;

function AttributeSelector(props) {
  const { attribute, onChange, selectionId, ...rest } = props;

  // initial callback with default/preselection
  useEffect(() => {
    onChange(attribute, selectDefaultOrPreselected());
  }, []);

  // gives back the preselected object or the default value
  const selectDefaultOrPreselected = () =>
    selectionId ? attribute.values.find((x) => x._id === selectionId) : attribute.values[0];

  const attributeValuePrice = (attrValue) => {
    let returnStr = '';
    if (attrValue.price > 0) {
      returnStr = ` (+ ${new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(attrValue.price)})`;
    }
    return returnStr;
  };

  const _selectionChange = (selection) => {
    onChange(attribute, selection);
  };

  return (
    <RowStyled {...rest}>
      <Col xs={5} style={{ alignItems: 'center', display: 'flex', paddingRight: 0 }}>
        <span>{attribute.title}:</span>
      </Col>
      <Col xs={7} style={{ alignItems: 'center', display: 'flex' }}>
        <InputStyled
          type="select"
          onChange={(e) => {
            _selectionChange(attribute.values.find((x) => x._id === e.target.value));
          }}
          defaultValue={selectionId}
        >
          {attribute.values.map((attributeValue) => (
            <option key={attributeValue._id} value={attributeValue._id}>
              {attributeValue.title}
              {attributeValuePrice(attributeValue)}
            </option>
          ))}
        </InputStyled>
      </Col>
    </RowStyled>
  );
}

AttributeSelector.propTypes = {
  attribute: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectionId: PropTypes.string,
};

export default AttributeSelector;
