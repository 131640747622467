import React from 'react';
import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 37.05" {...rest}>
      <path d="M16 24.43a4.16 4.16 0 104.15 4.1 4.09 4.09 0 00-4.15-4.1zm0 6.83a2.74 2.74 0 01-2.67-2.7A2.65 2.65 0 0116 25.92a2.62 2.62 0 012.65 2.67A2.68 2.68 0 0116 31.26zM51.53 24.43a4.14 4.14 0 00-4.15 4.13 4.17 4.17 0 108.33 0 4.11 4.11 0 00-4.18-4.13zm-.05 6.83a2.74 2.74 0 01-2.61-2.76 2.64 2.64 0 012.83-2.57 2.59 2.59 0 012.51 2.71 2.69 2.69 0 01-2.73 2.62z" />
      <path d="M58.42 35.57h-2a1.89 1.89 0 01.21-.25 8.49 8.49 0 00-2.61-14.85 1.3 1.3 0 01-.64-.61c-1.31-2.57-2.63-5.13-3.88-7.72a6.38 6.38 0 00-3.22-3.33l.24-.18a4.24 4.24 0 001.87-4.29A4.24 4.24 0 0045.27.72c-.19-.06-.44-.11-.52-.25-.35-.57-.88-.47-1.38-.41a5 5 0 00-4 3.15 1.71 1.71 0 01-.64.74c-.93.55-1.9 1-2.86 1.55-.47.25-.88.55-.55 1.15s.83.4 1.29.15c.8-.42 1.59-.86 2.33-1.26.37.82.7 1.54 1 2.28a4 4 0 01.38 1.08c.42 4.12.81 8.24 1.23 12.36a2.43 2.43 0 01-2.65 3h-4.3c-1.44 0-2.91.05-4.37 0a2.28 2.28 0 01-2.18-2.41V15h2.15a2.14 2.14 0 002.18-2.05 2.1 2.1 0 00-2.12-2.11H19.9c-1.92 0-2.55.61-2.63 2.53 0 0-.05.07-.08.11H14v-9H0V15h7.82v4.63a11.49 11.49 0 00.52 4.51 1.36 1.36 0 01-.14.91 8.29 8.29 0 00.8 8.38 24.72 24.72 0 001.9 2.14H5.39a.76.76 0 00-.73.76.75.75 0 00.57.73h53.22c.49 0 .79-.29.76-.8s-.33-.7-.79-.69zM44.85 2.23A2.78 2.78 0 0147 4.92a2.86 2.86 0 01-2.17 2.82zM40.57 5a3.3 3.3 0 012.74-3.33v6.56C42 8.25 40.62 6.69 40.57 5zm-20.92 7.3h10.29c.45 0 .91 0 .92.59s-.43.61-.89.61H19.68c-.44 0-.89 0-.91-.6s.44-.6.88-.6zm6.91 2.7v1.89c.1 1.67 0 3.48.06 5.11a3.73 3.73 0 002.76 3.53 5.33 5.33 0 001.38.18h8.08c2.89 0 4.53-1.82 4.24-4.68-.38-3.82-.76-7.64-1.13-11.39 1 .1 1.9.16 2.83.3a3.78 3.78 0 013 2c1.35 2.6 2.64 5.22 4 7.84a1.35 1.35 0 01.08.24 18.43 18.43 0 00-2.54.44 8.17 8.17 0 00-6 7c-.06.39-.15.62-.62.62h-18a1.56 1.56 0 01-.2-.06c-.44-3.54-2.21-6.17-5.66-7.39s-6.48-.33-9.21 2.19c-.32-2.58-.13-5.18-.21-7.81zM1.47 13.46V5.85h10.81v7.61zM9 28.59a6.9 6.9 0 017-7 7 7 0 016.94 6.89 7.06 7.06 0 01-7 7A7 7 0 019 28.59zm11.82 6.94a8.9 8.9 0 003.52-5.91h18.81a8.93 8.93 0 003.54 5.91zm30.73 0a7 7 0 01-6.92-6.94 7 7 0 116.92 6.94z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
