import React, { memo, useContext } from 'react';
import styled from 'styled-components';
import { Currency } from 'react-intl-number-format';
import calculateCartTotalValue from '../../util/calculateCartTotalValue';
import RestaurantContext from '../../Context/RestaurantContext';
import CartContext from '../../Context/CartContext';
import Icon from '../Icon';

const ProgressIndicatorWrapper = styled.div`
  margin: 20px 0px;
  font-size: 12px;

  span {
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
  }
`;

const RoundLi = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  color: #ffffff;
  font-size: 16px;
  background: ${(props) => props.theme.colors.primary};

  width: 16px;
  height: 16px;
  border-radius: 8px;
`;

const ProgressBar = styled.div`
  height: 8px;
  border-radius: 4px;
  width: 100%;
  background-color: RGB(242, 242, 242);
  margin-top: 8px;
`;

const GreenBar = styled.div`
  height: 8px;
  border-radius: 4px;
  max-width: 100%;
  background: ${(props) => props.theme.colors.primary};

  transition: width 200ms ease-in-out;
`;

function FreeDeliveryIndicator() {
  const { cart } = useContext(CartContext);
  const { restaurantProps } = useContext(RestaurantContext);
  const totalCartValue = calculateCartTotalValue(cart);

  const percentValue = (totalCartValue / restaurantProps.freeDeliveryFrom) * 100;

  return (
    <>
      <ProgressIndicatorWrapper>
        {restaurantProps.freeDeliveryFrom > totalCartValue ? (
          <div>
            Noch <Currency as="span">{restaurantProps.freeDeliveryFrom - totalCartValue}</Currency>{' '}
            bis zur gratis Lieferung!
          </div>
        ) : (
          <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              Perfekt - du bekommst dein Essen gratis geliefert!
              <RoundLi>
                <Icon style={{ fill: '#ffffff', width: 8, height: 8 }} name="check-single" />
              </RoundLi>
            </div>
          </>
        )}
        <ProgressBar>
          <GreenBar style={{ width: `${percentValue}%` }} />
        </ProgressBar>
      </ProgressIndicatorWrapper>
    </>
  );
}

export default memo(FreeDeliveryIndicator);
