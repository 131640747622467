import styled from 'styled-components';

const Divider = styled.div`
  position: relative;

  width: 100%;
  height: 1px;
  background: rgba(133, 133, 133, 0.15);
  content: ' ';
  margin: 25px 0;
`;

function HorizontalDivider(props) {
  return <Divider {...props} />;
}

HorizontalDivider.propTypes = {};

export default HorizontalDivider;
