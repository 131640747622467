import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import styled, { withTheme } from 'styled-components';
import { Currency } from 'react-intl-number-format';

const StyledRow = styled(Row)`
  font-size: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }
`;

const AmountDiv = styled.div`
  white-space: nowrap;
  flex-grow: 0;
  width: 80px;
  height: 100%;
  font-size: 15px;
  flex: none;
  align-self: flex-start;
  justify-content: center;
  align-items: center;

  display: flex;
  flex-flow: row nowrap;

  & > span {
    text-align: center;
    flex-grow: 1;
  }
`;

const Btn = styled.button`
  padding: 0 10px;
  min-width: 25px;
  color: #cecece;
  font-size: 15px;
  line-height: 15px;
  background: none;
  border: none;
  outline: none !important;

  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`;

const PriceDiv = styled.div`
  justify-content: flex-end;
  align-self: center;
  flex-grow: 1;
  text-align: right;
`;

function ExtrasManipulator({ extra, extraCharge, amountSelected, onIncrease, ...props }) {
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (amountSelected > 0) {
      setAmount((prevState) => amountSelected);
    }
  }, [amountSelected]);

  const increaseAmount = (e, byValue = 1) => {
    setAmount((prevState) => prevState + byValue);
    onIncrease(extra, amount + byValue);
    if (e) e.stopPropagation();
  };

  const decreaseAmount = (e) => {
    if (amount > 0) {
      setAmount((prevState) => prevState - 1);
      props.onDecrease(extra, amount - 1);
    }
    if (e) e.stopPropagation();
  };

  return (
    <StyledRow key={extra._id} onClick={increaseAmount}>
      <Col xs={6}>{extra.title}</Col>
      <Col
        xs={6}
        className="text-right"
        style={{ display: 'flex', justifyContent: 'center', fontWeight: 300 }}
      >
        <AmountDiv>
          <Btn
            type="button"
            onClick={(event) => decreaseAmount(event)}
            style={{ color: amount > 0 ? props.theme.colors.primary : '' }}
          >
            -
          </Btn>
          <span style={{ fontWeight: amount > 0 ? 'normal' : 'inherit' }}>{amount}x</span>
          <Btn
            type="button"
            style={{ color: props.theme.colors.primary, opacity: 1 }}
            onClick={(event) => increaseAmount(event)}
          >
            +
          </Btn>
        </AmountDiv>
        <PriceDiv>
          <Currency as="span">{extra.protected ? extra.price : extra.price + extraCharge}</Currency>
        </PriceDiv>
      </Col>
    </StyledRow>
  );
}

ExtrasManipulator.propTypes = {
  extra: PropTypes.object.isRequired,
  theme: PropTypes.object,
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired,
  extraCharge: PropTypes.number,
  amountSelected: PropTypes.number,
};

ExtrasManipulator.defaultValues = {
  extraCharge: 0,
  amountSelected: 0,
};

export default withTheme(ExtrasManipulator);
