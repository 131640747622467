import { useState, useEffect, useContext, memo } from 'react';
import styled from 'styled-components';
import { Col, Row, FormSelect } from 'react-bootstrap';
import PropTypes from 'prop-types';
import RestaurantContext from '../../Context/RestaurantContext';

const Wrapper = styled.div``;

const Label = styled.span`
  font-size: 14px;
  letter-spacing: 0.62px;
  font-weight: 400;
`;

const StyledSelector = styled(FormSelect)`
  border: 1px solid rgba(133, 133, 133, 0.15);
  font-size: 14px;
  cursor: pointer;
`;

const DesiredDeliveryTimeSelectorComponent = ({
  isPickUp,
  deliveryTimes,
  onSelectionChanged,
  preselectedDeliveryTime,
  ...rest
}) => {
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState(preselectedDeliveryTime);
  const { refetchRestaurantProps } = useContext(RestaurantContext);

  useEffect(() => {
    if (!selectedDeliveryTime) {
      // inital callback for default selection
      setSelectedDeliveryTime(
        deliveryTimes.length > 0 ? deliveryTimes[0].value : refetchRestaurantProps(),
      );
    }
  }, [deliveryTimes, refetchRestaurantProps, selectedDeliveryTime]);

  useEffect(() => {
    // selection changed? notify HoC
    if (onSelectionChanged) onSelectionChanged(selectedDeliveryTime);
  }, [selectedDeliveryTime, onSelectionChanged]);

  return (
    <Wrapper>
      <Row style={{ alignItems: 'center' }}>
        <Col xs={6}>
          <Label>Gewünschte {isPickUp ? 'Abholzeit' : 'Lieferzeit'}</Label>
        </Col>
        <Col xs={6}>
          <StyledSelector
            type="select"
            onChange={(e) => setSelectedDeliveryTime(e.target.value)}
            defaultValue={selectedDeliveryTime}
          >
            {deliveryTimes?.map((deliveryTime) => (
              <option
                key={`${deliveryTime.value}-${deliveryTime.label}`}
                value={deliveryTime.value}
              >
                {deliveryTime.label}
              </option>
            ))}
          </StyledSelector>
        </Col>
      </Row>
    </Wrapper>
  );
};

DesiredDeliveryTimeSelectorComponent.propTypes = {
  isPickUp: PropTypes.bool.isRequired,
  deliveryTimes: PropTypes.array.isRequired,
  preselectedDeliveryTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelectionChanged: PropTypes.func,
};

export default memo(DesiredDeliveryTimeSelectorComponent);
