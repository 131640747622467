import PropTypes from 'prop-types';
import { Fragment, useContext, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import LoaderTyping from '../Loader/LoaderTyping';
import { Currency } from 'react-intl-number-format';
import ArticleConfigurationContext from '../../Context/ArticleConfigurationContext';
import RestaurantContext from '../../Context/RestaurantContext';
import CartContext from '../../Context/CartContext';
import cartHelper from './cartHelpers';
import { toast } from 'react-toastify';

const ArticleBox = styled.div`
  position: relative;
  margin-bottom: 10px;
  font-size: 15px;
`;
const Line = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  word-break: break-word;
`;
const AmountDiv = styled.div`
  white-space: nowrap;
  flex-grow: 0;
  width: 80px;
  font-size: 15px;
  flex: none;

  display: flex;
  flex-flow: row nowrap;

  & > span {
    text-align: center;
    flex-grow: 1;
  }
`;
const Btn = styled.button`
  padding: 0;
  min-width: 25px;
  font-size: 20px;
  line-height: 14px;
  height: 22px;
  background: none;
  border: none;
  outline: none !important;

  &:first-child {
    text-align: left;
    opacity: 0.5;
    padding-left: 5px;
  }
  &:last-child {
    text-align: right;
    padding-right: 5px;
  }
`;

const TitleDiv = styled.div`
  flex-grow: 1;
  padding: 0 15px;
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;

  &.sub {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: inherit;
    font-weight: 300;
  }

  @media (min-width: 992px) {
    &:hover {
      opacity: 0.8;
    }
  }
`;
const AttributeDiv = styled.div``;
const PriceDiv = styled.div`
  flex-grow: 0;
  white-space: nowrap;

  &.sub {
    font-weight: 300;
  }
`;

const CartArticleList = ({ list, ...rest }) => {
  return list.map((item) => (
    <CartArticle key={`${item.uuid}-${item.articleId}`} item={item} {...rest} />
  ));
};
CartArticleList.propTypes = {
  list: PropTypes.array,
};
export default withTheme(CartArticleList);

const CartArticle = (props) => {
  const { item, minPriceReached } = props;

  const { overrideCart } = useContext(CartContext);
  const { setArticleWithConfiguration } = useContext(ArticleConfigurationContext);
  const {
    restaurantProps: { restaurantId },
  } = useContext(RestaurantContext);

  const [loading, setLoading] = useState(false);

  const increaseAmount = (item) => _changeArticleAmount(item, cartHelper.increaseArticleAmount);
  const decreaseAmount = (item) => _changeArticleAmount(item, cartHelper.decreaseArticleAmount);
  const _changeArticleAmount = (item, cartHelperFn) => {
    if (loading) return;

    setLoading(true);

    cartHelperFn(item.uuid, restaurantId)
      .then((responseData) => {
        setLoading(false);

        overrideCart(responseData);
      })
      .catch((error) => {
        toast.error(`Es ist ein Fehler aufgetreten. Das tut uns leid.`, {
          theme: 'colored',
        });
        // todo: hier error in logger

        setLoading(false);
      });
  };

  const openArticleConfigurator = (item) => {
    setArticleWithConfiguration(
      item.fullArticle,
      { amount: item.amount, note: item.note, ...item.configuration },
      item.uuid,
      'openArticleConfigurator',
    );
  };

  return (
    <ArticleBox key={item.uuid}>
      <Line>
        <AmountDiv>
          <Btn type="button" onClick={() => decreaseAmount(item)}>
            -
          </Btn>
          {loading ? (
            <LoaderTyping
              dotStyle={{
                background: 'black',
                width: 3,
                height: 3,
                borderRadius: 1.5,
              }}
              style={{ flexGrow: 1, textAlign: 'center' }}
            />
          ) : (
            <span>{item.amount}x</span>
          )}
          <Btn
            type="button"
            style={{ color: props.theme.colors.primary }}
            onClick={() => increaseAmount(item)}
          >
            +
          </Btn>
        </AmountDiv>
        <TitleDiv onClick={() => !loading && openArticleConfigurator(item)}>{item.title}</TitleDiv>
        <PriceDiv className="text-right">
          {loading ? (
            <>
              <LoaderTyping
                dotStyle={{
                  background: 'black',
                  width: 3,
                  height: 3,
                  borderRadius: 1.5,
                }}
                style={{ flexGrow: 1, textAlign: 'center' }}
              />{' '}
              €
            </>
          ) : (
            <>
              {item.fullArticle?.nonMinOrderValue && !minPriceReached ? '*' : ''}
              <Currency as="span">{item.totalPrice}</Currency>
            </>
          )}
        </PriceDiv>
      </Line>
      {item.configuration ? (
        <AttributeDiv onClick={() => !loading && openArticleConfigurator(item)}>
          {item.configuration.attributes ? (
            <ItemList list={item.configuration.attributes} uuid={item.uuid} />
          ) : null}

          {item.configuration.menuOption ? (
            <MenuOptionElement obj={item.configuration.menuOption} uuid={item.uuid} />
          ) : null}

          {item.configuration.extras ? (
            <ItemList list={item.configuration.extras} uuid={item.uuid} />
          ) : null}

          {item.configuration.specialRequests ? (
            <ItemList list={item.configuration.specialRequests} uuid={item.uuid} />
          ) : null}
        </AttributeDiv>
      ) : null}

      {item.note ? (
        <Line>
          <AmountDiv
            style={{
              flex: 'none',
              justifyContent: 'flex-end',
              fontStyle: 'italic',
            }}
          >
            Notiz:
          </AmountDiv>

          <TitleDiv style={{ fontWeight: 300, fontStyle: 'italic' }} className="sub">
            {item.note.split('\n').map((el, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`note-${index}-${item.uuid}`}>
                {el}
                <br />
              </Fragment>
            ))}
          </TitleDiv>
        </Line>
      ) : null}
    </ArticleBox>
  );
};

CartArticle.propTypes = {
  item: PropTypes.object,
  minPriceReached: PropTypes.bool,
};

const ItemLine = ({ name, amount }) => (
  <Line>
    <AmountDiv style={{ flex: 'none' }}>&nbsp;</AmountDiv>
    <TitleDiv title={name} style={{ fontWeight: 300 }} className="sub">
      + {amount ? `${amount}x ` : ''}
      {name}
    </TitleDiv>
  </Line>
);
ItemLine.propTypes = {
  name: PropTypes.string.isRequired,
  amount: PropTypes.number,
};

const ItemList = ({ list, uuid }) => (
  <>
    {list.map((el) => (
      <ItemLine key={el._id.concat(uuid)} name={el.title} amount={el.amount} />
    ))}
  </>
);
ItemList.propTypes = {
  list: PropTypes.array.isRequired,
  uuid: PropTypes.string.isRequired,
};

const MenuOptionAttribute = ({ optionTitle, articleTitle }) => (
  <Line>
    <AmountDiv style={{ flex: 'none' }}>&nbsp;</AmountDiv>
    <TitleDiv
      title={`${optionTitle}: ${articleTitle}`}
      style={{ fontWeight: 300, paddingLeft: '35px', fontSize: '0.9em' }}
      className="sub"
    >
      {optionTitle}: {articleTitle}
    </TitleDiv>
  </Line>
);
MenuOptionAttribute.propTypes = {
  optionTitle: PropTypes.string.isRequired,
  articleTitle: PropTypes.string.isRequired,
};
const MenuOptionElement = ({ obj, uuid }) => (
  <>
    <ItemLine name={obj.title} />
    {obj.selectedArticles.map((el) => (
      <MenuOptionAttribute
        key={uuid.concat(el.optionTitle)}
        articleTitle={el.articleTitle}
        optionTitle={el.optionTitle}
      />
    ))}
  </>
);
MenuOptionElement.propTypes = {
  obj: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired,
};
