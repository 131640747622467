import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const Point = styled.div`
  position: relative;
  display: block;
  height: 4px;
  width: 4px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.primary};
  ${(props) => {
    if (!props.$active) {
      return css`
        opacity: 0.25;
      `;
    }
  }}

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

const PagePointIndicator = ({ activeIndex, numberOfItems, ...rest }) => {
  return (
    <Wrapper>
      {[...Array(numberOfItems)].map((e, i) => (
        <Point key={`pagePoint-${i}`} $active={i === activeIndex} />
      ))}
    </Wrapper>
  );
};

PagePointIndicator.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  numberOfItems: PropTypes.number.isRequired,
};

export default PagePointIndicator;
