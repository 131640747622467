import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 59.93" style={style} {...rest}>
      <path d="M22.53 6.56v2.22a13 13 0 0 0-9.2 3.89 13.28 13.28 0 0 0-3.85 9.38v.92H7.29v-.92a15.49 15.49 0 0 1 4.5-10.95 15.26 15.26 0 0 1 10.74-4.54Z" />
      <path d="M22.61-.03a22.82 22.82 0 1 0 22.62 22.85A22.71 22.71 0 0 0 22.61-.03Zm0 43.52a20.71 20.71 0 0 1 0-41.41 20.71 20.71 0 0 1 0 41.41Z" />
      <path d="m44.53 39.9 12.33 12.33a3.25 3.25 0 1 1-4.59 4.6L39.89 44.46a18.64 18.64 0 0 0 2.69-2.18 16.88 16.88 0 0 0 1.95-2.38Zm-.45-3.44a16.38 16.38 0 0 1-3 4.33 20.39 20.39 0 0 1-4.58 3.26l14.28 14.28a5.36 5.36 0 1 0 7.57-7.6Z" />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
