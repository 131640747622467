import PropTypes from 'prop-types';

const SVG = ({
  style = {
    fill: '#000000',
  },
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" style={style}>
    <path fill="#2f9f2f" d="M0 0l30 30L60 0H0z" />
  </svg>
);

SVG.propTypes = {
  style: PropTypes.object.isRequired,
};

export default SVG;
