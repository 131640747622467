import React from 'react';
import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 39.03" style={style} {...rest}>
      <path
        d="m59.52 28.8-1.38-1.47-15.21-16.27-.1-.09a1.82 1.82 0 0 0-2.57.09L39 12.44A1.91 1.91 0 0 0 39 15l11.42 12.2H1.84A1.84 1.84 0 0 0 0 29v2.1a1.85 1.85 0 0 0 1.84 1.84h48.53L39.11 45.07a1.76 1.76 0 0 0 0 2.49l1.24 1.38s.09 0 .09.09a1.88 1.88 0 0 0 2.62-.09l16.46-17.61a1.91 1.91 0 0 0 0-2.53Z"
        transform="translate(0 -10.48)"
        style={{ fillRule: 'evenodd' }}
      />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
