import React from 'react';
import PropTypes from 'prop-types';

function SVG({
  style = {
    fill: '#000000',
  },
  ...rest
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 52" style={style} {...rest}>
      <path
        d="M60 27.69H5.63L27 49.12a1.73 1.73 0 01.51 1.2 1.71 1.71 0 01-.51 1.19 1.67 1.67 0 01-2.36 0L.48 27.27A1.58 1.58 0 010 26.08v-.18a1.66 1.66 0 01.48-1.2L24.66.49A1.67 1.67 0 1127 2.85L5.63 24.31H60z"
        fillRule="evenodd"
      />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object,
};

export default SVG;
